import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocuments } from "../../../../../Store/B2bslice";
import {
  dispatchtoast,
  fetchMemberProfile,
  getLocalStorageItem,
} from "../../../../../Utils";
import { merchantOnboard } from "../../../../../ApiServices/Apifun";
import Compressor from "compressorjs";

const DocumentUpload = ({ current, steps, setCurrent }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [Pan, setPan] = useState([]);
  const [Aadhar, setaadhar] = useState([]);
  const [Shop, setShop] = useState([]);
  const [panbase, setPanBase] = useState("");
  const [aadharbase, setAadharBase] = useState("");
  const [shopbase, setShopBase] = useState("");
  const dispatch = useDispatch();
  const formref = useRef();
  const [form] = Form.useForm();
  const getPersonal = useSelector((state) => state.B2B.personalDetail);
  const getdocuments = useSelector((state) => state.B2B.documents);
  const getMerchant = useSelector((state) => state.B2B.merchantDetail);
  const getBank = useSelector((state) => state.B2B.bankDetail);
  const handleImageChange = (file, from) => {
    try {
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        mimeType: "image/jpeg",
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onload = () => {
            let data = { ...compressedResult };
            data.name = compressedResult.name;
            data.thumbUrl = URL.createObjectURL(compressedResult);
            if (from === "pan") {
              setPanBase(reader.result);
              setPan([data]);
            } else if (from === "aadhar") {
              setAadharBase(reader.result);
              setaadhar([data]);
            } else {
              setShopBase(reader.result);
              setShop([data]);
            }
          };
          reader.readAsDataURL(compressedResult);
        },
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  };
  useEffect(() => {
    if (getdocuments) {
      formref.current.setFieldsValue({
        merchantPanImage: getdocuments.merchant_pan_image,
        maskedAadharImage: getdocuments.masked_aadhar_image,
        backgroundImageOfShop: getdocuments.background_image_of_shop,
      });
      setPan(getdocuments.merchant_pan_image);
      setaadhar(getdocuments.masked_aadhar_image);
      setShop(getdocuments.background_image_of_shop);
      setShopBase(getdocuments.shopbase);
      setPanBase(getdocuments.panbase);
      setAadharBase(getdocuments.aadharbase);
    } else {
      formref.current.setFieldsValue({
        merchantPanImage: "",
        maskedAadharImage: "",
        backgroundImageOfShop: "",
        setPan: [],
        setaadhar: [],
        setShop: [],
      });
      setPan([]);
      setaadhar([]);
      setShop([]);
    }
  }, []);
  const handleFinish = (val) => {
    // dispatch(setDocuments({
    //   background_image_of_shop:Shop,
    //   merchant_pan_image:Pan,
    //   masked_aadhar_image:Aadhar,
    //   panbase:panbase,aadharbase:aadharbase,shopbase:shopbase
    // }))
    const formdata = new FormData();
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    formdata.append("shop_latitude", getLocalStorageItem("latitude"));
    formdata.append("user_middle_name", "");
    formdata.append("shop_longitude", getLocalStorageItem("longitude"));
    formdata.append("background_image_of_shop", shopbase);
    formdata.append("merchant_pan_image", panbase);
    formdata.append("masked_aadhar_image", aadharbase);
    let combineddata = { ...getPersonal, ...getMerchant, ...getBank };
    combineddata.company_pan = combineddata.company_pan.toUpperCase();
    combineddata.user_pan = combineddata.user_pan.toUpperCase();
    Object.entries(combineddata).forEach(([key, val]) =>
      formdata.append(key, val)
    );
    setBtnLoading(true);
    merchantOnboard(formdata)
      .then((res) => {
        if (res.status) {
          setCurrent(4);
          fetchMemberProfile(dispatch);
        } else {
          dispatchtoast(res.data.message ? res.data.message : res.message,true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="p-4">
        <Form
          name="advanced_search"
          className="p-0"
          form={form}
          ref={formref}
          initialValues={{
            remember: true,
          }}
          onFinish={(val) => handleFinish(val)}
          onFinishFailed={(err) => console.log(err)}
          autoComplete="off"
        >
          <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-x-5 mt-4">
            <Form.Item
              name={`merchantPanImage`}
              className="font-semibold"
              label={"Pan Image"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Upload Pan Image!",
                },
              ]}
            >
              <Upload
                maxCount={1}
                listType="picture"
                customRequest={({ file }) => handleImageChange(file, "pan")}
                fileList={Pan}
                accept=".jpg,.jpeg,.png,.webp"
                {...props}
                defaultFileList={[]}
              >
                <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                  <UploadOutlined />
                  <p>Upload</p>
                </div>
              </Upload>
            </Form.Item>
            <Form.Item
              name={`maskedAadharImage`}
              className="font-semibold"
              label={"Aadhar Image"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Upload Aadhar Image!",
                },
              ]}
            >
              <Upload
                maxCount={1}
                listType="picture"
                customRequest={({ file }) => handleImageChange(file, "aadhar")}
                fileList={Aadhar}
                accept=".jpg,.jpeg,.png,.webp"
                {...props}
                defaultFileList={[]}
              >
                <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                  <UploadOutlined />
                  <p>Upload</p>
                </div>
              </Upload>
            </Form.Item>{" "}
            <Form.Item
              name={`backgroundImageOfShop`}
              label={"Background Shop Image"}
              className="font-semibold"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Upload Shop Image!",
                },
              ]}
            >
              <Upload
                maxCount={1}
                listType="picture"
                customRequest={({ file }) => handleImageChange(file, "shop")}
                fileList={Shop}
                accept=".jpg,.jpeg,.png,.webp"
                {...props}
                defaultFileList={[]}
              >
                <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                  <UploadOutlined />
                  <p>Upload</p>
                </div>
              </Upload>
            </Form.Item>
          </div>
          <div className="flex justify-end items-center m-2">
            <Form.Item
            //   wrapperCol={{
            //     offset: 8,
            //     span: 16,
            //   }}
            >
              {current > 0 && (
                <Button
                  className="hover:text-black bg-blue-500 text-white outline-none border-none"
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => {
                    setCurrent(2),
                      dispatch(
                        setDocuments({
                          background_image_of_shop: Shop,
                          merchant_pan_image: Pan,
                          masked_aadhar_image: Aadhar,
                          panbase,
                          aadharbase,
                          shopbase,
                        })
                      );
                  }}
                >
                  Previous
                </Button>
              )}{" "}
              {current < steps.length - 1 && (
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  className="bg-primary outline-none border-none text-white"
                >
                  Next
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default DocumentUpload;
