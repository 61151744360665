import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ProjectLayout from "../ProjectLayout";
import ErrorBoundary from "../ErrrorBoundry";
import { getLocalStorageItem } from "../Utils";
import { useSelector } from "react-redux";

function ProtectedAuth() {
  const location = useLocation();
  const profileDetail = useSelector((state) => state.B2B.profileDetail);
  const navigate = useNavigate();
  const isAuthenticated = () => {
    const user = getLocalStorageItem("isLoggedIn");
    return user;
  };
  useEffect(() => {
    if (
      location.pathname !== "/kycStatus" &&
      location.pathname !== "/Report/wallet" &&
      location.pathname !== "/fundRequest" &&
      location.pathname !== "/personal-info" &&
      location.pathname !== "/Report/fund-request" &&
      (profileDetail.digio_kyc === "0" ||
        profileDetail.digio_kyc === "2" ||
        profileDetail.digio_kyc === "3")
    ) {
      navigate("/kycStatus");
    }
  }, [location]);

  return isAuthenticated() ? (
    <>
      {" "}
      <ProjectLayout>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
