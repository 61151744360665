import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import KycPending from "./KycPending";
import KycRejected from "./KycRejected";
import { dispatchtoast } from "../../../Utils";
import { MemberProfile } from "../../../ApiServices/Apifun";
import { setProfile } from "../../../Store/B2bslice";
import { Spin } from "antd";
import DoKyc from "./DoKyc";

const KycStatus = () => {
  const profileDetail = useSelector((state) => state.B2B.profileDetail);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      MemberProfile()
        .then((res) => {
          if (res.status) {
            dispatch(setProfile(res.data.profile));
          } else {
            dispatchtoast("Unable to fetch profile detail", true);
          }
        })
        .catch((err) => console.log(err, "errr"));
    }, 10000);
    return () => clearTimeout(timer);
  }, []);
  return profileDetail && profileDetail.digio_kyc === "0" ? (
    <KycPending />
  ) : profileDetail && profileDetail.digio_kyc === "2" ? (
    <KycRejected />
  ) : profileDetail && profileDetail.digio_kyc === "1" ? (
    <Navigate to="/dashboard" replace />
  ) : profileDetail && profileDetail.digio_kyc === "3" ? (
    <DoKyc />
  ) : (
    <div className="flex flex-col justify-center items-center leading-7 h-full">
      <Spin spinning={true}></Spin>
      <p>Checking KYC Status</p>
    </div>
  );
};

export default KycStatus;
