import React from "react";
import { ThemeColor } from "../Theme/theme";
export const Cable =({style})=>{
    return(
        <div >
            <svg xmlns="http://www.w3.org/2000/svg"   height="20px"
        width="20px" strokeWidth={"4px"} stroke={ThemeColor.bswhite} viewBox="0 0 94.94 83.07">
                {/* <defs><style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4px;}</style></defs> */}
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                    <rect fill={ThemeColor.primary} className="cls-1" x="2" y="17.37" width="90.94" height="63.7" rx="7.32"/>
                    <polyline fill={ThemeColor.primary} className="cls-1" points="31.23 1.52 48.13 15.91 63.7 1.52"/>
            <rect fill={ThemeColor.primary} className="cls-1" x="19.76" y="31.46" width="55.42" height="35.52"/></g></g></svg>
        </div>
    )
}