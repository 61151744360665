import React from "react";
export const Loan = ({style}) => {
  return (
    <div>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        height="20px"
        width="20px"
        fill={style}
        viewBox="0 0 512 512"
        // style="enable-background:new 0 0 512 512;"
        // xml:space="preserve"
      >
        <path
          d="M159.9,276.8c15.5-2.6,23.7-2.1,54.3-2.1C143,189.9,161.2,61.4,254.9,0c-84.7,1-153.4,70.4-153.4,155.4
	C101.5,204.5,124.3,248.3,159.9,276.8L159.9,276.8z"
        />
        <path
          d="M356.1,0C210.7,0,146,182.1,256.6,274.7h40.5c25.1,0,46.2,15.1,55.5,36.1c86.1,2.3,158.8-67.8,158.8-155.4
	C511.3,69.8,441.7,0,356.1,0z M380.8,109c0.2,0.5,0.4,1.1,0.5,1.7c0.9,3.2,0.9,3.2,4.3,3.2c8.2,0,16.4,0,24.6,0
	c0.6,0,1.5-0.3,1.8,0.3c0.4,0.7-0.4,1.3-0.8,1.8c-2.8,4.2-5.7,8.4-8.4,12.7c-0.8,1.2-1.7,1.7-3.1,1.7c-5.2-0.1-10.4,0-15.6,0
	c-1.1,0-1.8,0.3-2.1,1.5c-2.7,14.5-11.3,24.6-23.8,31.7c-8.7,4.9-18.1,7.4-28,8.1c-0.4,0-0.9-0.2-1.1,0.3c-0.2,0.5,0.3,0.8,0.6,1.2
	c13,16.3,26.1,32.5,39.1,48.8c7.2,8.9,14.3,17.9,21.4,26.8c0.4,0.5,1.1,1,0.9,1.6c-0.3,0.8-1.2,0.4-1.9,0.4c-7.6,0-15.3,0-22.9,0.1
	c-1.6,0-2.6-0.5-3.6-1.8c-18.7-23.5-37.4-47-56.3-70.3c-2.6-3.2-3.9-6.3-3.5-10.3c0.2-2.4,0-4.7,0-7.1c0-2.1,0.1-2.2,2.3-2.2
	c5.7,0,11.4,0.2,17.1-0.1c8.7-0.4,17.1-2.1,24.5-7.1c6.9-4.6,11.3-11,12.3-19.3c0.3-2,0.2-2.2-1.9-2.2c-19.1,0-38.1,0-57.2,0
	c-0.8,0-2,0.4-2.4-0.4c-0.4-0.7,0.6-1.4,1-2.1c2.7-4.1,5.5-8.2,8.1-12.3c0.8-1.2,1.7-1.6,3.1-1.6c15.1,0,30.1,0,45.2,0
	c2.1,0,2.1,0,1.1-1.9c-3.2-5.7-8-9.3-14.1-11.4c-5.2-1.8-10.7-2.5-16.1-2.8c-8.8-0.5-17.7-0.3-26.5-0.3c-0.6,0-1.4,0.3-1.8-0.3
	c-0.4-0.6,0.3-1.2,0.7-1.7c2.8-4.3,5.7-8.5,8.5-12.8c0.8-1.2,1.6-1.6,3-1.6c33.5,0,67,0,100.5,0c0.6,0,1.4-0.2,1.7,0.5
	c0.3,0.5-0.4,1-0.7,1.5c-2.8,4.2-5.7,8.4-8.4,12.7c-0.8,1.3-1.7,1.8-3.2,1.8c-7.7-0.1-15.4,0-23,0h-2.4
	C377.5,101.2,379.5,104.9,380.8,109z"
        />
        <path
          d="M502.2,375.4c-13.1-13.1-34.3-13.1-47.3,0l-49.1,49.2c-5.4,5.4-12.7,8.4-20.3,8.4H257.5c-18.5,0-33.5-15-33.5-33.6
	c0-18.6,15-33.6,33.5-33.6h39c16.8,0,30.7-13.2,31.1-29.8c0.4-17.2-13.4-31.2-30.5-31.2c-102.3,0-39.1,0-111.9,0
	c-37.2,0-72.5,16.5-96.3,45.3l-18.1,23.1c-1,1.2-2.4,1.9-3.9,1.9H10c-5.5,0-10,4.5-10,10.1v116.8c0,5.6,4.5,10.1,10,10.1h387.8
	c9.8,0,19.1-3.9,26-10.8l78.3-78.4C515.3,409.7,515.3,388.5,502.2,375.4L502.2,375.4z"
        />
      </svg>
    </div>
  );
};
