import React from "react";
import { BsBusFrontFill } from "react-icons/bs";
import { MdFlightTakeoff } from "react-icons/md";
import { RiHotelBedFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const Tabbar = ({ from }) => {
  return (
    <>
      <div className="bg-white rounded-md p-4 mb-3">
        <div className="flex flex-wrap justify-start items-center text-[10px] xs:text-[13px] md:text-[14px] gap-x-2 md:gap-4  cursor-pointer">
          <NavLink to={"/flights"}>
          <div>
            <div className="flex justify-center gap-2 items-center p-2">
              <div>
                <MdFlightTakeoff className="text-primary" size={20} />
              </div>
              <p>Flights</p>
            </div>
            {from === "flights" ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ):<div className="p-2"></div>}
          </div>
          </NavLink>
         <NavLink to={"/bus"}>
         <div>
            <div className="flex justify-center gap-2 items-center p-2">
              <div>
                <BsBusFrontFill className="text-primary" size={20} />
              </div>
              <p>Bus</p>
            </div>
            {from === "bus" ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ):<div className="p-2"></div>}
          </div>
         </NavLink>
          <NavLink to={"/hotel"}>
          <div>
            <div className="flex justify-center gap-2 items-center p-2">
              <div>
                <RiHotelBedFill className="text-primary" size={20} />
              </div>
              <p>Hotel</p>
            </div>
            {from === "hotel" ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ):<div className="p-2"></div>}
          </div>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Tabbar;
