import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { InsertRowAboveOutlined } from "@ant-design/icons";
import { Images } from "../../../Controller";
import { BiRefresh } from "react-icons/bi";
import { PortalName, serviceCategory, trimString } from "../../../Utils";
import { AiFillCheckCircle, AiOutlineUser } from "react-icons/ai";
import { ThemeColor } from "../../../Theme/theme";
import { FcCancel } from "react-icons/fc";

import {
  GetDashboardData,
  GetWalletBalance,
} from "../../../ApiServices/Apifun";
import { BsArrowDown, BsArrowUp, BsChevronRight } from "react-icons/bs";
import { Carousel, Collapse, Skeleton, Spin, Tooltip } from "antd";
import { MdCancel, MdPending } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState({
    Total_txn: "",
    money_in: "",
    money_out: "",
    members: "",
  });
  const [spinState, setSpinState] = useState(false);
  const [collapsed, setCollapsed] = useState(["0"]);
  const [loader, setLoader] = useState(false);
  const [activeServices, setActiveServices] = useState([]);
  const [banners, setBanners] = useState([]);
  const [inActiveServices, setInactiveServices] = useState([]);
  const [accordionData, setAccordionData] = useState([]);
  const walletBalance = useSelector((state) => state.B2B.walletBal);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberService = useSelector((state) => state.B2B.memberServices);
  const [Balance, setBalance] = useState(null);
  useEffect(() => {
    dispatch(setselectedTab("1"));
    let active = [];
    let inactive = [];
    Object.entries(serviceCategory).map(([key, val]) => {
      if (memberService.includes(val)) {
        active = [...active, key];
      } else {
        inactive = [...inactive, key];
      }
    });
    setActiveServices(active);
    setInactiveServices(inactive);
    setBalance(walletBalance);
  }, [memberService, walletBalance]);

  const getDashboard = () => {
    setLoader(true);
    GetDashboardData()
      .then((res) => {
        setBanners(res.banners);
        setAccordionData(
          res.services.map((i, index) => {
            return {
              key: index,
              label: (
                <div className="grid grid-cols-3 w-full gap-2 place-items-start">
                  <img
                    src={i.icon}
                    className="w-10 h-10 bg-white  p-1 rounded-full"
                    alt="icon"
                  />
                  <div className="text-xs col-span-2 font-medium w-full">
                    <p>{i.service_name}</p>
                    <p>Total amt: {i.total_amount} Rs.</p>
                  </div>
                </div>
              ),
              children: (
                <div className=" text-xs font-bold leading-5">
                  <div className=" shadow-lg mt-2 shadow-gray-400 rounded-md p-3 flex justify-around  items-center">
                    <p className=""> Success</p>
                    <p className="">
                      {i.total_transactions_success} (
                      {"₹" + " " + i.total_amount_success})
                    </p>
                    <AiFillCheckCircle
                      className="bg-white  shadow-black text-green-700"
                      size={25}
                    />
                  </div>
                  <div className=" shadow-lg mt-2 shadow-gray-400 rounded-md p-3 flex justify-around  items-center">
                    <p className=""> Pending</p>
                    <p className="">
                      {i.total_transactions_pending} (
                      {"₹" + " " + i.total_amount_pending})
                    </p>
                    <MdPending
                      className="bg-white  shadow-black text-yellow-700"
                      size={25}
                    />
                  </div>
                  <div className=" shadow-lg mt-2 shadow-gray-400 rounded-md p-3 flex justify-around  items-center">
                    <p className=""> Failed</p>
                    <p className="">
                      {i.total_transactions_failed} (
                      {"₹" + " " + i.total_amount_failed})
                    </p>
                    <MdCancel
                      className="bg-white  shadow-black text-red-700"
                      size={25}
                    />
                  </div>
                </div>
              ),
              // showArrow: false,
            };
          })
        );
        setCardData({
          Total_txn: res.total_transactions,
          money_in: res.money_in,
          money_out: res.money_out,
          members: res.total_members,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoader(false));
  };
  useEffect(() => {
    getDashboard();
  }, []);
  useEffect(() => {}, [collapsed]);
  const handleGet = () => {
    setSpinState(true);
    GetWalletBalance()
      .then((res) => {
        setBalance(res.balance);
        setSpinState(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 place-items-start gap-3">
        <div className="col-span-2 w-full md:col-span-3 ">
          <div className="bg-primary rounded-3xl ">
            <Carousel>
              {banners.map((i) => (
                <div>
                  <div className="relative grid  bg-primary rounded-3xl grid-cols-1 sm:grid-cols-2 place-items-center  ">
                    <div className=" p-5">
                      <p className=" mt-8 font-bold text-2xl text-white">{i.title}</p>
                      <p className="mt-8 text-white text-xs">{i.subtitle}</p>
                      <p className="mt-12 text-center w-auto bg-primaryhover text-white p-3 rounded-full cursor-pointer">
                        Explore the AEPS
                      </p>
                    </div>
                    <div className="rounded-3xl w-full">
                      <img src={i.image} className="p-4 w-full h-[250px]  rounded-3xl" />
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          {loader ? (
            <div className="grid grid-cols-1 mt-4 sm:grid-cols-2 md:grid-cols-2 gap-5 place-items-center">
              {[1, 2, 3].map((i) => (
                <div className="  h-[100px]   w-full">
                  <Skeleton.Button
                    active={true}
                    size={200}
                    shape={"default"}
                    block={false}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4  mt-6 place-items-center">
              <div className="text-bswhite rounded-2xl text-xl w-full bg-[#68e365] border-black p-8 ">
                <div className="flex justify-start gap-4 items-center">
                  <InsertRowAboveOutlined
                    size={52}
                    className="hover:animate-spin border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4"
                  />
                  <div>
                    <p>{cardData.Total_txn}</p>
                    <p>Total Txn</p>
                  </div>
                </div>
              </div>
              <div className="text-bswhite rounded-2xl text-xl w-full bg-[#b48dd3] border-black p-8 ">
                <div className="flex justify-start gap-4 items-center">
                  <BsArrowDown
                    size={52}
                    className="hover:animate-spin border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4"
                  />
                  <div>
                    <p>{cardData.money_in} ₹</p>
                    <p>Money In</p>
                  </div>
                </div>
              </div>
              <div className="text-bswhite rounded-2xl text-xl w-full bg-[#709fba] border-black p-8 ">
                <div className="flex justify-start gap-4 items-center">
                  <BsArrowUp
                    size={52}
                    className="hover:animate-spin border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4"
                  />
                  <div>
                    <p>{cardData.money_out} ₹</p>
                    <p>Money Out</p>
                  </div>
                </div>
              </div>
              {profiledetail.member_type !== "Retailer" ? (
                <div className="text-bswhite rounded-2xl text-xl w-full bg-[#e36532] border-black p-8 ">
                  <div className="flex justify-start gap-4 items-center">
                    <AiOutlineUser
                      size={52}
                      className=" border-dashed cursor-pointer transition-all border-black font-extrabold bg-[#ffffff42] rounded-full p-4"
                    />
                    <div>
                      <p>{cardData.members}</p>
                      <p>Members</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="w-full">
          <div className="bg-primary h-[190px]  pl-8 relative animate-fade-in  w-full  py-3 rounded-3xl ">
            <div>
              <img src={Images.circleShape}/>
              </div>
            <div className="text-white  mt-1">
              <p>Available Balance</p>
            </div>
            <p className="text-white   font-semibold text-2xl">
              Rs. {Balance ?? "-"}/-
            </p>
            <p className="text-white">Wallet Balance</p>
            <div
              onClick={handleGet}
              className="bg-white rounded-xl p-4 flex gap-4 cursor-pointer justify-around items-center  absolute bottom-4 w-[40%]  right-2 md:right-6"
            >
              <BiRefresh
                className={`${spinState ? "animate-spin" : ""}`}
                size={20}
              />
              <p className="text-md font-semibold ">Refresh</p>
            </div>
          </div>
          <Spin spinning={loader}>
            <div className="bg-white h-[500px]  overflow-y-scroll rounded-3xl mt-3 p-3 w-full">
              <div className="flex justify-start items-center gap-4">
                <p className="bg-primary w-4 h-4 rounded-md"></p>
                <p className="font-bold">Active Services</p>
              </div>
              <Collapse
                onChange={(val) => setCollapsed(val)}
                defaultActiveKey={["0"]}
                ghost
                items={accordionData}
              />
            </div>
          </Spin>
        </div>
      </div>

      {/* <div className="bg-white p-8 rounded-xl mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 place-items-start">
        <div className="bg-[#5e72e4] h-[190px]  pl-8 relative animate-fade-in  w-full md:w-[90%] py-3 rounded-3xl ">
          <div className="text-white  mb-4">
            <img src={Images.circleShape} alt="circle" />
          </div>
          <p className="text-bswhite font-semibold text-2xl">
            Rs. {Balance ?? "-"}/-
          </p>
          <p className="text-white">Wallet Balance</p>
          <div
            onClick={handleGet}
            className="bg-white rounded-xl p-4 flex gap-4 cursor-pointer justify-around items-center absolute bottom-4 md:bottom-16 md:rotate-90 right-2 md:right-6"
          >
            <BiRefresh
              className={`${spinState ? "animate-spin" : ""}`}
              size={20}
            />
            <p className="text-md font-semibold ">Refresh</p>
          </div>
        </div>
    
        <div className="w-full">
          <p className="font-bold text-xl border-b-2 border-primary w-1/4">
            Services
          </p>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 place-items-start gap-8 mt-2">
            {activeServices.map((i, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-around items-center gap-3  "
                >
                  <AiFillCheckCircle size={20} color={ThemeColor.bsgreen} />
                  <Tooltip title={i}>
                  <p className="font-semibold capitalize">
                    {trimString(i, 12)}
                  </p>
                  </Tooltip>
                </div>
              );
            })}
            {inActiveServices.map((i, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-around items-center gap-3  "
                >
                  <FcCancel size={20} color={ThemeColor.bsred} />
                  <Tooltip title={i}>
                  <p className="font-semibold capitalize">{i}</p>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
