import React from "react";
export const Water = ({ style }) => {
  return (
    <div >
      <svg
        height="20px"
        width="20px"
        viewBox="0 0 26 35"
        fill={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6219 34.9999C9.82062 35.0103 7.09722 34.0786 4.88932 32.3546C3.56023 31.3119 2.45266 30.0143 1.63152 28.538C0.810385 27.0617 0.292223 25.4364 0.107382 23.7572C-0.280181 20.8972 0.389094 17.994 1.98965 15.5923L8.88278 5.31612C9.79848 3.96801 10.7142 2.64534 11.6044 1.29724L11.7825 1.06831L11.8588 0.966567L12.6982 0L13.6139 1.24636L13.8937 1.6279L15.1909 3.56104L23.1523 15.3888C24.3274 17.174 25.0316 19.2275 25.1995 21.3581C25.3674 23.4887 24.9935 25.6272 24.1125 27.5744C23.2316 29.5216 21.8723 31.2143 20.1611 32.4948C18.45 33.7754 16.4427 34.6021 14.3261 34.8982C13.7665 34.949 13.1814 34.9999 12.6219 34.9999ZM12.6473 3.40842L10.587 6.46074C8.32318 9.81829 5.95764 13.303 3.69385 16.7369C2.34574 18.7463 1.83703 21.0101 2.11683 23.5283C2.27738 24.9379 2.71511 26.3018 3.40483 27.5416C4.09456 28.7813 5.02263 29.8724 6.13569 30.7521C7.25176 31.6138 8.52845 32.2444 9.89109 32.6069C11.2537 32.9694 12.675 33.0566 14.0717 32.8633C15.8441 32.6165 17.5251 31.9254 18.9585 30.8542C20.3919 29.783 21.531 28.3666 22.2697 26.7367C23.0085 25.1069 23.3229 23.3167 23.1837 21.5327C23.0445 19.7487 22.4562 18.0289 21.4736 16.5334L13.5121 4.70566L12.6473 3.40842Z"
          fill="#white"
        />
        <path
          d="M13.2323 31.4644C11.139 31.4729 9.10427 30.7737 7.45831 29.4804C6.46995 28.7065 5.6459 27.7433 5.03443 26.647C4.42297 25.5507 4.03637 24.3435 3.89726 23.0959C3.62024 20.9639 4.12637 18.8038 5.32168 17.0167C5.47158 16.796 5.70223 16.6429 5.96391 16.5906C6.2256 16.5382 6.49737 16.5908 6.72066 16.737C6.83472 16.8068 6.93345 16.8991 7.01095 17.0081C7.08844 17.1171 7.14309 17.2407 7.1716 17.3714C7.20011 17.502 7.20192 17.6371 7.17688 17.7685C7.15184 17.8999 7.10046 18.0249 7.02588 18.1359C6.0907 19.5243 5.70121 21.2091 5.93215 22.867C6.03873 23.8423 6.33948 24.7864 6.81667 25.6437C7.29385 26.501 7.9378 27.254 8.71059 27.8585C9.48338 28.463 10.3694 28.9067 11.3163 29.1634C12.2633 29.4201 13.252 29.4846 14.2243 29.3532C14.3581 29.3354 14.4941 29.3444 14.6243 29.3797C14.7546 29.415 14.8765 29.4758 14.983 29.5586C15.0896 29.6415 15.1785 29.7447 15.2448 29.8623C15.311 29.9799 15.3532 30.1094 15.3689 30.2435C15.3867 30.3739 15.3784 30.5066 15.3444 30.6338C15.3105 30.7609 15.2515 30.8801 15.171 30.9842C15.0905 31.0884 14.9901 31.1755 14.8756 31.2405C14.7611 31.3055 14.6348 31.347 14.5041 31.3626C14.0839 31.4334 13.6584 31.4674 13.2323 31.4644Z"
          fill="#white"
        />
      </svg>
    </div>
  );
};
