import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { utiLogin } from "../../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setselectedTab } from "../../../Store/B2bslice";

const Uti = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCheck = () => {
    setBtnLoading(true);
    utiLogin()
      .then((res) => {
        if (res.status) {
          window.open(res.data.redirectUrl, "_blank");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("7"));
    if (!memberServices.includes("16")) {
      navigate("/not-Available");
    }
  }, []);
  return (
    <>
     
      <div className="flex h-full flex-col gap-4 justify-center items-center">
        <p className="font-semibold text-center text-xl">
          Press Button For PAN Services
        </p>
        <Button
          onClick={handleCheck}
          loading={btnLoading}
          className="bg-primary font-semibold text-white border-none"
        >
          Check
        </Button>
      </div>
    </>
  );
};

export default Uti;
