import React from "react";
export const Insurance = ({ style }) => {
  return (
    <div >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20px"
        width="20px"
        fill={style}
        viewBox="0 0 88.48 88.28"
      >
        {/* <defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;}</style></defs> */}
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path className="cls-1" d="M2,44.24a42.24,42.24,0,0,1,84.48,0" />
            <path className="cls-1" d="M2,44.43a10.56,10.56,0,1,1,21.12,0" />
            <path className="cls-1" d="M23.12,44.43a10.56,10.56,0,1,1,21.12,0" />
            <path className="cls-1" d="M65.36,44.43a10.56,10.56,0,1,0-21.12,0" />
            <path className="cls-1" d="M65.36,44.43a10.56,10.56,0,1,1,21.12,0" />
            <path
              className="cls-1"
              d="M23.12,75.7a10.56,10.56,0,1,0,21.12,0V44.43"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
