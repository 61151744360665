import React, { useState } from "react";
import { Images } from "../../../Controller";
import { kycRequest } from "../../../ApiServices/Apifun";
import { getLocalStorageItem, showkycWindow } from "../../../Utils";
import { useSelector } from "react-redux";
import { Button } from "antd";

const KycRejected = () => {
  const [loading, setLoading] = useState(false);
  const profileDetail = useSelector((state) => state.B2B.profileDetail);
  const handleDo = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    kycRequest(formdata)
      .then((res) => {
        const script1 = document.createElement("script");
        script1.src = "https://app.digio.in/sdk/v10/digio.js";
        script1.async = true;
        document.head.appendChild(script1);
        showkycWindow(
          res.data.digio_request_id,
          res.data.mobile,
          res.data.digio_access_token
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex justify-center h-full items-center">
      <div className=" h-full md:h-5/6 pt-8 md:pt-0 rounded-bl-lg rounded-br-lg md:rounded-br-xl md:rounded-tr-xl relative  w-full flex flex-col justify-center items-center">
        <div className=" w-1/2 m-auto  flex flex-col justify-center items-center">
          <img src={Images.rejectedKYC} className="w-1/2" />

          <p className="font-semibold text-xs text-center m-4">
            Your KYC is rejected by admin. Please Contact.
          </p>
          <p className="text-red-500">
            Reason:- {profileDetail.digio_kyc_remark}
          </p>
          <p
            className="mb-2 cursor-pointer text-blue-700"
            onClick={() => window.location.reload()}
          >
            To check status Kindly, Reload The Page!
          </p>
          <Button
            onClick={handleDo}
            loading={loading}
            className="w-full rounded-md border-none text-white font-semibold bg-primary hover:bg-primaryhover"
          >
            Re-Kyc
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KycRejected;
