import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import ProjectLayout from "../ProjectLayout";
import { getLocalStorageItem } from "../Utils";
import ErrorBoundary from "../ErrrorBoundry";
function PublicAuth(props) {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("isLoggedIn");
    return user;
  };

  return isAuthenticated() ? (
    <>
      {" "}
      <Navigate to="/dashboard" />
    </>
  ) : (
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
  );
}
export default PublicAuth;
