import { Button, Form, Select } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MantraPIDOPTS,
  MorphpoPIDOPTS,
  ToastContainerMsg,
  aadharPattern,
  captureUrl,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  handleSubmitForAeps,
  mantrairisPIDOPTS,
  mobilePattern,
  parseXml,
  startekPIDOPTS,
} from "../../../../../Utils";
import {
  AepsCashWithdrawl,
  getAllAepsMasters,
} from "../../../../../ApiServices/Apifun";
import { xml2json } from "../../../../../Utils/xml2json";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReportDrawer from "../../../../../Common/ReportDrawer";
import { setselectedTab } from "../../../../../Store/B2bslice";
import CommonInput from "../../../../../Common/CommonInput";
import TwoFaModal from "./TwoFaModal";
import TransactionTwoFaModal from "./TransactionTwoFaModal";

const WithdrawalMoney = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [openTwoFaModal, setOpenTwoFaModal] = useState(false);
  const [openTransactionTwoFaModal, setTransactionTwoFaModal] = useState(false);
  const [filledData, setFilledData] = useState(null);
  // const [openModal, setOpenModal] = useState(false);
  const [response, setResponse] = useState(null);
  console.log(response, "responseee");
  const [amount, setAmount] = useState("");
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [userBanks, setUserBanks] = useState([]);
  const [seconds, setSeconds] = useState(180);

  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  // useEffect(() => {
  //   let timer;
  //   let a = 180;
  //   timer = setInterval(() => {
  //     if (a > 0) {
  //       a = a - 1;
  //       setSeconds(a);
  //     } else {
  //       // navigate("/banking/aeps", {
  //       //   state: { from: "/banking/aeps-cash-withdrawl", type: "aeps" },
  //       // }); // Clear the interval when seconds reach 0
  //       // // dispatch(setCashWithdrawalTimer(180));
  //       setOpenTwoFaModal(true)
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [openTwoFaModal]);
  useEffect(() => {
    dispatch(setselectedTab("3.2"));
    const handleAepsMasters = () => {
      getAllAepsMasters()
        .then((res) => {
          setUserBanks(
            res.data.user_banks.map((i) => ({
              label: i.bank_name,
              value: i.id,
              key: i.id,
            }))
          );
        })
        .catch((err) => console.log(err));
    };
    handleAepsMasters();
    // if (!location.state?.allow) {
    //   if (memberServices.includes("8")) {
    //     if (
    //       Object.keys(profiledetail).length !== 0 &&
    //       !profiledetail.aeps_onboard
    //     ) {
    //       navigate("/banking/aeps-onboard");
    //     } else if (!profiledetail.ekyc_status) {
    //       navigate("/banking/aeps-ekyc");
    //     } else if (!profiledetail.twofa_status) {
    //       navigate("/banking/aeps", {
    //         state: { from: "/banking/aeps-cash-withdrawl", type: "aeps" },
    //       });
    //     } else {
    //       handleAepsMasters();
    //     }
    //   } else {
    //     navigate("/not-available");
    //   }
    // }
    // else {
    //   handleAepsMasters();
    // }
  }, []);
  const Content = () => {
    return (
      <>
        <div className="border-2 rounded-md w-9/12 border-dashed border-gray-500  ml-4 mr-4 text-center mt-5">
          <p className="-mt-2 bg-white w-[100px] flex justify-center items-center font-semibold m-auto text-[11px]">
            Transaction I'd
          </p>
          <p className="text-[11px] mt-2 mb-2">
            {response?.data?.merchantTransactionId}
          </p>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Merchant Name</p>
            <p className="text-black">{response?.data?.merchant_name}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Bank Name</p>
            <p className="text-black">{response?.data?.bank_name}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Aadhar Number</p>
            <p className="text-black">{response?.data?.masked_aadhaar}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Withdrawl Amount</p>
            <p className="text-black">{filledData?.amount} Rs.</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Remaining Balance</p>
            <p className="text-black">
              {response?.data?.balanceAmount ?? "0"} Rs.
            </p>
          </div>
        </div>

        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Bank RRN</p>
            <p className="text-black">{response?.data?.rrn} Rs.</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Status</p>
            <p className="text-black">{response?.data?.status}</p>
          </div>
        </div>
      </>
    );
  };
  // var PIDOPTS =
  //   '<PidOptions ver="1.0">' +
  //   '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" env="P" otp="" wadh="" posh=""/>' +
  //   "</PidOptions>";

  // function getPosition(string, subString, index) {
  //   return string.split(subString, index).join(subString).length;
  // }
  const capture = (val, port = 11100) => {
    setAmount(val.amount);
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        val.device === "morpho"
          ? captureUrl
          : val.device === "startek"
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        val.device === "morpho"
          ? MorphpoPIDOPTS
          : val.device === "mantra"
          ? MantraPIDOPTS
          : val.device === "startek"
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        console.log({ response });
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        // const test1 = response.data;
        // const test2 = test1.search("errCode");
        // const test6 = getPosition(test1, '"', 2);
        // const test4 = test2 + 9;
        // const test5 = test1.slice(test4, test6);
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(
            `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
            true
          );
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const parsed = parseXml(response.data);
          const xmltojson = JSON.parse(
            xml2json(parsed).replace("undefined", "")
          );
          console.log({ xmltojson });
          const {
            errCode,
            errInfo,
            fCount,
            fType,
            pCount,
            iCount,
            nmPoints,
            qScore,
          } = xmltojson.PidData.Resp;
          const { dpId, rdsId, rdsVer, mi, mc, dc } =
            xmltojson?.PidData?.DeviceInfo;
          const { ci } = xmltojson.PidData.Skey;
          const { Hmac } = xmltojson.PidData;
          const payload = {
            customer_mobile: val.customer_mobile,
            customer_aadhaar_number: val.customer_aadhaar_number,
            amount: val.amount,
            user_bank: val.user_bank.value,
            user_bank_name: val.user_bank.label,
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            device_imei: Array.isArray(
              xmltojson.PidData.DeviceInfo.additional_info.Param
            )
              ? xmltojson.PidData.DeviceInfo.additional_info.Param[0].value
              : xmltojson.PidData.DeviceInfo.additional_info.Param.value,
            errCode: errCode ? errCode : 0,
            errInfo: errInfo ? errInfo : 0,
            fCount: fCount ? fCount : 0,
            fType:
              val.device === "morpho" ||
              val.device === "mantra" ||
              val.device === "startek"
                ? fType || 2
                : 0,
            iType:
              val.device === "morpho" ||
              val.device === "mantra" ||
              val.device === "startek"
                ? 0
                : 1,
            iCount: iCount ? iCount : 0,
            pCount: pCount ? pCount : 0,
            pType: 0,
            nmPoints: nmPoints ? nmPoints : 0,
            qScore: qScore ? qScore : 0,
            dpID: dpId ? dpId : 0,
            rdsID: rdsId ? rdsId : 0,
            rdsVer: rdsVer ? rdsVer : 0,
            dc: dc ? dc : 0,
            mi: mi ? mi : 0,
            mc: mc ? mc : 0,
            ci: ci ? ci : 0,
            sessionKey: xmltojson.PidData.Skey["#text"],
            hmac: Hmac ? Hmac : 0,
            PidDatatype: xmltojson.PidData.Data.type,
            Piddata: xmltojson.PidData.Data["#text"],
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          AepsCashWithdrawl(formdata)
            .then((res) => {
              if (res.status_1 === false) {
                navigate("/banking/aeps", {
                  state: { from: "/banking/aeps-cash-withdrawl", type: "aeps" },
                });
              }
              if (res.status) {
                setResponse(res);
                dispatchtoast(res.data.message);
                setOpenReceiptDrawer(true);
              }else {
                navigate("/banking/aeps", {
                  state: { from: "/banking/aeps-cash-withdrawl", type: "aeps" },
                });
              }
            })
            .catch((err) => {
              if (err.response.data.status === false || err.response.data.status_1 === false) {
                navigate("/banking/aeps", {
                  state: { from: "/banking/aeps-cash-withdrawl", type: "aeps" },
                });
              }
            })
            .finally(() => setBtnLoading(false));
          // .finally(() => navigate("/dashboard"));
        }
      })
      .catch((error) => {
        setBtnLoading(false);
        console.log(error.response ? error.response.data : error.message);
      });
  };
  // function DeviceInfo(val) {
  //   var url = "https://localhost:11100/getDeviceInfo";
  //   axios({
  //     method: "DEVICEINFO", // Set the custom request method here
  //     url: url,
  //     headers: {
  //       "Content-Type": "text/xml",
  //       Accept: "text/xml",
  //     },
  //   })
  //     .then((res) => {
  //       const xmltojson = convertXmlToJson(res.data);
  //       const checkDeviceConnection = Object.values(xmltojson.attributes).every(
  //         (val) => val !== ""
  //       );
  //       if (checkDeviceConnection) {
  //         capture(val);
  //       } else {
  //         dispatchtoast("Please connect device to scan fingerprint", true);
  //       }
  //     })
  //     .catch((err) => console.log(err));

  //   // var xhr;
  //   // var ua = window.navigator.userAgent;
  //   // var msie = ua.indexOf("MSIE ");

  //   // if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
  //   //   // If Internet Explorer, return version number
  //   //   //IE browser
  //   //   xhr = new ActiveXObject("Microsoft.XMLHTTP");
  //   // } else {
  //   //   //other browser
  //   //   xhr = new XMLHttpRequest();
  //   // }

  //   // //
  //   // xhr.open("DEVICEINFO", url, true);

  //   // xhr.onreadystatechange = function () {
  //   //   // if(xhr.readyState == 1 && count == 0){
  //   //   //	fakeCall();
  //   //   //}
  //   //   if (xhr.readyState == 4) {
  //   //     var status = xhr.status;

  //   //     if (status == 200) {
  //   //       alert(xhr.responseText);

  //   //       console.log(xhr.response);
  //   //     } else {
  //   //       console.log(xhr.response);
  //   //     }
  //   //   }
  //   // };

  //   // xhr.send();
  // }
  // const formatTime = (timeInSeconds) => {
  //   const minutes = Math.floor(timeInSeconds / 60);
  //   const seconds = timeInSeconds % 60;
  //   return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  // };
  return (
    <>
      {/* <div className=" flex justify-end my-3 items-center  text-white">
        <p
          className={`text-right bg-red-600 p-3 rounded-full  cursor-pointer font-semibold ${
            seconds > 0 ? "text-white" : "text-blue-600"
          }`}
        >
          {seconds > 0 ? `2FA valid for ${formatTime(seconds)} sec` : ""}
        </p>
      </div> */}
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">Cash Withdrawl</p>
          <Form
            ref={formRef}
            name="basic"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinishFailed={(err) => console.log(err)}
            onFinish={
              (val) => {
                setFilledData(val);
                setOpenTwoFaModal(true);
              }
              // handleSubmitForAeps(val, capture)
            }
          >
            <Form.Item
              name={`customer_mobile`}
              label={"Mobile Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Mobile Number!",
                },
                {
                  pattern: mobilePattern,
                  message: "Invalid mobile number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Mobile Number"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                name="customer_mobile"
              />
            </Form.Item>
            <Form.Item
              name={`customer_aadhaar_number`}
              label={"Aadhar Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter aadhar Number!",
                },
                {
                  pattern: aadharPattern,
                  message: "Invalid aadhar number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                placeholder="Enter Aadhar Number"
                name="customer_aadhaar_number"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`amount`}
              label={"Amount"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Amount!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Amount"
                name="amount"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name={`user_bank`}
              label={"Select Bank"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one bank!",
                },
              ]}
            >
              <Select
                showSearch
                labelInValue={true}
                placeholder="Select a bank"
                filterOption={filterOption}
                className="w-full mt-2"
                options={userBanks}
              />
            </Form.Item>
            {/* <Form.Item
              name={`device`}
              label={"Select Device"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one device!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Device"
                filterOption={filterOption}
                className="w-full mt-2"
                options={deviceOptions}
              />
            </Form.Item> */}
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Cash Withdrawl Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content />}
        />
      )}
      {openTwoFaModal && (
        <TwoFaModal
          type={"aeps"}
          opentwofa={openTwoFaModal}
          setOpenTwoFaModal={setOpenTwoFaModal}
          opentransactionmodal={openTransactionTwoFaModal}
          setopentransactionModal={setTransactionTwoFaModal}
        />
      )}
      {openTransactionTwoFaModal && (
        <TransactionTwoFaModal
          type={"aeps"}
          form={form}
          setOpenReceiptDrawer={setOpenReceiptDrawer}
          setResponse={setResponse}
          filledData={filledData}
          opentwofa={openTwoFaModal}
          setOpenTwoFaModal={setOpenTwoFaModal}
          opentransactionmodal={openTransactionTwoFaModal}
          setopentransactionModal={setTransactionTwoFaModal}
        />
      )}
    </>
  );
};

export default WithdrawalMoney;
