import React, { useState } from 'react'
import { Button, Form, Input } from "antd";
import { changePin } from '../../../ApiServices/Apifun';
import { dispatchtoast } from '../../../Utils';

const ChangePin = () => {
    const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("new_pin")) {
      return Promise.reject("The pin do not match!");
    }
    return Promise.resolve();
  };
  const handleFinish = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    Object.keys(val).map((key) => formdata.append(key, val[key]));
    changePin(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          form.resetFields()
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <div className="w-full bg-white p-4 rounded-md mt-3 flex justify-start items-start">
    <Form
      form={form}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinishFailed={(err) => console.log(err)}
      onFinish={(val) => handleFinish(val)}
    >
        <Form.Item
        name={`password`}
        label={"Password"}
        className="w-full"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Enter password!",
          },
          {
            whitespace: true,
            message: "Field cannot contain only blank spaces",
          },
        ]}
      >
        <Input.Password
          placeholder="Enter password"
          name="password"
          className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
        />
      </Form.Item>
      <Form.Item
        name={`old_pin`}
        label={"Old pin"}
        className="w-full"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Enter old pin!",
          },
          {
            whitespace: true,
            message: "Field cannot contain only blank spaces",
          },
        ]}
      >
        <Input.Password
          placeholder="Enter old pin"
          name="old_pin"
          className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
        />
      </Form.Item>
      <Form.Item
        name={`new_pin`}
        label={"New pin"}
        className="w-full"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Enter new pin!",
          },
          {
            whitespace: true,
            message: "Field cannot contain only blank spaces",
          },
        ]}
      >
        <Input.Password
        maxLength={4}
          placeholder="Enter new pin"
          name="new_pin"
          className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
        />
      </Form.Item>{" "}
      <Form.Item
        name={`confirm_new_pin`}
        label={"Confirm Pin"}
        labelCol={{ span: 24 }}
        className="w-full"
        dependencies={["new_pin"]}
        rules={[
          {
            required: true,
            message: "Please confirm your pin!",
          },
          { validator: validatePassword },
          {
            whitespace: true,
            message: "Field cannot contain only blank spaces",
          },
        ]}
      >
        <Input.Password
        maxLength={4}
          placeholder="Confirm your pin"
          name="confirm_new_pin"
          className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
        />
      </Form.Item>{" "}
      <Form.Item>
        <Button
          loading={btnLoading}
          className="w-full font-semibold h-10 bg-primary hover:bg-primaryhover text-white mt-2 border-none"
          htmlType="submit"
        >
          Save
        </Button>
      </Form.Item>{" "}
    </Form>
  </div>
  )
}

export default ChangePin