import React, { useState } from "react";
import { Button, Modal } from "antd";
import useOtpHook from "../../../Hooks/useOtpHook";
import { XpressPayout } from "../../../ApiServices/Apifun";
import { dispatchtoast, getLocalStorageItem } from "../../../Utils";

const OtpExpressPayout = ({
  isOpen,
  setIsOpen,
  otherFields,
  form,
  setResult,
  setOpenReceiptDrawer,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const { handleChange, handleKeyUp, pin, inputRefs } = useOtpHook(6);

  const handleVerify = () => {
    setBtnLoading(true);
    let data={...otherFields}
    data.bank_id=otherFields.bank_id?.value?otherFields.bank_id?.value:otherFields?.bank_id
    const formdata = new FormData();
    formdata.append("otp", pin.join(""));
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    Object.keys(data).map((key) =>
      formdata.append(key, data[key])
    );
    XpressPayout(formdata)
      .then((res) => {
        if (res) {
          if (res.status) {
            dispatchtoast("Xpress payout successfull");
            setIsOpen(false);
            form.resetFields();
            setResult({
              data: {
                "Benificiary Name": res.data.benificiary_name,
                "Account No.": res.data.account_no,
                Amount: otherFields.amount,
                "Api Transaction Id": res.data.api_transaction_id,
                status:
                  res.data.payment_status === 1
                    ? "Success"
                    : res.data.payment_status === 2
                    ? "Failed"
                    : "Pending",
                Remark: res.message,
              },
              transaction_id: res.data.transaction_id,
            });
            setOpenReceiptDrawer(true);
          } 
        } else {
          dispatchtoast("Something went wrong", true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal
      title={"Verify OTP"}
      footer={null}
      className="shadow-normal"
      width={300}
      maskClosable={false}
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
        form.resetFields();
      }}
    >
      <div className="  rounded-3xl shadow-normal bg-white p-5">
        <div className="header flex flex-col justify-center items-center">
          <p className="text-[15px] mt-3 text-gray-500">Enter OTP</p>
        </div>
        <div className=" mt-5 flex justify-center items-center">
          {[1, 2, 3, 4, 5, 6].map((i, index) => {
            return (
              <>
                <input
                  key={index}
                  ref={inputRefs[index]}
                  type="text"
                  maxLength={1}
                  className="w-[15px] otpinput xs:w-[35px] sm:w-[35px] md:w-[40px] text-bsblackrgb rounded-md bg-gray-300 h-8  mx-1 border-none focus:outline-none focus:border-none text-center"
                  value={pin[index]}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyUp={(e) => handleKeyUp(index, e)}
                />
              </>
            );
          })}
        </div>
        <Button
          loading={btnLoading}
          onClick={() => pin.length === 6 && handleVerify()}
          className={`SignInBtn w-full ${
            pin.length === 6 ? "cursor-pointer" : "cursor-not-allowed"
          }  mt-4 ${
            pin.length === 6 ? "bg-primary" : "bg-bsgray"
          }  flex justify-center border-none items-center h-12 mt-10 rounded-full text-white text-[12px] font-bold`}
        >
          <p>Verify OTP</p>
        </Button>
      </div>
    </Modal>
  );
};

export default OtpExpressPayout;
