import {
    Button,
    DatePicker,
    Select,
    Spin,
    Table,
    Tag,
    Tooltip,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    checkPanStatus,
    getNsdlReport,
    nsdlRequest,
  } from "../../../ApiServices/Apifun";
  import CommonInput from "../../../Common/CommonInput";
  import { AiOutlineSearch } from "react-icons/ai";
  import { dispatchtoast, formatDateAndTime, trimString } from "../../../Utils";
  import dayjs from "dayjs";
import { setselectedTab } from "../../../Store/B2bslice";
import { CiCircleCheck, CiEdit } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import CommonPagination from "../../../Common/Pagination";

const NsdlReport = () => {
      const [dataSource, setDataSource] = useState([]);
      const [current, setCurrent] = useState(1);
      const [searchKeyword, setSearchKeyWord] = useState("");
      const [searchcolumn, setSearchColumn] = useState(null);
      const [fromDate, setFromDate] = useState("");
      const [toDate, setToDate] = useState("");
      const [totalPages, setTotalPages] = useState(null);
      const [pageSize, setPageSize] = useState(10);
      // const memberServices = useSelector((state) => state.B2B.memberServices);
      const [searchOptions, setSearchOptions] = useState([]);
      const [loading, setLoading] = useState(false);
      const dispatch = useDispatch();
    const ReportColumn = [
        {
          title: "Date",
          dataIndex: "Date",
          key: "Date",
          render: (text) => (
            <p className="font-medium w-[150px]">{formatDateAndTime(text)}</p>
          ),
        },
        {
          title: "Transaction Id",
          dataIndex: "transaction_id",
          key: "transaction_id",
          render: (text) => {
            return (
              <>
                <p className=" font-medium  overflow-y-scroll">{text}</p>
              </>
            );
          },
        },
        {
          title: "Ack No.",
          dataIndex: "ack_no",
          key: "ack_no",
          render: (text) => (
            <p style={{ width: "50px" }} className=" font-medium">
              {text ? text : "-"}
            </p>
          ),
        },
        {
          title: "Pan Status",
          dataIndex: "pan_status",
          key: "pan_status",
          render: (text) => {
            return (
              <>
                <p className="w-[200px]">{text?text:"-"}</p>
              </>
            );
          },
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (text) => {
            return (
              <>
                <p className="font-medium w-[80px] overflow-y-scroll">
                  {text ? trimString(text) + " " + "Rs." : "-"}
                </p>
              </>
            );
          },
        },
        {
          title: "Remark",
          dataIndex: "remark",
          key: "remark",
          render: (text) => {
            return (
              <>
                <Tooltip title={text}>
                  <p className="font-medium w-[180px] overflow-y-scroll">
                    {text ? trimString(text) : "-"}
                  </p>
                </Tooltip>
              </>
            );
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text) => {
            return (
              <>
                {text === "1" ? (
                  <Tag color="green">Success</Tag>
                ) : text === "0" ? (
                  <Tag color="yellow">Pending</Tag>
                ) : (
                  <Tag color="red">Failed</Tag>
                )}
              </>
            );
          },
        },
        {
          title: "Action",
          dataIndex: "",
          key: "",
          render: (text, record) => {
            return (
              <>
                <div className="flex justify-around items-center gap-4">
                  <Tooltip title="ForIncompleteReq.">
                    <Button style={{color:"black"}}
                      disabled={record.status === "2" || record.status !== "0"}
                      onClick={() => handleIncomplete(record)}
                      className=" rounded-full text-white hover:text-black flex justify-center items-center bg-primary"
                    >Re-Generate
                     {/* <CiEdit /> */}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Check status">
                    <Button style={{color:"black"}}
                      disabled={record.status === "2" || record.status !== "1"}
                      onClick={() => handleCheckStatus(record)}
                      className=" rounded-full text-white flex justify-center items-center bg-primary"
                    >
                      Check status
                      {/* <CiCircleCheck /> */}
                    </Button>
                  </Tooltip>
                </div>
              </>
            );
          },
        },
      ];
      function handleCheckStatus(record) {
        const formdata = new FormData();
        formdata.append("transaction_id", record.transaction_id);
        checkPanStatus(formdata)
          .then((res) => {
            if (res.status) {
              dispatchtoast(res.message ?? res.data.message);
              getReport()
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      function handleIncomplete(record) {
        setLoading(true)
        const formdata = new FormData();
        formdata.append("transaction_id", record.transaction_id);
        formdata.append("api_transaction_id", record.api_transaction_id);
        nsdlRequest("generateIncompleteRequest", formdata)
          .then((res) => {
            if (res.status) {
              window.open(res.data.redirect_url, "_blank");
            }
          })
          .catch((err) => {
            console.log(err);
          }).finally(()=>setLoading(false))
      }
    const getReport = (
        search_keyword = searchKeyword,
        search_column = searchcolumn,
        from_date = fromDate,
        to_date = toDate
      ) => {
        setLoading(true);
        const payload = {
          per_page: pageSize,
          page_no: current,
          search_keyword: search_keyword,
          search_column: search_column,
          from_date: from_date,
          to_date: to_date,
        };
        const formdata = new FormData();
        Object.keys(payload).map((key) => formdata.append(key, payload[key]));
        getNsdlReport(formdata)
          .then((res) => {
            setDataSource(res.data);
            setTotalPages(res.total);
            setSearchOptions(
              res?.filter_column_name?.map((i, index) => {
                return {
                  label: i,
                  value: res.filter_column_key[index],
                };
              })
            );
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      };
      useEffect(() => {
        dispatch(setselectedTab("11.13"));
      }, []);
      useEffect(() => {
        getReport();
      }, [current, pageSize]);
  return (
    <>
     <div className="">
        <div className="prepaid mt-4  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
          <div className="flex justify-between items-center">
            <p className="font-bold text-[15px] mb-2">NSDL Report</p>
            <p
              onClick={() => {
                getReport("", "", "", "");
                setSearchKeyWord("");
                setSearchColumn(null);
                setFromDate("");
                setToDate("");
              }}
              className="font-medium text-xs cursor-pointer bg-bsred hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2"
            >
              Clear Filter
            </p>
          </div>
          <div className="selectionFields grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center ">
            <div className="w-full">
              <p className={`required text-textGray mt-2`}>
                Select Search Type
              </p>
              <Select
                placeholder="Select search type"
                value={searchcolumn}
                onChange={(val) => setSearchColumn(val)}
                className="w-full mt-2"
                options={searchOptions}
              />
            </div>
            <div className="w-full">
              <p className={`required text-textGray `}>Keyword</p>
              {searchcolumn === "nsdl.created_at" ? (
                <DatePicker
                  // value={dayjs("2023-09-07","YYYY-MM-DD")}
                  popupClassName="bottom-calendar"
                  allowClear
                  // value={searchKeyword}
                  className="w-full datepicker mt-4"
                  onChange={(val) =>
                    setSearchKeyWord(dayjs(val).format("YYYY-MM-DD"))
                  }
                  placement="bottomLeft"
                  placeholder="Search"
                />
              ) : (
                <CommonInput
                  placeholder="Seacrh"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyWord(e.target.value)}
                  className="outline-none text-xs border mt-4 w-full  border-gray-200 focus:border-primary   px-2 "
                />
              )}
            </div>
            <div className="w-full">
              <p className={`required text-textGray mt-2`}>
                From Date - To Date
              </p>
              <DatePicker.RangePicker
                allowClear
                onChange={(val) => {
                  setFromDate(val ? dayjs(val[0]).format("YYYY-MM-DD") : "");
                  setToDate(val ? dayjs(val[1]).format("YYYY-MM-DD") : "");
                }}
                className="w-full mt-2"
              />
            </div>{" "}
          </div>
          <div className="w-full flex justify-end items-center mt-3">
            <Button
              // disabled=
              onClick={() => getReport()}
              style={{ color: "white" }}
              className=" font-semibold flex justify-between gap-2 items-center hover:bg-primaryhover bg-primary border-none"
            >
              <AiOutlineSearch />
              <p>Search</p>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4">
          <Spin spinning={loading}>
            <Table
              className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
              columns={ReportColumn}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  className: "cursor-pointer text-[12px]",
                };
              }}
              dataSource={dataSource}
            />
            <div className="mt-2 mb-8 flex justify-end items-center">
            <CommonPagination totalPages={totalPages} current={current} pageSize={pageSize} setCurrent={setCurrent} setPageSize={setPageSize}/>
            </div>
          </Spin>
        </div>
      </div>
    </>
  )
}

export default NsdlReport