import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Table,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  dispatchtoast,
  filterOption,
} from "../../../Utils";
import { AiOutlineBank } from "react-icons/ai";
import { BsQrCode } from "react-icons/bs";
import {
  GetAdminBankDetail,
  SubmitFundRequest,
  getBanks,
} from "../../../ApiServices/Apifun";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { setselectedTab } from "../../../Store/B2bslice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonInput from "../../../Common/CommonInput";

const FundRequest = () => {
  const [form] = useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [fromBank, setFromBank] = useState([]);
  const [toBank, setToBank] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [bankUpiDetail, setBankUpiDetail] = useState({});
  const [image, setImage] = useState([]);
  const[disableToBank,setDisableTobank]=useState(false)
  const [imageBase64, setImageBase64] = useState(false);
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const columns = [
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      render: (text) => <p className="w-[200px]">{text}</p>,
    },
    {
      title: "Account Holder Name",
      dataIndex: "account_holder",
      key: "account_holder",
      render: (text) => <p className="w-[150px]">{text}</p>,
    },
    {
      title: "Account Number",
      dataIndex: "account_no",
      key: "account_no",
      render: (text) => <p className="w-[200px]">{text}</p>,
    },
    {
      title: "IFSC Code",
      dataIndex: "ifsc_code",
      key: "ifsc_code",
      render: (text) => <p className="w-[150px]">{text}</p>,
    },
  ];
  useEffect(() => {
    dispatch(setselectedTab("10"))
    getBanks()
      .then((res) =>
        setFromBank(
          res.banks.map((i, index) => {
            return {
              label: i.bank_name,
              value: i.id,
              key: i.id,
            };
          })
        )
      )
      .catch((err) => console.log(err));
    GetAdminBankDetail()
      .then((res) => {
        setBankData(res.banks_details);
        setToBank(
          res.banks_details.map((i, index) => {
            return {
              label: i.bank_name,
              value: i.id,
              key: i.id,
            };
          })
        );
        let data = { ...res.upi_details };
        if(data.qr_code.includes("localhost")){
          data.qr_code = data.qr_code.replace("localhost", "192.168.1.147");
        }
        setBankUpiDetail(data);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleFinish = (val) => {
    setBtnLoading(true)
    const formdata = new FormData();
    let data = { ...val };
    delete data["attachment"];
    data["cheque_or_dd_no"] = data["cheque_or_dd_no"]
      ? data["cheque_or_dd_no"]
      : "";
      if(data.payment_mode==="UPI/QR"){
        data["to_bank"]="0"
      }
    data["cheque_date"] = data["cheque_date"] ? dayjs(data["cheque_date"]).format("YYYY-MM-DD")  : "";
    formdata.append("attachment", imageBase64);
    Object.keys(data).map((key) => formdata.append(key, data[key]));
    SubmitFundRequest(formdata)
      .then((res) => {
        if (res.status) {
          form.resetFields()
          dispatchtoast(res.message);
          navigate("/Report/fund-request")
        }
      })
      .catch((err) => console.log(err)).finally(()=>setBtnLoading(false))
  };
  const handleCustomReq = async ({ file, onSuccess, onError }) => {
    try {
      const base64Data = await readFileAsBase64(file);
      const dataUrl = await readFileAsDataUrl(file);
      const newFile = {
        ...file,
        name: file.name,
        thumbUrl: dataUrl, 
        base64: base64Data, 
      };
      setImage([newFile]);
      setImageBase64(base64Data);
      onSuccess();
    } catch (error) {
      console.error(error);
      onError(error);
    }
  };
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  // Helper function to read a file as Base64 data
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result; // Remove the data URI prefix
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 place-items-start">
        <div className="prepaid animate-slide-in w-full  bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">Send Add Fund Request</p>
          <Form
          form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleFinish(val)}
          >
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 place-items-start">
              <Form.Item
                name={`from_bank`}
                label={"From Bank Name"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select one bank!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select from bank"
                  filterOption={filterOption}
                  className="w-full text-xs mt-2"
                  options={fromBank}
                />
              </Form.Item>
              <Form.Item
                name={`to_bank`}
                label={"To Bank Name"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select to bank!",
                  },
                ]}
              >
                <Select
                  showSearch
                  disabled={disableToBank}
                  placeholder="Select to bank"
                  filterOption={filterOption}
                  className="w-full text-xs mt-2"
                  options={toBank}
                />
              </Form.Item>
              <Form.Item
                name={`payment_mode`}
                label={"Payment Mode"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select payment mode!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select payment mode"
                  filterOption={filterOption}
                  onChange={(val)=>val==="UPI/QR" && setDisableTobank(true)}
                  className="w-full text-xs mt-2"
                  options={[
                    {
                      label: "Cash",
                      value: "Cash",
                    },
                    // {
                    //   label: "Cheque",
                    //   value: "Cheque",
                    // },
                    // {
                    //   label: "Demand Draft(DD)",
                    //   value: "Demand Draft(DD)",
                    // },
                    {
                      label: "IMPS",
                      value: "IMPS",
                    },
                    {
                      label: "NEFT",
                      value: "NEFT",
                    },
                    {
                      label: "UPI/QR",
                      value: "UPI/QR",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name={`attachment`}
                label={"Payment Proof"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Upload one payment proof!",
                  },
                ]}
              >
                <Upload
                  maxCount={1}
                  onRemove={() => {
                    setImage([])
                  }}
                  listType="picture"
                  accept=".jpg, .jpeg, .png"
                  fileList={image}
                  customRequest={handleCustomReq}
                >
                  <Button
                    className="bg-primary text-white border-none font-medium"
                    icon={<IoCloudUploadOutline />}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
              {/* <Form.Item
                name={`cheque_or_dd_no`}
                label={"Cheque Or DDNumber"}
                className="w-full"
                initialValue={""}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Cheque Or DDNumber"
                  name="cheque_or_dd_no"
                  className="outline-none text-xs border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
                />
              </Form.Item>{" "}
              <Form.Item
                name={`cheque_date`}
                label={"Cheque Date"}
                initialValue={""}
                className="w-full"
                labelCol={{ span: 24 }}
              >
                <DatePicker
                  // value={dayjs("2023-09-07","YYYY-MM-DD")}
                  popupClassName="bottom-calendar"
                  allowClear
                  // value={searchKeyword}
                  className="w-full datepicker mt-1 h-9"
                  // onChange={(val) =>
                  //   setSearchKeyWord(dayjs(val).format("YYYY-MM-DD"))
                  // }
                  placement="bottomLeft"
                  placeholder="Search"
                />
              </Form.Item>{" "} */}
              <Form.Item
                name={`amount`}
                label={"Fund Amount"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter fund amount!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                onInput={(e)=>{
                  const value = e.target.value.replace(/\D/g, '');
                  e.target.value = value;
                }}
                  placeholder="Enter Amount"
                  name="amount"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`transaction_id`}
                label={"RRN/UTR/Reference No/Transaction Id"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter RRN/UTR/Reference No/Transaction Id!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter RRN/UTR/Reference No/Transaction Id"
                  name="transaction_id"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`remark`}
                label={"Remarks"}
                className="w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Remark!",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter Remarks"
                  name="remark"
                  className="outline-none text-xs border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
                />
              </Form.Item>
            </div>
            <div className="flex justify-end items-center ">
              <Form.Item>
                <Button
                  loading={btnLoading}
                  className="w-full bg-primary border-none mt-4"
                  style={{ color: "white" }}
                  htmlType="submit"
                >
                  Send
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className=" animate-slide-in w-full col-span-2  bg-white shadow-normal  rounded-xl p-5">
          <div className="bg-white rounded-md p-4 mb-3">
            <div className="grid grid-cols-2 place-items-center text-[10px] xs:text-[13px] md:text-[14px] gap-0 md:gap-4 ">
              <div className="w-full cursor-pointer">
                <div
                  onClick={() => setActiveTab(1)}
                  className="flex justify-center  gap-2 items-center p-2"
                >
                  <div>
                    <AiOutlineBank className="text-primary" size={20} />
                  </div>
                  <p>Bank Details</p>
                </div>
                {activeTab === 1 ? (
                  <div className="border-t-2 p-2 animate-bounce-once border-primary"></div>
                ) : (
                  <div className="p-2"></div>
                )}
              </div>
              <div className="w-full cursor-pointer">
                <div
                  onClick={() => setActiveTab(2)}
                  className="flex justify-center gap-2 items-center p-2"
                >
                  <div>
                    <BsQrCode className="text-primary" size={20} />
                  </div>
                  <p>QR Code</p>
                </div>
                {activeTab === 2 ? (
                  <div className="border-t-2 p-2 animate-bounce-once border-primary"></div>
                ) : (
                  <div className="p-2"></div>
                )}
              </div>
            </div>
          </div>
          {activeTab === 1 ? (
            <Table
              className="history-table rounded-md max-h-[320px] overflow-x-auto text-white "
              dataSource={bankData}
              columns={columns}
              pagination={false}
            />
          ) : bankUpiDetail.upi_status === "1" &&
            bankUpiDetail.qr_code_status === "1" ? (
            <div className="flex flex-col justify-center items-center">
              <img src={bankUpiDetail.qr_code} className="w-full md:w-1/4 h-1/4" />
              <p className="font-bold text-center text-lg">
                UPI I'd: {bankUpiDetail.upi_id}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default FundRequest;
