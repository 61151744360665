import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  DeviceInfo,
  MantraIrisdeviceInfoAvdm,
  MantraPIDOPTS,
  MantradeviceInfoAvdm,
  MorphpoPIDOPTS,
  StartekDeviceInfo,
  ToastContainerMsg,
  captureUrl,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  getPosition,
  mantrairisPIDOPTS,
  parseXml,
  startekPIDOPTS,
} from "../../../../../Utils";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { xml2json } from "../../../../../Utils/xml2json";
import {
  AepsTwoFactorAuth,
  MemberProfile,
} from "../../../../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import {
  setProfile,
  setselectedTab,
} from "../../../../../Store/B2bslice";

const Aeps = () => {
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const location = useLocation();
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fields, setFields] = useState(null);

  useEffect(() => {
    dispatch(setselectedTab("0"));
    if (!location.state ) {
      navigate(-1);
    }else if(profiledetail.twofa_status){
      navigate(-1);
    }
  }, []);

  const capture = (x, port=11100) => {
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        fields === "morpho"
          ? captureUrl
          : fields === "startek"
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        fields === "morpho"
          ? MorphpoPIDOPTS
          : fields === "mantra"
          ? MantraPIDOPTS
          : fields === "startek"
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        console.log({ xmltojson });
        // const test1 = response.data;
        // const test2 = test1.search("errCode");
        // const test6 = getPosition(test1, '"', 2);
        // const test4 = test2 + 9;
        // const test5 = test1.slice(test4, test6);
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(
            `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
            true
          );
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const parsed = parseXml(response.data);
          const xmltojson = JSON.parse(
            xml2json(parsed).replace("undefined", "")
          );
          const {
            errCode,
            errInfo,
            fCount,
            fType,
            pCount,
            iCount,
            nmPoints,
            qScore,
          } = xmltojson.PidData.Resp;
          const { dpId, rdsId, rdsVer, mi, mc, dc } =
            xmltojson.PidData.DeviceInfo;
          const { ci } = xmltojson.PidData.Skey;
          const { Hmac } = xmltojson.PidData;
          const payload = {
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            type: location.state?.type,
            device_imei: Array.isArray(
              xmltojson.PidData.DeviceInfo.additional_info.Param
            )
              ? xmltojson.PidData.DeviceInfo.additional_info.Param[0].value
              : xmltojson.PidData.DeviceInfo.additional_info.Param.value,
            errCode: errCode ? errCode : 0,
            errInfo: errInfo ? errInfo : 0,
            fCount: fCount ? fCount : 0,
            fType:
              fields === "morpho" || fields === "mantra" || fields === "startek"
                ? fType || 2
                : 0,
            iType:
              fields === "morpho" || fields === "mantra" || fields === "startek"
                ? 0
                : 1,
            iCount: iCount ? iCount : 0,
            pCount: pCount ? pCount : 0,
            pType: 0,
            nmPoints: nmPoints ? nmPoints : 0,
            qScore: qScore ? qScore : 0,
            dpID: dpId ? dpId : 0,
            rdsID: rdsId ? rdsId : 0,
            rdsVer: rdsVer ? rdsVer : 0,
            dc: dc ? dc : 0,
            mi: mi ? mi : 0,
            mc: mc ? mc : 0,
            ci: ci ? ci : 0,
            sessionKey: xmltojson.PidData.Skey["#text"],
            hmac: Hmac ? Hmac : 0,
            PidDatatype: xmltojson.PidData.Data.type,
            Piddata: xmltojson.PidData.Data["#text"],
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          AepsTwoFactorAuth(formdata)
            .then((res) => {
              if (res.data.status === "SUCCESS") {
                let data = { ...profiledetail };
                data.twofa_status === 1;
                setProfile(data);
                dispatchtoast("Two factor completed successfully!");
                                navigate(
                  location.state?.from ? location.state?.from : "/dashboard",
                  { state: { allow: true } }
                );
                MemberProfile()
                  .then((res) => {
                    if (res.status) {
                      dispatch(setProfile(res.data.profile));
                    } else {
                      dispatchtoast("Unable to fetch profile detail", true);
                    }
                  })
                  .catch((err) => console.log(err, "errr"));
              } else {
                dispatchtoast(res.message, true);
              }
            })
            .catch((err) => console.log(err))
            .finally(() => setBtnLoading(false));
        }
      })
      .catch((error) => {
        console.log(error.response ? error.response.data : error.message);
      });
  };
  const handleSubmit = () => {
    if (fields === "morpho") {
      DeviceInfo(() => capture());
    } else if (fields === "mantra") {
      MantradeviceInfoAvdm((imei, port) => capture(imei, port));
    } else if (fields === "iris") {
      MantraIrisdeviceInfoAvdm(() => capture());
    } else if (fields === "startek") {
      StartekDeviceInfo(() => capture());
    }
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">
            Two Factor Authentication
          </p>
          <div className="selectionFields ">
            <div>
              <p className={`required text-textGray mt-2`}>Select Device</p>
              <Select
                value={fields}
                filterOption={filterOption}
                placeholder="Select a device"
                onChange={(val) => setFields(val)}
                className="w-full mt-2"
                options={deviceOptions}
              />
            </div>

            <Button
              disabled={!fields}
              loading={btnLoading}
              onClick={() => handleSubmit()}
              className="bg-primary mt-4 w-full border-none rounded-md h-9 text-center text-white flex justify-center items-center font-bold text-md cursor-pointer"
            >
              Scan & Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aeps;
