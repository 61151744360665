import React from 'react'
import { ThemeColor } from '../Theme/theme'

const Broadband = ({style}) => {
  return (
<svg fill={ThemeColor.bswhite} xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" version="1.1" 
viewBox="0 0 36.29 35.98"
>
 <defs>
  <style type="text/css">
  </style>
 </defs>
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_1768550324288">
   <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
     <path fill={"white"} className="fil0 str0" d="M2.93 22.05l30.43 0c1.28,-0 2.32,1.04 2.32,2.32l0 8.68c-0,1.28 -1.04,2.32 -2.32,2.32l-30.43 0c-1.28,0 -2.32,-1.04 -2.32,-2.32l-0 -8.68c0,-1.28 1.04,-2.32 2.32,-2.32z"/>
     <line stroke={ThemeColor.primary} className="fil0 str1" x1="6.74" y1="28.75" x2="16.55" y2= "28.75" />
     <line stroke={ThemeColor.primary} className="fil0 str1" x1="20.48" y1="26.71" x2="20.48" y2= "30.79" />
     <line stroke={ThemeColor.primary} className="fil0 str1" x1="24.26" y1="26.71" x2="24.26" y2= "30.79" />
     <line stroke={ThemeColor.primary} className="fil0 str1" x1="28.03" y1="26.71" x2="28.03" y2= "30.79" />
     <line stroke={ThemeColor.primary} className="fil0 str1" x1="31.8" y1="26.71" x2="31.8" y2= "30.79" />
     <line stroke={ThemeColor.bswhite} className="fil0 str1" x1="18.15" y1="9.13" x2="18.15" y2= "19.23" />
     <path className="fil0 str1" d="M15.04 6.46c0.83,-1.08 1.87,-1.74 2.99,-1.74 1.13,0 2.28,0.71 3.13,1.91"/>
     <path className="fil0 str1" d="M11.94 4.11c1.25,-2.16 3.56,-3.5 6.06,-3.5 2.5,0 4.81,1.33 6.06,3.5"/>
    </g>
   </g>
  </g>
 </g>
</svg>

  )
}

export default Broadband