import { Space } from "antd";

export const Planscolumns = (setAmount) => {
  return [
    {
      title: "Circle",
      dataIndex: "circle",
      key: "circle",
      render: (text) => <a className="font-medium">{text}</a>,
    },
    {
      title: "Plan",
      dataIndex: "plan_name",
      key: "plan_name",
      render: (text) => {
        return (
          <>
            <p className=" font-medium px-3 overflow-y-scroll">
              {text ? text.split(" ")[0] : "NAN"}
            </p>
          </>
        );
      },
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      render: (text) => <p style={{width:"100px"}} className=" font-medium">{text}</p>,
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
      render: (text) => <a className="font-medium">{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => {
        return (
          <>
            <p style={{width:"300px"}} className="font-medium   px-3 overflow-y-scroll">
              {text ? text : "NAN"}
            </p>
          </>
        );
      },
    },

    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (text) => {
        return (
          <p
            onClick={() => setAmount(text)}
            className="border font-medium  cursor-pointer border-primary hover:bg-primary hover:text-bswhite font-bold transition-all text-center px-2 text-primary rounded-md"
          >
            RS. {text}
          </p>
        );
      },
    },
  ];
};
