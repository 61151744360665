import { Drawer, Skeleton } from "antd";
import React from "react";
import { ThemeColor } from "../Theme/theme";

const NotificationDrawer = ({ open, setopen }) => {
  return (
    <Drawer
      title="Notification"
      width={500}
      headerStyle={{
        background: ThemeColor.primary,
        color: ThemeColor.bswhite,
      }}
      placement="right"
      onClose={() => setopen(false)}
      open={open}
    >
      {[1, 2, 3, 4, 5].map((i, index) => {
        return (
          <div  key={index}>
            <div
             
              className="flex my-3 justify-between items-start gap-2"
            >
              {/* <img
                    src={avatar}
                    className=" rounded-full  w-8 h-8"
                    width={"30px"}
                    height={"30px"}
                  /> */}
              <Skeleton.Avatar active={true} size={40} shape={"circle"} />

              <div>
                <p className="font-semibold">Recharge</p>
                <p className="text-bsgray600">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <p className="text-bsgray600 font-semibold">Monday</p>
            </div>
            <hr />
          </div>
        );
      })}
    </Drawer>
  );
};

export default NotificationDrawer;
