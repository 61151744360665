import React from "react";
import { Images } from "../Controller";
import { NavLink } from "react-router-dom";

const NotAvailable = () => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 h-full place-items-center">
        <div className=" flex justify-center items-center w-full">
          <img src={Images.manfind} className="h-[300px] md:h-[400px] w-[300px]" />
        </div>
        <div className=" w-9/12 md:w-full  text-start m-auto md:text-start">
          <h1 className="font-bold text-2xl md:text-5xl text-gray-600">Service InActive</h1>
          <p className="text-md text-gray-600">
            Sorry this service is not active!
          </p>
          <p className="text-xs text-gray-400">
            (Please contact your admin)...
          </p>
          <p className="mt-4 text-md text-gray-600">
            ...either way, you should propbably
          </p>
          <NavLink to="/dashboard">
            <p className="text-blue-800 font-semibold">
              go back to the homepage
            </p>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default NotAvailable;
