import React from "react";
import { ThemeColor } from "../Theme/theme";
export const Mobile_PostPaid = ({ style }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        version="1.1"
        viewBox="0 0 16.48 21.59"
      >
        <defs>
          <style type="text/css"></style>
        </defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <g id="_1767785155760">
            <path
            stroke={ThemeColor.bswhite}
              id="Path_328"
              className="fil0"
              d="M0.37 21.59l15.73 0c0,0 0,0 0,0 0.05,0 0.1,-0.01 0.14,-0.03 0.05,-0.02 0.09,-0.05 0.12,-0.08 0.07,-0.07 0.11,-0.16 0.11,-0.26l0 -20.84c-0,-0.1 -0.04,-0.19 -0.11,-0.26 -0.03,-0.03 -0.08,-0.06 -0.12,-0.08 -0.04,-0.02 -0.09,-0.03 -0.14,-0.03 -0,0 -0,0 -0,0l-11.64 0c-0.09,0 -0.18,0.03 -0.25,0.1l-4.1 3.84c-0.07,0.07 -0.11,0.16 -0.11,0.26 0,0 0,0 0,0l0 17.01c0,0 0,0 0,0 0,0.1 0.04,0.19 0.11,0.26 0.07,0.07 0.16,0.11 0.26,0.11l-0 -0zm0.37 -17.23l3.86 -3.64 11.14 -0 0 20.15 -15.01 0 0.02 -16.51zm0 0z"
              data-name="Group 176"
            />
            <path
              id="Path_330"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M13.72 11.54c0,-0 0,-0 0,-0 0,-0.1 -0.04,-0.19 -0.11,-0.26 -0.07,-0.07 -0.17,-0.11 -0.26,-0.11l-10.24 0c-0,-0 -0,-0 -0,-0 -0.21,0 -0.37,0.17 -0.37,0.37 0,0 0,0 0,0l0 7.32c0,0 0,0 0,0 0,0.1 0.04,0.19 0.11,0.26 0.07,0.07 0.16,0.11 0.26,0.11 0,0 0,0 0,0l10.25 0c0,-0 0,-0 0,-0 0.1,0 0.19,-0.04 0.26,-0.11 0.03,-0.03 0.06,-0.08 0.08,-0.12 0.02,-0.04 0.03,-0.09 0.03,-0.14 0,-0 0,-0 0,-0l0 -4.68 -0.02 -2.65zm-10.25 0.37l2.33 0 0 1.93 -2.33 0 0 -1.93zm6.48 6.59l-3.43 0 0 -1.92 3.43 -0 0 1.92zm0 -2.64l-3.79 0c-0,-0 -0,-0 -0,-0 -0.05,0 -0.1,0.01 -0.14,0.03 -0.14,0.06 -0.23,0.19 -0.23,0.34 0,0 0,0 0,0l0 2.29 -2.31 0 0 -3.97 6.48 0 0 1.31zm0 -2.03l-3.43 0 0 -1.92 3.43 0 0 1.92zm3.05 4.68l-2.32 0 0 -1.93 2.32 0 0 1.93zm0 -2.65l-2.32 0 0 -1.31 2.32 0 0 1.31zm0 -2.03l-2.32 0 0 -1.92 2.32 0 0 1.92zm0 0z"
              data-name="Path 330"
            />
            <path
              id="Path_331"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M3.08 6.68l0 0.72 -0.42 0 0 -2.04 0.8 0c0.01,-0 0.01,-0 0.02,-0 0.13,0 0.26,0.03 0.39,0.08 0.11,0.05 0.21,0.13 0.27,0.24 0.06,0.1 0.09,0.22 0.09,0.34 0,0 -0,0.01 -0,0.01 0,0.01 0,0.02 0,0.03 0,0.17 -0.08,0.34 -0.21,0.45 -0.15,0.11 -0.33,0.17 -0.52,0.17 -0.02,0 -0.03,-0 -0.05,-0l-0.37 -0zm0 -0.34l0.38 0c0.01,0 0.02,0 0.02,0 0.08,0 0.16,-0.03 0.23,-0.08 0.06,-0.05 0.09,-0.13 0.09,-0.21 0,-0.01 -0,-0.01 -0,-0.02 0,-0 0,-0.01 0,-0.01 0,-0.08 -0.03,-0.17 -0.09,-0.23 -0.06,-0.06 -0.14,-0.09 -0.23,-0.09 -0,0 -0.01,0 -0.01,0l-0.39 0 0 0.64zm0 0z"
              data-name="Group 127"
            />
            <path
              id="Path_332"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M4.43 6.63c0,-0.01 0,-0.01 0,-0.02 0,-0.13 0.03,-0.26 0.09,-0.38 0.05,-0.11 0.14,-0.21 0.25,-0.27 0.11,-0.06 0.24,-0.1 0.37,-0.1 0,0 0.01,0 0.01,0 0.01,-0 0.02,-0 0.03,-0 0.18,0 0.35,0.07 0.48,0.19 0.13,0.14 0.21,0.32 0.22,0.51l0 0.1c0,0.01 0,0.02 0,0.04 0,0.19 -0.07,0.38 -0.2,0.52 -0.13,0.13 -0.31,0.21 -0.49,0.21 -0.01,0 -0.02,-0 -0.03,-0 -0.01,0 -0.02,0 -0.03,0 -0.19,0 -0.37,-0.08 -0.49,-0.21 -0.13,-0.15 -0.2,-0.33 -0.2,-0.53 0,-0.02 0,-0.03 0,-0.05l-0 -0.02zm0.4 0.03c-0,0.01 -0,0.02 -0,0.04 0,0.1 0.03,0.21 0.08,0.29 0.05,0.07 0.13,0.12 0.22,0.12 0,0 0.01,-0 0.01,-0 0,0 0.01,0 0.01,0 0.09,0 0.17,-0.04 0.22,-0.11 0.06,-0.09 0.09,-0.2 0.09,-0.31 0,-0.02 -0,-0.03 -0,-0.05 0,-0.01 0,-0.02 0,-0.03 0,-0.1 -0.03,-0.21 -0.08,-0.29 -0.05,-0.07 -0.13,-0.12 -0.22,-0.12 -0,0 -0.01,0 -0.01,0 -0,-0 -0.01,-0 -0.01,-0 -0.09,0 -0.17,0.04 -0.22,0.12 -0.05,0.09 -0.08,0.2 -0.08,0.31 0,0.02 0,0.03 0,0.05l-0 0zm0 0z"
              data-name="Group 130"
            />
            <path
              id="Path_333"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M6.94 6.98c-0,-0.05 -0.03,-0.1 -0.07,-0.12 -0.07,-0.04 -0.15,-0.06 -0.24,-0.07 -0.36,-0.08 -0.54,-0.23 -0.54,-0.46 0,-0 0,-0 0,-0.01 0,-0.13 0.06,-0.25 0.17,-0.33 0.12,-0.09 0.26,-0.14 0.41,-0.14 0.01,0 0.02,0 0.03,0 0.01,-0 0.02,-0 0.04,-0 0.15,0 0.3,0.05 0.43,0.14 0.11,0.08 0.17,0.21 0.17,0.34 0,0 -0,0.01 -0,0.01l-0.41 0c0,-0 0,-0 0,-0.01 0,-0.05 -0.02,-0.1 -0.06,-0.14 -0.04,-0.04 -0.1,-0.06 -0.16,-0.06 -0.01,0 -0.01,0 -0.02,0 -0.01,-0 -0.01,-0 -0.02,-0 -0.05,0 -0.1,0.02 -0.14,0.05 -0.03,0.03 -0.05,0.07 -0.05,0.11 0,0 0,0 0,0 0,0 0,0 0,0 0,0.05 0.02,0.09 0.06,0.11 0.07,0.04 0.14,0.06 0.21,0.07 0.09,0.02 0.17,0.04 0.26,0.07 0.19,0.05 0.32,0.21 0.32,0.41 0,0 0,0 0,0 0,0 0,0 0,0.01 0,0.13 -0.07,0.26 -0.18,0.33 -0.13,0.08 -0.27,0.13 -0.42,0.13 -0.01,0 -0.02,-0 -0.04,-0 -0,-0 -0.01,0 -0.02,0 -0.11,0 -0.22,-0.02 -0.33,-0.07 -0.09,-0.04 -0.17,-0.1 -0.23,-0.19 -0.05,-0.08 -0.08,-0.17 -0.08,-0.26l0.38 0c0,0 0,0 0,0 0,0.06 0.03,0.12 0.08,0.17 0.05,0.04 0.12,0.06 0.18,0.06 0.01,0 0.01,-0 0.02,-0 0.01,0 0.01,0 0.02,0 0.05,0 0.11,-0.02 0.15,-0.04 0.04,-0.03 0.06,-0.07 0.06,-0.11 0,-0 0,-0 0,-0l-0 0zm0 0z"
              data-name="Group 133"
            />
            <path
              id="Path_334"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M8.08 5.51l0 0.37 0.26 0 0 0.3 -0.26 0 0 0.76c-0,0.01 -0,0.01 -0,0.02 0,0.04 0.01,0.07 0.03,0.11 0.03,0.02 0.07,0.04 0.1,0.04 0.01,0 0.01,-0 0.02,-0 0,0 0.01,0 0.01,0 0.04,0 0.07,-0 0.11,-0.01l-0 0.31c-0.08,0.02 -0.16,0.04 -0.25,0.04 -0.02,0 -0.03,0 -0.05,0 -0.21,0 -0.39,-0.17 -0.39,-0.39 0,-0.02 0,-0.03 0,-0.05l0 -0.82 -0.22 0 0 -0.3 0.22 0 0 -0.37 0.41 0zm0 0z"
              data-name="Group 136"
            />
            <path
              id="Path_335"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M9.94 6.65c0,0.01 0,0.03 0,0.04 0,0.19 -0.06,0.37 -0.16,0.52 -0.1,0.13 -0.25,0.21 -0.41,0.21 -0.01,0 -0.01,-0 -0.02,-0 -0.01,0 -0.01,0 -0.02,0 -0.14,0 -0.26,-0.06 -0.35,-0.16l0 0.71 -0.4 0 0 -2.1 0.38 0 0.02 0.15c0.09,-0.11 0.23,-0.18 0.37,-0.18 0,0 0.01,0 0.01,0 0.01,-0 0.02,-0 0.02,-0 0.16,0 0.32,0.08 0.41,0.21 0.1,0.15 0.16,0.33 0.16,0.52 0,0.02 -0,0.04 -0,0.05l-0 0.02zm-0.41 -0.03c0,-0.01 0,-0.02 0,-0.04 0,-0.1 -0.03,-0.2 -0.08,-0.29 -0.04,-0.07 -0.12,-0.12 -0.21,-0.12 -0,0 -0.01,0 -0.01,0 -0.01,-0 -0.02,-0 -0.03,-0 -0.1,0 -0.19,0.06 -0.24,0.15l0 0.62c0.05,0.09 0.14,0.15 0.24,0.15 0.01,0 0.02,-0 0.02,-0 0.19,0 0.29,-0.16 0.29,-0.48zm0 0z"
              data-name="Group 139"
            />
            <path
              id="Path_336"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M11.07 7.4c-0.02,-0.04 -0.03,-0.09 -0.04,-0.14 -0.09,0.1 -0.23,0.16 -0.37,0.16 -0.01,0 -0.01,-0 -0.02,-0 -0.01,0 -0.01,0 -0.02,0 -0.13,0 -0.25,-0.05 -0.35,-0.13 -0.09,-0.08 -0.15,-0.19 -0.15,-0.32 0,-0 0,-0.01 0,-0.01 -0,-0.01 -0,-0.01 -0,-0.02 0,-0.14 0.07,-0.27 0.18,-0.35 0.14,-0.09 0.3,-0.13 0.47,-0.13 0.02,0 0.03,0 0.05,0l0.19 0 0 -0.09c0,-0 0,-0.01 0,-0.01 0,-0.06 -0.02,-0.11 -0.05,-0.16 -0.04,-0.04 -0.09,-0.06 -0.15,-0.06 -0.01,0 -0.01,0 -0.02,0 -0,-0 -0.01,-0 -0.01,-0 -0.05,0 -0.11,0.02 -0.15,0.05 -0.04,0.03 -0.06,0.08 -0.06,0.13 0,0 0,0.01 0,0.01l-0.41 0c0,-0 0,-0 0,-0 0,-0.09 0.03,-0.17 0.08,-0.24 0.06,-0.08 0.14,-0.14 0.23,-0.18 0.1,-0.04 0.21,-0.06 0.32,-0.06 0,0 0.01,0 0.01,0 0.01,-0 0.02,-0 0.03,-0 0.15,0 0.29,0.05 0.41,0.14 0.11,0.09 0.17,0.23 0.17,0.37 0,0.01 -0,0.02 -0,0.03l0 0.66c-0,0.01 -0,0.02 -0,0.03 0,0.1 0.02,0.2 0.06,0.29l0 0.02 -0.41 0zm-0.33 -0.28c0,-0 0,-0 0,-0 0.06,0 0.11,-0.01 0.16,-0.04 0.05,-0.02 0.09,-0.06 0.11,-0.11l0 -0.26 -0.15 0c-0.2,0 -0.31,0.07 -0.33,0.21l0 0.02c0,0 0,0 0,0.01 0,0.05 0.02,0.09 0.05,0.12 0.04,0.03 0.09,0.05 0.14,0.05 0,0 0.01,-0 0.01,-0l-0 -0zm0 0z"
              data-name="Group 142"
            />
            <path
              id="Path_337"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M12.15 7.4l-0.41 0 0 -1.52 0.41 0 0 1.52zm-0.43 -1.91c0,-0 0,-0 0,-0.01 0,-0.05 0.02,-0.11 0.06,-0.14 0.04,-0.04 0.1,-0.06 0.15,-0.06 0,0 0.01,0 0.01,0 0,-0 0.01,-0 0.01,-0 0.06,0 0.11,0.02 0.15,0.06 0.04,0.04 0.06,0.09 0.06,0.14 0,0 0,0 0,0 0,0 0,0 0,0.01 0,0.05 -0.02,0.11 -0.06,0.14 -0.04,0.04 -0.1,0.06 -0.15,0.06 -0,0 -0.01,-0 -0.01,-0 -0,0 -0.01,0 -0.01,0 -0.06,0 -0.11,-0.02 -0.15,-0.06 -0.04,-0.04 -0.06,-0.09 -0.06,-0.14 0,-0 0,-0 0,-0.01l-0 0zm0 0z"
              data-name="Group 145"
            />
            <path
              id="Path_338"  stroke={ThemeColor.bswhite}
              className="fil0"
              d="M12.43 6.63c-0,-0.02 -0,-0.03 -0,-0.05 0,-0.18 0.06,-0.36 0.16,-0.52 0.1,-0.13 0.25,-0.21 0.42,-0.21 0.01,0 0.01,0 0.02,0 0,-0 0.01,-0 0.01,-0 0.14,0 0.27,0.06 0.36,0.17l0 -0.78 0.41 0 0 2.16 -0.37 0 -0.02 -0.16c-0.09,0.12 -0.23,0.19 -0.38,0.19 -0,0 -0.01,-0 -0.01,-0 -0,0 -0.01,0 -0.01,0 -0.16,0 -0.32,-0.08 -0.42,-0.21 -0.11,-0.16 -0.16,-0.34 -0.16,-0.53 0,-0.02 0,-0.04 0,-0.06l-0 0zm0.4 0.03c-0,0.01 -0,0.02 -0,0.04 0,0.1 0.03,0.2 0.08,0.29 0.04,0.07 0.12,0.11 0.21,0.11 0,0 0.01,-0 0.01,-0 0.01,0 0.01,0 0.02,0 0.11,0 0.2,-0.06 0.25,-0.16l0 -0.6c-0.04,-0.1 -0.14,-0.16 -0.24,-0.16 -0.01,0 -0.01,0 -0.02,0 -0.2,-0 -0.29,0.16 -0.29,0.47zm0 0z"
              data-name="Group 148"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
