import React from "react";
import { ThemeColor } from "../Theme/theme";
export const Electricity = ({ style }) => {
  return (
    <div>
      <svg
        strokeWidth={"3px"}
        stroke={ThemeColor.bswhite}
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        version="1.1"
        viewBox="0 0 29.72 39.97"
      >
        <defs>
          <style type="text/css"></style>
        </defs>
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <g id="_1768550230240">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <polygon
                  className="fil0"
                  points="11.57,16.06 14.56,16.06 13.51,19.94 18.07,14.75 15.08,14.75 16.12,10.87 "
                />
                <path
                  fill={ThemeColor.primary}
                  className="fil1 str0"
                  d="M7.72 27.13c-4.37,-2.54 -7.06,-7.22 -7.06,-12.27 0,-7.84 6.36,-14.2 14.2,-14.2 7.84,0 14.2,6.36 14.2,14.2 0,5.05 -2.68,9.72 -7.04,12.26l-0 -0 0 5.06c-0.01,3.94 -3.21,7.12 -7.14,7.12l-0 0c-3.94,-0 -7.14,-3.19 -7.14,-7.13l0 -5.04 -0.01 0z"
                />
                <line
                  className="fil1 str0"
                  x1="7.72"
                  y1="27.15"
                  x2="22.03"
                  y2="27.15"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
