import { Button, DatePicker, Dropdown, Radio, Select, Tooltip } from "antd";
import React, { Children, useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrFormSubtract, GrFormAdd } from "react-icons/gr";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { MdFlightTakeoff } from "react-icons/md";
import Tabbar from "../Tabbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Flights = () => {
  const [childrens, setChildrens] = useState(0);
  const [adults, setAdults] = useState(0);
  const [infant, setInfant] = useState(0);
  const [bookingType, setBookingType] = useState(1);
  const [chooseReturn, setChooseReturn] = useState(false);
  const [multicityList, setMultiCityList] = useState([1]);
  const memberServices = useSelector((state) => state.B2B.memberServices);
const navigate=useNavigate()
  const TravelClass = [
    { label: "General Class", value: "general" },
    { label: "Premium Economy", value: "premium" },
    { label: "First Class", value: "first" },
    { label: "Business Class", value: "business" },
  ];

  const handleSubtract = (type) => {
    if (type === "adult" && adults > 0) {
      setAdults(adults - 1);
    } else if (type === "children" && childrens > 0) {
      setChildrens(childrens - 1);
    } else if (type === "infant" && infant > 0) {
      setInfant(infant - 1);
    }
  };
  const handleAdd = (type) => {
    if (type === "adult") {
      setAdults(adults + 1);
    } else if (type === "children") {
      setChildrens(childrens + 1);
    } else {
      setInfant(infant + 1);
    }
  };
  useEffect(() => {
    if (bookingType === 1) {
      setChooseReturn(false);
    } else if (bookingType === 2) {
      setChooseReturn(true);
    } else {
      setChooseReturn(false);
    }
  }, [bookingType]);
  const handleRemove = (index) => {
    let newdata = [...multicityList];
    newdata.splice(index, 1);
    setMultiCityList(newdata);
  };
  useEffect(()=>{
    if (!memberServices.includes("14")) {
      navigate("/not-Available");
    } 
  },[])
  return (
    <>
      <Tabbar from={"flights"} />
      <div className=" p-4 bg-white rounded-xl">
        <div className="flex justify-start items-center gap-3">
          <MdFlightTakeoff className="text-primary" size={20} />
          <p className="font-semibold text-xl">Flight Booking</p>
        </div>
        <div className="grid grid-cols-2 place-items-start mt-3">
          <div className="radio-group ">
            <Radio.Group
              value={bookingType}
              onChange={(e) => setBookingType(e.target.value)}
              name="radiogroup"
              defaultValue={1}
            >
              <Radio value={1}>One Way</Radio>
              <Radio value={2}>Round Trip</Radio>
              <Radio value={3}>Multicity</Radio>
            </Radio.Group>
          </div>
        </div>
        {bookingType === 1 || bookingType === 2 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-6 mt-4">
            <div className="flex justify-around items-center font-semibold w-full gap-4">
              <div className="w-full">
                <Select
                  placeholder="From ✈"
                  className="w-full mt-2"
                  options={[]}
                />
              </div>
              <HiOutlineSwitchHorizontal
                className="cursor-pointer text-primary font-extralight mt-4"
                size={40}
              />
              <div className="w-full">
                <Select placeholder="To ✈" className="w-full mt-2" options={[]} />
              </div>
            </div>
            <div className="flex justify-around items-center font-semibold w-full gap-4">
              <div className="w-full ">
                <DatePicker
                  popupClassName="bottom-calendar"
                  className="w-full datepicker mt-2"
                  onChange={() => {}}
                  placement="bottomLeft"
                  placeholder="Depart"
                />
              </div>
              <div className="w-full cursor-pointer">
                {chooseReturn ? (
                  <DatePicker
                    dropdownClassName="bottom-calendar"
                    className="w-full datepicker mt-2"
                    onChange={() => {}}
                    placeholder="Return"
                  />
                ) : (
                  <div className="border border-bsgray400 h-8 mt-2  text-bsgray flex flex-wrap overflow-y-scroll justify-around items-center">
                    <p>Return</p>
                    <p
                      onClick={() => {
                        setChooseReturn(true);
                        setBookingType(2);
                      }}
                    >
                      Choose Date
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            {bookingType === 3 && (
              <div className="flex justify-end items-center">
                <p
                  onClick={() => setMultiCityList([...multicityList, 1])}
                  className="  text-center bg-primary hover:bg-primaryhover px-4 py-1 cursor-pointer text-white "
                >
                  Add City
                </p>
              </div>
            )}
            <div className="grid grid-cols-1 border border-primary rounded-md shadow-sm shadow-gray-200  p-2 max-h-[300px] min-h-[100px] overflow-y-scroll md:grid-cols-2 place-items-center gap-x-6 mt-3">
              {multicityList.map((i, index) => {
                return (
                  <>
                    <div className="flex justify-around items-center font-semibold w-full gap-x-4">
                      <div className="w-full">
                        <Select
                          placeholder="From"
                          className="w-full mt-7"
                          options={[]}
                        />
                      </div>
                      <HiOutlineSwitchHorizontal
                        className="cursor-pointer text-primary font-extralight mt-8"
                        size={40}
                      />
                      <div className="w-full">
                        <Select
                          placeholder="To"
                          className="w-full mt-7"
                          options={[]}
                        />
                      </div>
                    </div>
                    <div className=" font-semibold w-full gap-4">
                      <div className="relative w-full">
                        <DatePicker
                          popupClassName="bottom-calendar"
                          className="w-full datepicker mt-7"
                          onChange={() => {}}
                          placeholder="Depart"
                        />
                        {index !== 0 && (
                         <Tooltip
                          title="Remove "
                          placement="topRight"
                          color="gray"
                        >
                          <div
                            onClick={() => handleRemove(index)}
                            className="flex absolute top-0 bottom-8 right-0 justify-end items-center cursor-pointer"
                          >
                            <AiOutlineCloseCircle className="text-end text-primary" />
                          </div>
                        </Tooltip>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 place-items-center gap-4 mt-4">
          <div className="w-full grid grid-cols-2 gap-3 bg-primary col-span-1  p-2 mt-4">
            <div className=" flex justify-start gap-2 items-center w-full">
              <p className="text-black">Adults</p>
              <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                {adults}
              </p>
            </div>
            <div className="flex cursor-pointer gap-4 justify-around items-center">
              <p
                onClick={() => handleSubtract("adult")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormSubtract />
              </p>
              <p
                onClick={() => handleAdd("adult")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormAdd />
              </p>
            </div>
          </div>
          <div className="w-full grid grid-cols-2 gap-3 bg-primary col-span-1  p-2 mt-4">
            <div className=" flex justify-start gap-1 items-center w-full">
              <p className="text-black">Childrens</p>
              <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                {childrens}
              </p>
            </div>
            <div className="flex cursor-pointer gap-4 justify-around items-center">
              <p
                onClick={() => handleSubtract("children")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormSubtract />
              </p>
              <p
                onClick={() => handleAdd("children")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormAdd />
              </p>
            </div>
          </div>
          <div className="w-full bg-primary grid grid-cols-2 gap-3 col-span-1  p-2 mt-4">
            <div className=" flex justify-start gap-2 items-center w-full">
              <p className="text-black">Infant</p>
              <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                {infant}
              </p>
            </div>
            <div className="flex cursor-pointer gap-4 justify-around items-center">
              <p
                onClick={() => handleSubtract("infant")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormSubtract />
              </p>
              <p
                onClick={() => handleAdd("infant")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormAdd />
              </p>
            </div>
          </div>
          <div className="w-full col-span-1">
            <Select
              placeholder="Cabin Class"
              className="w-full mt-2"
              allowClear
              options={TravelClass}
            />
          </div>
          <div className="col-span-1 w-full">
            <Button className="w-full border-none outline-none bg-primary rounded-none hover:bg-primaryhover font-bold text-white mt-2">
              Search Flights
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flights;
