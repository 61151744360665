import { Button } from "antd";
import React from "react";
import { Images } from "../../../Controller";
import { MemberProfile } from "../../../ApiServices/Apifun";
import { setProfile } from "../../../Store/B2bslice";
import { useDispatch, useSelector } from "react-redux";
import { dispatchtoast } from "../../../Utils";
import { useNavigate } from "react-router-dom";

const KycPending = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const handleRefresh = () => {
    MemberProfile()
      .then((res) => {
        if (res.status) {
          dispatch(setProfile(res.data.profile));
          if (res.data.profile.digio_kyc === "1") {
            navigate("/dashboard");
          } 
          else {
            dispatchtoast("KYC not approved yet!", true);
          }
        } else {
          dispatchtoast("Unable to fetch profile detail", true);
        }
      })
      .catch((err) => console.log(err, "errr"));
  };

  return (
    <div className="flex justify-center h-full items-center">
      <div className=" h-full md:h-5/6 pt-8 md:pt-0 rounded-bl-lg rounded-br-lg md:rounded-br-xl md:rounded-tr-xl relative  w-full flex flex-col justify-center items-center">
        <div className=" w-1/2 m-auto  flex flex-col justify-center items-center">
          <img src={Images.pendingKYC} className="w-1/2" />

          <p className="font-semibold text-xs text-center m-4">
            KYC approval is in progress. Once admin will approve you can use
            services
          </p>
          <Button
            onClick={handleRefresh}
            className="w-full rounded-md border-none text-white font-semibold bg-primary hover:bg-primaryhover"
          >
            Refresh
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KycPending;
