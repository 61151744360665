import React from "react";
import { Images } from "../../../../../Controller";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";
const StatusScreen = () => {
  const navigate=useNavigate()
  return (
    <>
      <div className=" m-auto h-[400px]">
        <div className="flex w-full md:w-full  px-4 flex-col h-full justify-center items-center">
          <img src={Images.success} className="w-20 h-20 animate-bounce" />
          <p className="text-center font-medium">
            Merchant onboarded successfully!
          </p>
          <Button
            onClick={()=>navigate("/banking/aeps-ekyc",{state:{allow:true}})}
            className="bg-primary p-3 flex justify-center items-center outline-none border-none  text-white  rounded-full mt-4"
          >
            Do E-KYC{" "}
          </Button>
        </div>
        
      </div>
    </>
  );
};

export default StatusScreen;
