import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Select } from "antd";
import useOtpHook from "../../../Hooks/useOtpHook";
import {
  addBenificiary,
  getDmtBanks,
  verifyBankAccount,
  verifyBenificiary,
} from "../../../ApiServices/Apifun";
import {
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  ifscPattern,
} from "../../../Utils";
import CommonInput from "../../../Common/CommonInput";

const AddBeneFiciaryModal = ({ isOpen, setIsOpen, handleSearch, mobile }) => {
  const formref = useRef();
  const [form] = Form.useForm();
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [banks, setBanks] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [selectLoading, setselectLoading] = useState(false);
  const handleOk = () => {
    setIsOpen(false);
  };
  const { handleChange, handleKeyUp, pin, inputRefs } = useOtpHook(6);
  const handleCancel = () => {
    setIsOpen(false);
  };
  const getAllBanks = () => {
    setselectLoading(true);
    getDmtBanks()
      .then((res) => {
        if (res.status) {
          setBanks(
            res.data.banks.map((i, index) => {
              return {
                label: i.bank_name,
                value: i.bank_id,
                key: i?.ifsc_code?i.ifsc_code:"",
              };
            })
          );
          setselectLoading(false);
        } else {
          setBanks([]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setselectLoading(false));
  };
  useEffect(() => {
    getAllBanks();
  }, []);

  const handleAdd = (val) => {
    let data={...val}
    data.bank_id=val.bank_id.value
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("remitter_mobile", mobile);
    Object.entries(data).forEach(([key, value]) => formdata.append(key, value));
    addBenificiary(formdata)
      .then((res) => {
        if (res.status) {
          setShowOtpScreen(true);
          setResponse(res.data);
        } else {
          setIsOpen(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleVerify = () => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("otp", pin.join(""));
    formdata.append("otp_refrence_id", response.otprefrenceid);
    formdata.append("mobile", mobile);
    verifyBenificiary(formdata)
      .then((res) => {
        if (res.status) {
          setIsOpen(false);
          handleSearch();
          dispatchtoast("Remitter added successfully!");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleVerifyBank = () => {
    setVerifyBtnLoading(true);
    const fieldValues = form.getFieldsValue();
    const formdata = new FormData();
    formdata.append("benificiary_account", fieldValues.account_number);
    formdata.append("benificiary_ifsc", fieldValues.ifsc);
    formdata.append("benificiary_name", fieldValues.first_name);
    formdata.append("bank_id",  fieldValues.bank_id?.value?fieldValues?.bank_id?.value:fieldValues?.bank_id);
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    verifyBankAccount(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          formref.current.setFieldsValue({
            first_name: res.data.beneficiary_name,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setVerifyBtnLoading(false));
  };
  const handlefillIFSC=(val)=>{
    formref.current.setFieldsValue({
      ifsc:val.key
    })
  }
  return (
    <>
      <Modal
        title={`${showOtpScreen ? "Verify OTP" : "Add Beneficiary"}`}
        footer={null}
        className="shadow-normal flex justify-normal items-center"
        width={300}
        maskClosable={false}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {!showOtpScreen ? (
          <Form
            name="basic"
            form={form}
            ref={formref}
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleAdd(val)}
          >
            <Form.Item
              name={`account_number`}
              label={"Account Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Fill Account Number!",
                },
              ]}
            >
              <CommonInput
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                placeholder="Enter Account Number"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`bank_id`}
              label={"Select Bank"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select any one bank!",
                },
              ]}
            >
              <Select
                placeholder="Select Bank"
                loading={selectLoading}
                filterOption={filterOption}
                showSearch={true}
                labelInValue
                onChange={(val)=>handlefillIFSC(val)}
                className="w-full"
                options={banks}
              />
            </Form.Item>{" "}
            <div className="grid grid-cols-3 place-items-center gap-2 m-2">
              <Form.Item
                name={`ifsc`}
                label={"IFSC"}
                className="col-span-2"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter IFSC Code!",
                  },
                  {
                    pattern: ifscPattern,
                    message: "Invalid ifsc number format!",
                  },
                ]}
              >
                <CommonInput
                  onInput={(e) => {
                    const value = e.target.value.toUpperCase();
                    e.target.value = value;
                  }}
                  placeholder="Enter IFSC Code"
                />
              </Form.Item>{" "}
              <Button
                loading={verifyBtnLoading}
                onClick={() => handleVerifyBank()}
                className="bg-primary mt-4 p-3 w-full text-xs flex justify-center items-center outline-none border-none  text-white  rounded-md"
              >
                Verify Bank
              </Button>
            </div>
            <Form.Item
              name={`first_name`}
              label={"Account Holder Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter account holder name!",
                },
              ]}
            >
              <CommonInput placeholder="Enter account holder name" />
            </Form.Item>{" "}
            <Form.Item
              className="w-full"
              //   wrapperCol={{
              //     offset: 8,
              //     span: 16,
              //   }}
            >
              <Button
                htmlType="submit"
                loading={btnLoading}
                className="bg-primary p-3 w-full flex justify-center items-center outline-none border-none  text-white  rounded-md"
              >
                Add{" "}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div className="  rounded-3xl shadow-normal bg-white p-5">
            <div className="header flex flex-col justify-center items-center">
              <p className="text-[15px] mt-3 text-gray-500">Enter OTP</p>
            </div>
            <div className=" mt-5 flex justify-center items-center">
              {[1, 2, 3, 4, 5, 6].map((i, index) => {
                return (
                  <input
                    key={index}
                    ref={inputRefs[index]}
                    type="text"
                    maxLength={1}
                    className="w-[25px] otpinput xs:w-[35px] sm:w-[35px] md:w-[40px] text-bsblackrgb bg-gray-100 h-8  mx-1 border-none focus:outline-none focus:border-none text-center"
                    value={pin[index]}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyUp={(e) => handleKeyUp(index, e)}
                  />
                );
              })}
            </div>
            <Button
              loading={btnLoading}
              onClick={() => pin.length === 6 && handleVerify()}
              className={`SignInBtn w-full ${
                pin.length === 6 ? "cursor-pointer" : "cursor-not-allowed"
              }  mt-4 ${
                pin.length === 6 ? "bg-primary" : "bg-bsgray"
              }  flex justify-center border-none items-center h-12 mt-10 rounded-full text-white text-[12px] font-bold`}
            >
              <p>Verify OTP</p>
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddBeneFiciaryModal;
