import { Button, Form, Select } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPersonalDetail } from "../../../../../Store/B2bslice";
import { aadharPattern, emailPattern, filterOption, panPattern, pincodePattern } from "../../../../../Utils";
import CommonInput from "../../../../../Common/CommonInput";

const PersonalDetail = ({ current, states, steps, setCurrent }) => {
  const formref = useRef();
  const getPersonalDetail = useSelector((state) => state.B2B.personalDetail);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [state, setStates] = useState([]);
  const handleFinish = (val) => {
    dispatch(setPersonalDetail(val));
    setCurrent(1);
  };
  useEffect(() => {
    if (getPersonalDetail) {
      formref.current.setFieldsValue({
        user_first_name: getPersonalDetail.user_first_name,
        user_middle_name: getPersonalDetail.user_middle_name,
        user_last_name: getPersonalDetail.user_last_name,
        email_id: getPersonalDetail.email_id,
        user_aadhaar_number:getPersonalDetail.user_aadhaar_number,
        user_address1: getPersonalDetail.user_address1,
        user_pan:getPersonalDetail.user_pan,
        user_address2: getPersonalDetail.user_address2,
        user_state: getPersonalDetail.user_state,
        user_city: getPersonalDetail.user_city,
        user_district: getPersonalDetail.user_district,
        user_pin_code: getPersonalDetail.user_pin_code,
      });
    } else {
      formref.current.setFieldsValue({
        user_first_name: "",
        user_middle_name: "",
        user_last_name: "",
        email_id: "",
        user_pan:"",
        user_aadhaar_number:"",
        user_address1: "",
        user_address2: "",
        user_state: "",
        user_city: "",
        user_district: "",
        user_pin_code: "",
      });
    }
  }, []);

  return (
    <>
      <div className="p-4 ">
        <Form
          name="basic"
          form={form}
          ref={formref}
          initialValues={{
            remember: true,
          }}
          onFinishFailed={(err) => console.log(err)}
          onFinish={(val) => handleFinish(val)}
        >
          <p className="text-md text-primary font-semibold">Personal Details</p>
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-x-5">
            <Form.Item
              name={`user_first_name`}
              label={"First Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter First Name!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your First Name"
                name="firstname"
              />
            </Form.Item>
            <Form.Item
              name={`user_middle_name`}
              label={"Middle Name"}
              labelCol={{ span: 24 }}
            >
              <CommonInput
                placeholder="Enter Your Middle Name"
                name="middleName"
              />
            </Form.Item>
            <Form.Item
              name={`user_last_name`}
              label={"Last Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Last Name!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Last Name"
                name="lastName"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`email_id`}
              label={"Email Id"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Email Id!",
                },
                {
                  pattern: emailPattern,
                  message: "Invalid email format!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Email I'd"
                name="emailId"
              />
            </Form.Item>
            <Form.Item
              name={`user_aadhaar_number`}
              label={"Aadhar Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Aadhar Number!",
                },
                {
                  pattern: aadharPattern,
                  message: 'Invalid Aadhaar card number format!',
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Aadhar Number"
                name="emailId"
              />
            </Form.Item>
            <Form.Item
          name={`user_pan`}
          label={"Pan Card"}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Enter Pan!",
            }, {
              pattern: panPattern,
              message: 'Invalid PAN format!',
            },{
              whitespace: true,
              message: "Field cannot contain only blank spaces",
            },
          ]}
        >
          <CommonInput
            placeholder="Enter Pan"
            name="user_pan"
          />
        </Form.Item>
            <Form.Item
              name={`user_address1`}
              label={"Address 1"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter your address!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Address 1"
                name="merchantAddress1"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`user_address2`}
              label={"Address 2"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter your address!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Address 2"
                name="merchantAddress2"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`user_state`}
              label={"State"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select any one state!",
                },
              ]}
            >
              <Select
                placeholder="Select State"
                showSearch
                filterOption={filterOption}
                className="w-full "
                options={states}
              />
            </Form.Item>{" "}
            <Form.Item
              name={`user_city`}
              label={"City"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter City!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your City Name"
                name="merchantCityName"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`user_district`}
              label={"District"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter District!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your District Name"
                name="merchantDistrictName"
              />
            </Form.Item>
            <Form.Item
              name={`user_pin_code`}
              label={"PinCode"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter PinCode!",
                },
                {
                  pattern: pincodePattern,
                  message: "Invalid PIN code format!",
                },{
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Pin Code"
                name="merchantPinCode"
              />
            </Form.Item>
          </div>

          <div className="flex justify-end items-center m-2">
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              {current < steps.length - 1 && (
                <Button
                  htmlType="submit"
                  className="bg-primary outline-none border-none text-white"
                >
                  Next
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PersonalDetail;
