import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerMsg, removeLocalStorageItem } from "./Utils";
import { Spin } from "antd";
import { useEffect } from "react";
import useDigiLoader from "./Hooks/useDigiLoader";
function App() {
  const B2BRouting = [...PublicRoutes(), ...PrivateAuth()];
  const B2BRouteConfig = () => useRoutes(B2BRouting);
  const loader = useDigiLoader();
  useEffect(() => {
    if (loader === "2") {
      removeLocalStorageItem("startLoader");
    }
    return () => removeLocalStorageItem("startLoader");
  }, [loader]);
  return (
    <>
      <HashRouter>
        <Spin spinning={loader === "1"}>
          <B2BRouteConfig />
        </Spin>
      </HashRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
