import { Table } from "antd";
import React, { useEffect } from "react";
import { Planscolumns } from "./PlansColumn";

const AllPlans = (props) => {
  const { group_name,data,setAmount } = props;

  useEffect(() => {
  }, [group_name,data]);
  return (
    <>
      <Table
        className="custom-table h-[320px] overflow-x-auto text-white rounded-none"
        columns={Planscolumns(setAmount)}
        pagination={false}
        dataSource={data}
      />
    </>
  );
};

export default AllPlans;
