import React, { useState,useEffect } from "react";
import { Images } from "../../Controller";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Spin } from "antd";
import { ReactComponent as AepsloginIcon } from "../../Assets/logoicons/AEPS.svg";
import { ReactComponent as BbpsloginIcon } from "../../Assets/logoicons/BBPS.svg";
import { ReactComponent as KycloginIcon } from "../../Assets/logoicons/KYC.svg";
import { ReactComponent as PayoutloginIcon } from "../../Assets/logoicons/Payout.svg";
import { ReactComponent as RechargeloginIcon } from "../../Assets/logoicons/Recharge.svg";
import { ReactComponent as TravelloginIcon } from "../../Assets/logoicons/Travel.svg";
import { ReactComponent as WalletloginIcon } from "../../Assets/logoicons/Wallet.svg";

import { PortalName, dispatchtoast } from "../../Utils";
import { FaUserTie } from "react-icons/fa";
import CommonInput from "../../Common/CommonInput";
import { ResetUserPin } from "../../ApiServices/Apifun";

const ResetPin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [BtnLoading, setBtnLoading] = useState(false);

  const [form] = Form.useForm();

  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("new_pin")) {
      return Promise.reject("The pin do not match");
    }
    return Promise.resolve();
  };

  const handlesubmit = async (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("otp_uid", location.state?.otp_uid);
    Object.keys(val).map((key) => formdata.append(key, val[key]));
    ResetUserPin(formdata)
      .then((res) => {
        if (res.status) {
          navigate("/");
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div className="flex justify-center bg-blue-100 h-screen items-center">
        <div className="grid grid-cols-1 md:grid-cols-2  w-full md:w-9/12 h-screen md:h-full  place-items-center">
          <div className="h-full md:h-5/6 shadow-lg shadow-gray-500   bg-primary rounded-tl-lg rounded-tr-lg md:rounded-tl-lg md:rounded-bl-lg  w-full px-10 flex flex-col justify-center items-center">
            <p className="mt-10">
              <FaUserTie color="white" size={60} />
            </p>
            <p className="text-white mt-10 font-[100] text-4xl">Welcome to</p>
            <p className="mt-6 text-3xl font-semibold text-white">
              {PortalName}
            </p>
            <p className="text-xs font-normal text-white mt-4">
              Bunch of all business solution logic
            </p>
            <img
              src={Images.loginillustration2}
              className="w-[300px] h-[200px] mt-4 m-4"
            />
          </div>
          <div className=" h-full md:h-5/6 shadow-lg shadow-gray-500 pt-8 md:pt-0 rounded-bl-lg rounded-br-lg md:rounded-br-xl md:rounded-tr-xl relative  w-full flex flex-col bg-white justify-center items-center">
            <img
              src={Images.WebsiteLogo}
              className="w-1/2"
              style={{ filter: "drop-shadow(0px 1px 2px #cfc5c5)" }}
            />
            <p
              className="text-start mt-4 font-semibold text-xl"
              style={{ fontFamily: "system-ui" }}
            >
              Login to B2B
            </p>
            <div className="py-10 w-2/3">
              <Form
                form={form}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinishFailed={(err) => console.log(err)}
                onFinish={(val) => handlesubmit(val)}
              >
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 place-items-center">
                  <Form.Item
                    name={`otp`}
                    label={"OTP"}
                    className="w-full"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter OTP!",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter a valid number",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput
                      maxlength={6}
                      placeholder="Enter OTP"
                      name="otp"
                    />
                  </Form.Item>
                  <Form.Item
                    name={`password`}
                    label={"Password"}
                    className="w-full"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter Password!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <CommonInput placeholder="Enter Password" name="password" />
                  </Form.Item>
                  <Form.Item
                    name={`new_pin`}
                    label={"New Pin"}
                    className="w-full"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter New Pin!",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <Input.Password
                      maxLength={4}
                      placeholder="Enter New Pin"
                      name="new_pin"
                      className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name={`confirm_new_pin`}
                    label={"Confirm New Pin"}
                    labelCol={{ span: 24 }}
                    className="w-full"
                    dependencies={["new_ppin"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm new pin",
                      },
                      { validator: validatePassword },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <Input.Password
                      maxLength={4}
                      placeholder="Confirm new pin"
                      name="confirm_new_pin"
                      className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
                    />
                  </Form.Item>{" "}
                  <Form.Item className="w-full">
                    <Button
                      htmlType="submit"
                      loading={BtnLoading}
                      className={`SignInBtn w-full  mt-4 mb-4
                   bg-primary
               cursor-pointer
               flex justify-center border-none items-center h-12 rounded-full text-white text-[12px] font-bold`}
                    >
                      <p>Submit</p>
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
            <div className="absolute bottom-4  flex justify-center flex-wrap gap-4  w-full items-center  font-semibold text-3xl font-serif">
              <AepsloginIcon className="cursor-pointer" />
              <BbpsloginIcon className="cursor-pointer" />
              <KycloginIcon className="cursor-pointer" />
              <TravelloginIcon className="cursor-pointer" />
              <RechargeloginIcon className="cursor-pointer" />
              <PayoutloginIcon className="cursor-pointer" />
              <WalletloginIcon className="cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPin;
