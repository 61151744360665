import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import CommonInput from "../../../../../Common/CommonInput";
import { YesBankAepsValidateOtp } from "../../../../../ApiServices/Apifun";
import { dispatchtoast } from "../../../../../Utils";
import { useLocation, useNavigate } from "react-router-dom";

const YesValidateOtpModal = ({ isOpen, setIsOpen }) => {
  const [btnLoading,setBtnLoading]=useState(false)
  const navigate=useNavigate()
  const location=useLocation()
  console.log({location})
  const handleSend=(val)=>{
    setBtnLoading(true)
    const formdata=new FormData()
    formdata.append("otp",val.otp)
YesBankAepsValidateOtp(formdata).then(res=>{
  if(res.status){
    dispatchtoast(res.data.statusMessage)
    setIsOpen(false)
    navigate("/banking/aepsyes-ekyc",{state:{from:location.state?.from}})
  }
}).catch(err=>console.log(err)).finally(()=>setBtnLoading(false))
  }
  return (
    <>
      <Modal
        footer={null}
        maskClosable={false}
        title="Validate Otp"
        open={isOpen}
        width={400}
        onCancel={() => setIsOpen(false)}
      >
        <div>
          <Form 
          onFinish={(val)=>handleSend(val)}
          onFinishFailed={(err)=>console.log(err)}
          >
            <Form.Item name={"otp"} rules={[
              {
                required:true,
                message:"Please Enter Otp!"
              }
            ]}>
              <CommonInput placeholder={"Enter Otp"}/>
            </Form.Item>
            <Form.Item>
              <Button loading={btnLoading} htmlType="submit" className="bg-primary p-3 w-full flex justify-center items-center outline-none border-none  text-white  rounded-full mt-4">
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default YesValidateOtpModal;
