import React from "react";
export const Lpg_Gas = ({ style }) => {
  return (
    <div >
    <svg
         height="20px"
         width="20px"
      viewBox="0 0 24 35"
      fill={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7518 34.3006H7.79841C5.73027 34.3006 3.74683 33.479 2.28444 32.0166C0.822041 30.5542 0.000488281 28.5708 0.000488281 26.5027V13.0053C0.000488281 10.9372 0.822041 8.95374 2.28444 7.49135C3.74683 6.02896 5.73027 5.2074 7.79841 5.2074H15.7518C17.8199 5.2074 19.8033 6.02896 21.2657 7.49135C22.7281 8.95374 23.5497 10.9372 23.5497 13.0053V26.5027C23.5497 28.5708 22.7281 30.5542 21.2657 32.0166C19.8033 33.479 17.8199 34.3006 15.7518 34.3006ZM7.79841 7.27993C6.28204 7.28675 4.82972 7.89214 3.75747 8.96438C2.68523 10.0366 2.07984 11.4889 2.07303 13.0053V26.5027C2.07303 28.0211 2.67623 29.4774 3.74994 30.5511C4.82366 31.6248 6.27994 32.228 7.79841 32.228H15.7518C17.2703 32.228 18.7265 31.6248 19.8002 30.5511C20.8739 29.4774 21.4772 28.0211 21.4772 26.5027V13.0053C21.4704 11.4889 20.8649 10.0366 19.7927 8.96438C18.7205 7.89214 17.2682 7.28675 15.7518 7.27993H7.79841Z"
        fill="#white"
      />
      <path
        d="M8.1611 7.27977C8.02405 7.28333 7.8877 7.25896 7.76037 7.20815C7.63303 7.15733 7.51738 7.08113 7.42044 6.98418C7.3235 6.88724 7.2473 6.77158 7.19648 6.64424C7.14566 6.51691 7.12129 6.38056 7.12485 6.2435V1.03627C7.12485 0.761431 7.23404 0.497853 7.42838 0.303515C7.62271 0.109178 7.88627 0 8.1611 0C8.43593 0 8.69949 0.109178 8.89383 0.303515C9.08816 0.497853 9.19735 0.761431 9.19735 1.03627V6.2435C9.20091 6.38056 9.17654 6.51691 9.12572 6.64424C9.07491 6.77158 8.99871 6.88724 8.90176 6.98418C8.80482 7.08113 8.68917 7.15733 8.56184 7.20815C8.43451 7.25896 8.29816 7.28333 8.1611 7.27977Z"
        fill="#white"
      />
      <path
        d="M15.2851 7.27977C15.1481 7.28333 15.0117 7.25896 14.8844 7.20815C14.7571 7.15733 14.6414 7.08113 14.5445 6.98418C14.4475 6.88724 14.3713 6.77158 14.3205 6.64424C14.2697 6.51691 14.2453 6.38056 14.2489 6.2435V1.03627C14.2489 0.761431 14.3581 0.497853 14.5524 0.303515C14.7467 0.109178 15.0103 0 15.2851 0C15.56 0 15.8235 0.109178 16.0179 0.303515C16.2122 0.497853 16.3214 0.761431 16.3214 1.03627V6.2435C16.3249 6.38056 16.3006 6.51691 16.2497 6.64424C16.1989 6.77158 16.1227 6.88724 16.0258 6.98418C15.9288 7.08113 15.8132 7.15733 15.6859 7.20815C15.5585 7.25896 15.4222 7.28333 15.2851 7.27977Z"
        fill="#white"
      />
      <path
        d="M17.4875 2.07253H6.03675C5.76192 2.07253 5.49832 1.96335 5.30399 1.76902C5.10965 1.57468 5.00049 1.3111 5.00049 1.03627C5.00049 0.761431 5.10965 0.497853 5.30399 0.303515C5.49832 0.109178 5.76192 0 6.03675 0H17.4875C17.7623 0 18.0259 0.109178 18.2202 0.303515C18.4146 0.497853 18.5237 0.761431 18.5237 1.03627C18.5237 1.3111 18.4146 1.57468 18.2202 1.76902C18.0259 1.96335 17.7623 2.07253 17.4875 2.07253Z"
        fill="#white"
      />
      <path
        d="M16.684 35H6.83949C6.56465 35 6.30106 34.8908 6.10672 34.6965C5.91238 34.5022 5.80322 34.2386 5.80322 33.9638C5.80322 33.6889 5.91238 33.4253 6.10672 33.231C6.30106 33.0367 6.56465 32.9275 6.83949 32.9275H16.684C16.9589 32.9275 17.2224 33.0367 17.4168 33.231C17.6111 33.4253 17.7203 33.6889 17.7203 33.9638C17.7203 34.2386 17.6111 34.5022 17.4168 34.6965C17.2224 34.8908 16.9589 35 16.684 35Z"
        fill="#white"
      />
      <path
        d="M22.5133 20.8549H1.03661C0.761774 20.8549 0.49821 20.7457 0.303872 20.5513C0.109534 20.357 0.000340961 20.0934 0.000340961 19.8186C-0.00322074 19.6815 0.0211536 19.5452 0.0719703 19.4179C0.122787 19.2905 0.198989 19.1749 0.295934 19.0779C0.39288 18.981 0.508529 18.9048 0.635865 18.8539C0.763202 18.8031 0.899555 18.7788 1.03661 18.7823H22.5133C22.6503 18.7788 22.7867 18.8031 22.914 18.8539C23.0414 18.9048 23.157 18.981 23.254 19.0779C23.3509 19.1749 23.4271 19.2905 23.4779 19.4179C23.5287 19.5452 23.5531 19.6815 23.5496 19.8186C23.5496 20.0934 23.4404 20.357 23.246 20.5513C23.0517 20.7457 22.7881 20.8549 22.5133 20.8549Z"
        fill="#white"
      />
    </svg>
  </div>
  );
};
