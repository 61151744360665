import ForgotPassword from "../Views/Auth/ForgotPassword";
import Login from "../Views/Auth/Login";
import Otp from "../Views/Auth/Otp";
import Register from "../Views/Auth/Register";
import ResetPin from "../Views/Auth/ResetPin";
import Setpassword from "../Views/Auth/Setpassword";
import PublicAuth from "./PublicAuth";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/setPassword",
          element: <Setpassword />,
        },
        {
          path: "/otpScreen",
          element: <Otp />,
        },
        {
          path: "/forgotpassword",
          element: <ForgotPassword />,
        },
        {
          path: "/resetpin",
          element: <ResetPin />,
        },
        {
          path: "/register",
          element: <Register />,
        },
      ],
    },
  ];
};
