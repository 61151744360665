import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  MantraPIDOPTS,
  MorphpoPIDOPTS,
  ToastContainerMsg,
  aadharPattern,
  captureUrl,
  convertXmlToJson,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  handleSubmitForAeps,
  mantrairisPIDOPTS,
  mobilePattern,
  parseXml,
  startekPIDOPTS,
} from "../../../../../Utils";
import {
  AepsMinistatement,
  getAllAepsMasters,
} from "../../../../../ApiServices/Apifun";
import { xml2json } from "../../../../../Utils/xml2json";
import axios from "axios";
import ReportDrawer from "../../../../../Common/ReportDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setselectedTab } from "../../../../../Store/B2bslice";
import CommonInput from "../../../../../Common/CommonInput";

const Ministatement = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [userBanks, setUserBanks] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [response, setResponse] = useState([]);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setselectedTab("3.4"));
    const handleAepsMasters = () => {
      getAllAepsMasters()
        .then((res) => {
          setUserBanks(
            res.data.user_banks.map((i) => ({
              label: i.bank_name,
              value: i.id,
              key: i.id,
            }))
          );
        })
        .catch((err) => console.log(err));
    };
    if (!location.state?.allow) {
      if (memberServices.includes("8")) {
        if (
          Object.keys(profiledetail).length !== 0 &&
          !profiledetail.aeps_onboard
        ) {
          navigate("/banking/aeps-onboard");
        } else if (!profiledetail.ekyc_status) {
          navigate("/banking/aeps-ekyc");
        } else if (!profiledetail.twofa_status) {
          navigate("/banking/aeps", {
            state: { from: "/banking/aeps-miniStatement", type: "aeps" },
          });
        } else {
          handleAepsMasters();
        }
      } else {
        navigate("/not-available");
      }
    } else {
      handleAepsMasters();
    }
  }, []);

  const capture = (val,port=11100) => {
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        val.device === "morpho"
          ? captureUrl
          : val.device === "startek"
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        val.device === "morpho"
          ? MorphpoPIDOPTS
          : val.device === "mantra"
          ? MantraPIDOPTS
          : val.device === "startek"
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(`Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`, true);
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const parsed = parseXml(response.data);
          const xmltojson = JSON.parse(
            xml2json(parsed).replace("undefined", "")
          );
          const {
            errCode,
            errInfo,
            fCount,
            fType,
            pCount,
            iCount,
            nmPoints,
            qScore,
          } = xmltojson.PidData.Resp;
          const { dpId, rdsId, rdsVer, mi, mc, dc } =
            xmltojson.PidData.DeviceInfo;
          const { ci } = xmltojson.PidData.Skey;
          const { Hmac } = xmltojson.PidData;
          const payload = {
            customer_mobile: val.customer_mobile,
            user_bank: val.user_bank.value,
            user_bank_name: val.user_bank.label,
            customer_aadhaar_number: val.customer_aadhaar_number,
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            device_imei: Array.isArray(
              xmltojson.PidData.DeviceInfo.additional_info.Param
            )
              ? xmltojson.PidData.DeviceInfo.additional_info.Param[0].value
              : xmltojson.PidData.DeviceInfo.additional_info.Param.value,
            errCode: errCode ? errCode : 0,
            errInfo: errInfo ? errInfo : 0,
            fCount: fCount ? fCount : 0,
            fType: val.device === "morpho" || val.device === "mantra" || val.device === "startek" ? fType || 2 : 0,
            iType: val.device === "morpho" || val.device === "mantra"|| val.device === "startek" ? 0 : 1,
            iCount: iCount ? iCount : 0,
            pCount: pCount ? pCount : 0,
            pType: 0,
            nmPoints: nmPoints ? nmPoints : 0,
            qScore: qScore ? qScore : 0,
            dpID: dpId ? dpId : 0,
            rdsID: rdsId ? rdsId : 0,
            rdsVer: rdsVer ? rdsVer : 0,
            dc: dc ? dc : 0,
            mi: mi ? mi : 0,
            mc: mc ? mc : 0,
            ci: ci ? ci : 0,
            sessionKey: xmltojson.PidData.Skey["#text"],
            hmac: Hmac ? Hmac : 0,
            PidDatatype: xmltojson.PidData.Data.type,
            Piddata: xmltojson.PidData.Data["#text"],
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          AepsMinistatement(formdata)
            .then((res) => {
              if (res.status_1 === false) {
                navigate("/banking/aeps", {
                  state: { from: "/banking/aeps-miniStatement", type: "aeps" },
                });
              }
              if (res.data.status === "SUCCESS") {
                setResponse(res);
                setDrawerOpen(true);
              } else {
                dispatchtoast(res.message, true);
              }
            })
            .catch((err) => {
              if (err.response.data.status_1 === false) {
                navigate("/banking/aeps", {
                  state: { from: "/banking/aeps-miniStatement", type: "aeps" },
                });
              }
            })
            .finally(() => setBtnLoading(false));
          // .finally(() => navigate("/dashboard"));
        }
      })
      .catch((error) => {
        console.log({ error });
        setBtnLoading(false);
      });
  };

  const Content = () => {
    return (
      <>
        <div className="bg-white w-full mt-2 rounded-md p-4">
          <div className="font-semibold text-xs">
            <p>Balance : {response?.data?.balanceAmount ?? 0} Rs</p>
            <p>Merchant Name : {response?.data?.merchant_name ?? "-"}</p>
            <p>Bank Name : {response?.data?.bank_name ?? "-"}</p>
            <p>Aadhar Number : {response?.data?.masked_aadhaar ?? "-"}</p>
            <p>Transaction I'd: {response?.data.merchantTxnId ?? "-"}</p>
            <p>Bank RRN: {response?.data.bankRRN}</p>
          </div>
        </div>
        <div className="bg-white w-full  p-4 rounded-md">
          <p className="font-bold">Statements</p>
          {response?.length !== 0 &&
            response?.data.miniStatementData.map((i, index) => {
              return (
                <>
                  <div className="mt-4">
                    <p className="text-xs">{i.narration}</p>
                    <div className="flex justify-between items-center mt-2">
                      <p className="font-semibold text-xs">
                        Amount: {i.amount} Rs.
                      </p>
                      <p
                        className={`${
                          i.txnType === "Dr" ? "text-red-600" : "text-green-500"
                        }  font-bold`}
                      >
                        {i.txnType}.
                      </p>
                    </div>
                    <p className="font-semibold text-xs">Date: {i.date}</p>
                  </div>
                  <hr className="mt-2" />
                </>
              );
            })}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">Mini Statement</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleSubmitForAeps(val,capture)}
          >
            <Form.Item
              name={`customer_mobile`}
              label={"Mobile Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Mobile Number!",
                },
                {
                  pattern: mobilePattern,
                  message: "Invalid mobile number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Mobile Number"
                name="customer_mobile"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name={`customer_aadhaar_number`}
              label={"Aadhar Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter aadhar Number!",
                },
                {
                  pattern: aadharPattern,
                  message: "Invalid aadhar number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Aadhar Number"
                name="customer_aadhaar_number"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>{" "}
            <Form.Item
              name={`user_bank`}
              label={"Select Bank"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one bank!",
                },
              ]}
            >
              <Select
                showSearch
                labelInValue={true}
                placeholder="Select a bank"
                filterOption={filterOption}
                className="w-full mt-2"
                options={userBanks}
              />
            </Form.Item>
            <Form.Item
              name={`device`}
              label={"Select Device"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one device!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Device"
                filterOption={filterOption}
                className="w-full mt-2"
                options={deviceOptions}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {drawerOpen && (
        <ReportDrawer
          open={drawerOpen}
          setOpen={setDrawerOpen}
          content={<Content />}
          title="Mini Statement"
        />
      )}
    </>
  );
};

export default Ministatement;
