import React from "react";
import { ThemeColor } from "../Theme/theme";
export const Landline = ({ style }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20px"
        width="20px"
        strokeWidth={"4px"}
        stroke={ThemeColor.bswhite}
        viewBox="0 0 83.17 77.24"
      >
        {/* <defs><style>.cls-1,.cls-2{fill:none;stroke:#000;stroke-width:4px;}.cls-1{stroke-linecap:round;stroke-linejoin:round;}.cls-2{stroke-miterlimit:10;}</style></defs> */}
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect
              fill={ThemeColor.bswhite}
              className="cls-1"
              x="17.86"
              y="2"
              width="18.65"
              height="63.42"
              rx="7.98"
            />
            <rect
              fill={ThemeColor.bsblue}
              x="45.16"
              y="22.68"
              width="26.11"
              height="7.8"
            />
            <rect
              fill={ThemeColor.bsblue}
              x="44.55"
              y="39.8"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bswhite}
              x="55.6"
              y="39.8"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bsblue}
              x="66.65"
              y="39.8"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bsblue}
              x="44.55"
              y="48.51"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bsblue}
              x="55.6"
              y="48.51"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bsblue}
              x="66.65"
              y="48.51"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bsblue}
              x="44.55"
              y="57.23"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bsblue}
              x="55.6"
              y="57.23"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <rect
              stroke={ThemeColor.bsblue}
              x="66.65"
              y="57.23"
              width="5.11"
              height="5.07"
              rx="2.17"
            />
            <path
              fill={ThemeColor.primary}
              className="cls-2"
              d="M36.51,13.46H79.12a2,2,0,0,1,2,2V73.19a2.05,2.05,0,0,1-2,2H4.05a2.05,2.05,0,0,1-2-2V15.5a2,2,0,0,1,2.05-2H17.86"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
