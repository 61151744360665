import { Button, Modal } from "antd";
import React, {  useState } from "react";
import {
  getLocalStorageItem,
  numberToWords,
} from "../../../Utils";
import {
  getPayoutAndDmtOtp,
} from "../../../ApiServices/Apifun";
import { Images } from "../../../Controller";
import { Globalendpoints } from "../../../ApiServices/global";
import OtpDmt from "./OtpDmt";

const DmtPayModal = ({ isOpen, setIsOpen, details, beneficiaryId }) => {
  const [inputValue, setInputValue] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [originalVal, setOriginalVal] = useState("");
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [combinedAmount, setCombinedAmount] = useState({});
  const [otpuid, setOtpUid] = useState(null);
  const [hide, setHide] = useState(false);
  const [responses, setResponse] = useState([]);
  const [showTransferHistory, setTransferHistory] = useState(false);
  const handleOk = () => {
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const formatNumberWithCommas = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleChange = (e) => {
    const sanitized = e.target.value.replace(/[^0-9.]/g, "");
    setOriginalVal(sanitized);
    const formattedValue = formatNumberWithCommas(sanitized);
    setInputValue(formattedValue);
  };
  const handleTransfer = () => {
    setBtnLoading(true);
    getPayoutAndDmtOtp()
      .then((res) => {
        if (res.status) {
          setOtpUid(res.otp_uid);
          setHide(true);
          setOpenVerifyModal(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <Modal
        title={!showTransferHistory ? "Pay Amount" : "Transfer Status"}
        footer={null}
        className={`shadow-normal dmtmodal ${hide ? "hidden" : ""}`}
        width={300}
        maskClosable={false}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {!showTransferHistory ? (
          <>
            <div className="userDetail text-center font-semibold text-[13px]">
            </div>
            <div className="amount mt-4  font-bold text-center">
              <p className="text-3xl">₹</p>
              <input
                onChange={handleChange}
                value={inputValue}
                placeholder="0"
                className="border-none mb-20 text-lg text-center"
              />
            </div>
          </>
        ) : (
          <>
            <div className=" overflow-y-scroll">
              <div className="border border-bsgray300 rounded-md font-semibold shadow-md p-2 h-3/5 overflow-y-scroll leading-10">
                <p>Transfer status</p>
                {Object.keys(combinedAmount).length !== 0 &&
                  Object.keys(combinedAmount).map((key, index) => {
                    return combinedAmount[key] === 0 ? null : (
                      <div key={index}>
                        <div className="flex justify-between items-center">
                          <p>₹ {combinedAmount[key]}</p>
                          <p className="flex justify-center items-center gap-4">
                            {key}{" "}
                            <span>
                              <img
                                src={
                                  key === "success"
                                    ? Images.success
                                    : key === "pending"
                                    ? Images.pending
                                    : Images.failed
                                }
                                className="w-4 h-4"
                              />
                            </span>
                          </p>
                        </div>
                        {index ===
                        Object.keys(combinedAmount).length - 1 ? null : (
                          <hr />
                        )}
                      </div>
                    );
                  })}
                {/* <hr /> */}
              </div>
              <div className="min-h-[100px] max-h-[200px]">
                {responses?.data?.length !== 0 &&
                  responses?.data?.map((i, index) => {
                    return (
                      <div
                        key={index}
                        className="mt-2 border border-bsgray300 rounded-md font-semibold text-xs shadow-md"
                      >
                        <div className="p-2  flex justify-between items-center  ">
                          <div>
                            <p>Amount</p>
                            <p>₹ {i.amount}/-</p>
                            <p>Rupees {numberToWords(i.amount)}</p>
                          </div>
                          <div>
                            <img
                              src={
                                i.status === "SUCCESS"
                                  ? Images.success
                                  : i.status === "PENDING"
                                  ? Images.pending
                                  : Images.failed
                              }
                              className="w-8 h-8"
                              alt="status"
                            />
                            <p>{i.status}</p>
                          </div>
                        </div>
                        <div className=" p-1 font-semibold bg-[#f8fafc] text-bsgray700 text-xs leading-5">
                          <p className={`${i.status==="FAILED"?"text-red-600":i.status==="PENDING"?"text-yellow-400":"text-green-400"}`}>Remark: {i?.message??"-"}</p>
                          <p>Transfer Mode: {i.transfer_mode}</p>
                          <p>TXN: {i.transaction_id}</p>
                          <p>Beneficiary I'd: {i.beneficiary_id}</p>
                          <p>
                            Api Transaction I'd:{" "}
                            {i?.data?.api_transaction_id ?? "NAN"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        <div className="">
          {Number(originalVal) > Number(details.limit_available) && (
            <>
              <p className="text-xs text-bsred">
                You can't enter amount larger then your available limit!
              </p>
              <p className="text-xs text-bsred">
                Available Limit : {details.limit_available}
              </p>
            </>
          )}
          {!showTransferHistory ? (
            <Button
              onClick={() => handleTransfer()}
              loading={btnLoading}
              disabled={
                !inputValue ||
                Number(originalVal) > Number(details.limit_available)
              }
              className="w-full  bg-primary mt-2 hover:bg-primaryhover text-bswhite rounded-sm border-none "
            >
              Pay
            </Button>
          ) : (
            <Button
              onClick={() => setIsOpen(false)}
              className="w-full  bg-primary mt-2 hover:bg-primaryhover text-bswhite rounded-sm border-none "
            >
              Go Back
            </Button>
          )}
        </div>
      </Modal>
      {openVerifyModal && (
        <OtpDmt
          isOpen={openVerifyModal}
          setIsOpen={setOpenVerifyModal}
          setShowSuccess={setTransferHistory}
          setResponse={setResponse}
          setCombinedAmount={setCombinedAmount}
          setpaymodal={setIsOpen}
          setHide={setHide}
          otherFields={{
            otp_uid: otpuid,
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            amount: originalVal,
            remitter_mobile: details.mobile,
            beneficiary_id: beneficiaryId.id,
            beneficiary_name: beneficiaryId.name,
            account_no: beneficiaryId.account,
            ifsc_code: beneficiaryId.ifsc,
            bank_name: beneficiaryId.bank,
            transfer_mode: "IMPS",
          }}
          endpoint={Globalendpoints.fundTransfer}
        />
      )}
    </>
  );
};

export default DmtPayModal;

