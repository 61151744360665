import React, { useState, useEffect } from "react";
import { Images } from "../../Controller";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { setPAsswordAndPin } from "../../ApiServices/Apifun";
import {
  PortalName,
  dispatchtoast,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../Utils";
import { Button, Form, Input } from "antd";
import { ReactComponent as AepsloginIcon } from "../../Assets/logoicons/AEPS.svg";
import { ReactComponent as BbpsloginIcon } from "../../Assets/logoicons/BBPS.svg";
import { ReactComponent as KycloginIcon } from "../../Assets/logoicons/KYC.svg";
import { ReactComponent as PayoutloginIcon } from "../../Assets/logoicons/Payout.svg";
import { ReactComponent as RechargeloginIcon } from "../../Assets/logoicons/Recharge.svg";
import { ReactComponent as TravelloginIcon } from "../../Assets/logoicons/Travel.svg";
import { ReactComponent as WalletloginIcon } from "../../Assets/logoicons/Wallet.svg";
import { FaUserTie } from "react-icons/fa";
import CommonInput from "../../Common/CommonInput";

const Setpassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject("The passwords do not match");
    }
    return Promise.resolve();
  };
  const validatePin = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("pin")) {
      return Promise.reject("The pin do not match");
    }
    return Promise.resolve();
  };
  const handleFinish = (val) => {
    setLoading(true);
    const paylaod = {
      mobile: location?.state?.val?.mobile,
      password: val.password,
      pin: val.pin,
      otp_uid: location?.state.res?.otp_uid,
      otp: val.otp,
    };
    const formdata = new FormData();
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    Object.keys(paylaod).forEach((key) => formdata.append(key, paylaod[key]));
    setPAsswordAndPin(formdata)
      .then((res) => {
        if (res.status) {
          if (res.status) {
            if (
              res.digio_kyc === "0" ||
              res.digio_kyc === "2" ||
              res.digio_kyc === "3"
            ) {
              setLocalStorageItem("isLoggedIn", JSON.stringify(true));
              setLocalStorageItem("access_token", res.token);
              navigate("/kycStatus");
            }
            // else if (res.digio_kyc === "3") {
            //   setLocalStorageItem("isLoggedIn", JSON.stringify(true));
            //   setLocalStorageItem("access_token", res.token);
            //   navigate(`/kycPage?digiokyc=${res.digio_kyc}`, {
            //     state: { digio_kyc: res.digio_kyc },
            //   });
            // }
            else {
              setLocalStorageItem("isLoggedIn", JSON.stringify(true));
              setLocalStorageItem("access_token", res.token);
              navigate("/dashboard");
            }
          }
        } else {
          dispatchtoast(res.msg, true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <div className="flex justify-center bg-blue-100 h-screen items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full md:w-9/12 h-screen md:h-full  place-items-center">
          <div className="h-full md:h-5/6 shadow-lg shadow-gray-500  bg-primary rounded-tl-lg rounded-bl-lg  w-full px-10 flex flex-col justify-center items-center">
            <p className="mt-10">
              <FaUserTie color="white" size={60} />
            </p>
            <p className="text-white mt-10 font-thin text-4xl">Welcome to</p>
            <p className="mt-6 text-3xl font-semibold text-white">
              {PortalName}
            </p>
            <p className="text-xs font-normal text-white mt-4">
              Bunch of all business solution logic
            </p>
            <img
              src={Images.loginillustration2}
              className="w-[300px] h-[250px] mt-4 m-4"
            />
          </div>
          <div className=" h-full md:h-5/6  shadow-lg shadow-gray-500 rounded-br-xl rounded-tr-xl relative my-16 md:my-0 w-full flex flex-col bg-white justify-center items-center">
            <div className="rounded-md bg-white py-12 px-6 md:px-16">
              <img
                src={Images.WebsiteLogo}
                className="w-1/2"
                style={{ filter: "drop-shadow(0px 1px 2px #cfc5c5)" }}
              />
              <div className=" flex justify-end items-center mt-2 text-blue-500  font-semibold">
                <NavLink to="/">
                  <p className="text-xs">Change Number</p>
                </NavLink>
              </div>
              <div className="w-full mt-3 flex justify-center items-center">
                <Form
                  form={form}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinishFailed={(err) => console.log(err)}
                  onFinish={(val) => handleFinish(val)}
                >
                  <div className="grid grid-cols-2 gap-4 md:grid-cols-2 place-items-center">
                    <Form.Item
                      name={`otp`}
                      label={"OTP"}
                      className="w-full"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Enter OTP!",
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: "Please enter a valid number",
                        },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <CommonInput
                        maxlength={6}
                        placeholder="Enter OTP"
                        name="otp"
                      />
                    </Form.Item>
                    <Form.Item
                      name={`password`}
                      label={"Password"}
                      className="w-full"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Enter Password!",
                        },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter Password"
                        name="password"
                        className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
                      />
                    </Form.Item>{" "}
                    <Form.Item
                      name={`confirm_password`}
                      label={"Confirm Password"}
                      labelCol={{ span: 24 }}
                      className="w-full"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                        { validator: validatePassword },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm your password"
                        name="confirm_password"
                        className="outline-none text-xs rounded-none border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
                      />
                    </Form.Item>{" "}
                    <Form.Item
                      name={`pin`}
                      label={"Pin"}
                      className="w-full"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Enter Pin!",
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: "Please enter a valid number",
                        },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <CommonInput
                        placeholder="Enter Your Mobile Number"
                        maxlength={4}
                        name="customer_mobile"
                      />
                    </Form.Item>
                    <Form.Item
                      name={`setPin`}
                      label={"Confirm Pin"}
                      labelCol={{ span: 24 }}
                      className="w-full"
                      dependencies={["pin"]}
                      rules={[
                        { required: true, message: "Please confirm your pin" },
                        { validator: validatePin },
                        {
                          pattern: /^[0-9]*$/,
                          message: "Please enter a valid number",
                        },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <CommonInput
                        placeholder="Enter Your Mobile Number"
                        maxlength={4}
                        name="customer_mobile"
                        className="outline-none text-xs border mt-1 w-full border-gray-200 focus:border-primary  h-9 px-2 "
                      />
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      loading={loading}
                      className={`SignInBtn w-full   mt-4 bg-primary  flex justify-center border-none items-center h-12 rounded-full text-white text-[12px] font-bold`}
                    >
                      <p>Verify PIN</p>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              {/* <div className="absolute bottom-2 cursor-pointer flex justify-center flex-wrap gap-4  w-full items-center  font-semibold text-3xl font-serif">
            <AepsloginIcon />
                <BbpsloginIcon />
                <KycloginIcon />
                <TravelloginIcon />
                <RechargeloginIcon />
                <PayoutloginIcon />
                <WalletloginIcon />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setpassword;
