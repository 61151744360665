import { Button, DatePicker, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { RiHotelBedFill } from "react-icons/ri";
import { GrFormAdd, GrFormSubtract } from "react-icons/gr";
import Tabbar from "../Tabbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Hotel = () => {
  const [rooms, setRooms] = useState(0);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();
  const handleSubtract = (type) => {
    if (type === "rooms" && rooms > 0) {
      setRooms(rooms - 1);
    } else if (type === "children" && children > 0) {
      setChildren(children - 1);
    } else if (type === "adults" && adults > 0) {
      setAdults(adults - 1);
    }
  };
  const handleAdd = (type) => {
    if (type === "rooms") {
      setRooms(rooms + 1);
    } else if (type === "childrens") {
      setChildren(children + 1);
    } else {
      setAdults(adults + 1);
    }
  };
  useEffect(() => {
    if (!memberServices.includes("12")) {
      navigate("/not-Available");
    }
  }, []);
  return (
    <>
      <Tabbar from={"hotel"} />
      <div className=" p-4 bg-white rounded-xl">
        <div className="flex justify-start items-center gap-3">
          <RiHotelBedFill className="text-primary" size={20} />
          <p className="font-semibold text-xl">Hotel Booking</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 place-items-center gap-6 mt-4">
          <div className="flex justify-around items-center font-semibold w-full gap-4">
            <div className="w-full">
              <Select
                placeholder="Select City"
                className="w-full mt-2"
                options={[]}
              />
            </div>
          </div>
          <div className="w-full">
            <DatePicker
              popupClassName="bottom-calendar"
              className="w-full datepicker mt-2"
              onChange={() => {}}
              placeholder="Check In"
            />
          </div>
          <div className="w-full cursor-pointer">
            <DatePicker
              popupClassName="bottom-calendar"
              className="w-full datepicker mt-2"
              onChange={() => {}}
              placeholder="Check Out"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-4 mt-4">
          <div className="w-full grid grid-cols-2 gap-3 bg-primary col-span-1  p-2 mt-4">
            <div className=" flex justify-start gap-4 items-center w-full">
              <p className="text-black">Rooms</p>
              <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                {rooms}
              </p>
            </div>
            <div className="flex cursor-pointer gap-4 justify-around items-center">
              <p
                onClick={() => handleSubtract("rooms")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormSubtract />
              </p>
              <p
                onClick={() => handleAdd("rooms")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormAdd />
              </p>
            </div>
          </div>
          <div className="w-full grid grid-cols-2 gap-3 bg-primary col-span-1  p-2 mt-4">
            <div className=" flex justify-start gap-4 items-center w-full">
              <p className="text-black">Adults</p>
              <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                {adults}
              </p>
            </div>
            <div className="flex cursor-pointer gap-4 justify-around items-center">
              <p
                onClick={() => handleSubtract("adults")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormSubtract />
              </p>
              <p
                onClick={() => handleAdd("adults")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormAdd />
              </p>
            </div>
          </div>
          <div className="w-full bg-primary grid grid-cols-2 gap-3 col-span-1  p-2 mt-4">
            <div className=" flex justify-start gap-4 items-center w-full">
              <p className="text-black">Childrens</p>
              <p className="text-center bg-black w-6 h-6 flex justify-center items-center rounded-full text-bswhite ">
                {children}
              </p>
            </div>
            <div className="flex cursor-pointer gap-4 justify-around items-center">
              <p
                onClick={() => handleSubtract("childrens")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormSubtract />
              </p>
              <p
                onClick={() => handleAdd("childrens")}
                className="bg-bswhite flex justify-center items-center text-black w-6 h-6"
              >
                <GrFormAdd />
              </p>
            </div>
          </div>
          <div className="col-span-1 w-full">
            <Button className="w-full border-none outline-none h-8 bg-primary rounded-none hover:bg-primaryhover font-bold text-white mt-2">
              Search Hotel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hotel;
