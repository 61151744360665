import React, { useRef } from "react";
import { Button, Form, Select, message } from "antd";
import { AiOutlineCopy } from "react-icons/ai";
import {
  dispatchtoast,
  filterOption,
  gstPattern,
  mobilePattern,
  panPattern,
  pincodePattern,
} from "../../../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setMerchantDetail } from "../../../../../Store/B2bslice";
import CommonInput from "../../../../../Common/CommonInput";
const MerchantDetails = ({
  current,
  states,
  companytype,
  steps,
  setCurrent,
}) => {
  const formref = useRef();
  const getmerchantDetail = useSelector((state) => state.B2B.merchantDetail);
  const getPersonalDetail = useSelector((state) => state.B2B.personalDetail);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    if (getmerchantDetail) {
      formref.current.setFieldsValue({
        merchant_name: getmerchantDetail.merchant_name,
        user_mobile: getmerchantDetail.user_mobile,
        // gstin_number: getmerchantDetail.gstin_number,
        company_pan: getmerchantDetail.company_pan,
        company_type: getmerchantDetail.company_type,
        shop_address: getmerchantDetail.shop_address,
        shop_city: getmerchantDetail.shop_city,
        shop_state: getmerchantDetail.shop_state,
        shop_district: getmerchantDetail.shop_district,
        shop_pin_code: getmerchantDetail.shop_pin_code,
      });
    } else {
      formref.current.setFieldsValue({
        merchant_name: "",
        user_mobile: "",
        // gstin_number: "",
        company_pan: "",
        shop_address: "",
        shop_city: "",
        shop_state: "",
        company_type: "",
        shop_district: "",
        shop_pin_code: "",
      });
    }
  }, []);
  const handleFill = () => {
    formref.current.setFieldsValue({
      shop_city: getPersonalDetail.user_city,
      shop_district: getPersonalDetail.user_district,
      shop_state: getPersonalDetail.user_state,
      shop_pin_code: getPersonalDetail.user_pin_code,
    });
    dispatchtoast("Filled Successfully!");
  };
  const handleFinish = (val) => {
    dispatch(setMerchantDetail(val));
    setCurrent(2);
  };
  return (
    <div className="">
      <Form
        name="basic"
        form={form}
        ref={formref}
        initialValues={{
          remember: true,
        }}
        onFinishFailed={(err) => console.log(err)}
        onFinish={(val) => handleFinish(val)}
      >
        <div className="flex justify-between items-center">
          <p className="text-md mt-4 text-primary font-semibold">
            Merchant Details
          </p>
          <div
            onClick={handleFill}
            className="text-md mt-4 cursor-pointer flex justify-center items-center gap-2 text-primary font-semibold"
          >
            <p>
              <AiOutlineCopy />
            </p>{" "}
            <p>Copy Address</p>
          </div>
        </div>
        <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-x-5">
          <Form.Item
            name={`merchant_name`}
            label={"Merchant Name"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter Merchant Name!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <CommonInput
              placeholder="Enter Merchant Name"
              name="merchantName"
            />
          </Form.Item>
          <Form.Item
            name={`user_mobile`}
            label={"Mobile Number"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter Mobile Number!",
              },
              {
                pattern: mobilePattern,
                message: "Invalid mobile number format!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <CommonInput
              placeholder="Enter Your Mobile Number"
              name="merchantPhoneNumber"
            />
          </Form.Item>
          {/* <Form.Item
            name={`gstin_number`}
            label={"GSTIN Number"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter GST Number!",
              },
              {
                pattern: gstPattern,
                message: "Invalid GST number format!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <CommonInput placeholder="Enter GST Number" name="gstinNumber" />
          </Form.Item>{" "} */}
          <Form.Item
            name={`company_pan`}
            label={"Company/Shop Pan"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter Pan!",
              },
              {
                pattern: panPattern,
                message: "Invalid PAN format!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <CommonInput placeholder="Enter Pan" name="companyOrShopPan" />
          </Form.Item>
          <Form.Item
            name={`shop_address`}
            label={"Shop Address"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter your shop address!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <CommonInput
              placeholder="Enter Your Shop Address"
              name="shopAddress"
            />
          </Form.Item>{" "}
          <Form.Item
            name={`shop_city`}
            label={"City"}
            rules={[
              {
                required: true,
                message: "Enter your city!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
            labelCol={{ span: 24 }}
          >
            <CommonInput placeholder="Enter Your City" name="shopCity" />
          </Form.Item>{" "}
          <Form.Item
            name={`shop_state`}
            label={"State"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Select any one state!",
              },
            ]}
          >
            <Select
              placeholder="Select State"
              showSearch
              filterOption={filterOption}
              className="w-full h-9"
              options={states}
            />
          </Form.Item>{" "}
          <Form.Item
            name={`company_type`}
            label={"Company Type"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Select any one type!",
              },
            ]}
          >
            <Select
              placeholder="Select Company Type"
              className="w-full h-9"
              showSearch
              filterOption={filterOption}
              options={companytype}
            />
          </Form.Item>{" "}
          <Form.Item
            name={`shop_district`}
            label={"District"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter District!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <CommonInput
              placeholder="Enter Your District Name"
              name="shopDistrict"
            />
          </Form.Item>{" "}
          <Form.Item
            name={`shop_pin_code`}
            label={"Pin Code"}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter PinCpde!",
              },
              {
                pattern: pincodePattern,
                message: "Invalid PIN code format!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <CommonInput placeholder="Enter Pin Code" name="shopPincode" />
          </Form.Item>
        </div>
        <div className="flex justify-end items-center m-2">
          <Form.Item
          //   wrapperCol={{
          //     offset: 8,
          //     span: 16,
          //   }}
          >
            {current > 0 && (
              <Button
                className="hover:text-black bg-blue-500 text-white outline-none border-none"
                style={{
                  margin: "0 8px",
                }}
                onClick={() => setCurrent(0)}
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                htmlType="submit"
                className="bg-primary outline-none border-none text-white"
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                className="bg-green-500 text-white border-none"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default MerchantDetails;
