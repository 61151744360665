import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DeviceInfo,
  MantraIrisdeviceInfoAvdm,
  MantraPIDOPTS,
  MantradeviceInfoAvdm,
  MorphpoPIDOPTS,
  StartekDeviceInfo,
  captureUrl,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  mantrairisPIDOPTS,
  parseXml,
  setLocalStorageItem,
  startekPIDOPTS,
} from "../../../../../Utils";
import { xml2json } from "../../../../../Utils/xml2json";
import {
  MemberProfile,
  YesBankAepsTwoFA,
  YesBankCommonToFa,
} from "../../../../../ApiServices/Apifun";
import axios from "axios";
import { Globalendpoints } from "../../../../../ApiServices/global";
import { setProfile } from "../../../../../Store/B2bslice";

const TwoFaModal = ({
  type,
  setType,
  isOpen,
  openUserTwofaModal,
  setOpenUserTwoFaModal,
  current,
  setTwoFatxnId,
  setIsOpen,
}) => {
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const [fields, setFields] = useState(null);
  const capture = (x, port=11100) => {
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        fields === "morpho"
          ? captureUrl
          : fields === "startek"
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        fields === "morpho"
          ? MorphpoPIDOPTS
          : fields === "mantra"
          ? MantraPIDOPTS
          : fields === "startek"
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        console.log({ xmltojson });
        // const test1 = response.data;
        // const test2 = test1.search("errCode");
        // const test6 = getPosition(test1, '"', 2);
        // const test4 = test2 + 9;
        // const test5 = test1.slice(test4, test6);
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(
            `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
            true
          );
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const payload = {
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            bio_metricdata: response.data.replace(/"/g, "'"),
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          YesBankCommonToFa(
            formdata,
            "",
            type === "status"
              ? Globalendpoints.yesCahwithdrawalTwoFa
              : type === "status1"
              ? Globalendpoints.yesTwoFactorAuth
              : Globalendpoints.yesCahwithdrawalTwoFa
            // current === 0
            //   ? Globalendpoints.yesCahwithdrawalTwoFa
            //   : Globalendpoints.yesTwoFactorAuth
          )
            .then((res) => {
              if (res.data.status === "SUCCESS") {
                let data = { ...profiledetail };
                data.twofa_status === 1;
                setTwoFatxnId(res?.data?.twofa_txn_id ?? "");
                setIsOpen(false);
                current === 0 && setOpenUserTwoFaModal(true);
                dispatchtoast("Two factor completed successfully!");
                MemberProfile()
                  .then((res) => {
                    if (res.status) {
                      dispatch(setProfile(res.data.profile));
                      setIsOpen(false);
                    } else {
                      dispatchtoast("Unable to fetch profile detail", true);
                    }
                  })
                  .catch((err) => console.log(err, "errr"));
              } 
            })
            .catch((err) => console.log(err))
            .finally(() => {
              setBtnLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error.response ? error.response.data : error.message);
      });
  };
  const handleSubmit = () => {
    if (fields === "morpho") {
      DeviceInfo(() => capture());
    } else if (fields === "mantra") {
      MantradeviceInfoAvdm((imei, index) => capture(imei, index));
    } else if (fields === "iris") {
      MantraIrisdeviceInfoAvdm(() => capture());
    } else if (fields === "startek") {
      StartekDeviceInfo(() => capture());
    }
  };
  return (
    <>
      <Modal
        footer={null}
        title={<p><span className="text-primary">Merchant</span> Two Factor Authentication</p>}
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
          setType("");
        }}
        maskClosable={false}
      >
        <div className="prepaid   w-full bg-white   rounded-3xl p-2">
          <div className="selectionFields ">
            <div className="w-full">
              <p className={`required text-textGray mt-2`}>Select Device</p>
              <Select
                value={fields}
                filterOption={filterOption}
                placeholder="Select a device"
                onChange={(val) => setFields(val)}
                className="w-full mt-2"
                options={deviceOptions}
              />
            </div>

            <Button
              disabled={!fields}
              loading={btnLoading}
              onClick={() => handleSubmit()}
              className="bg-primary mt-4 w-full border-none rounded-md h-9 text-center text-white flex justify-center items-center font-bold text-md cursor-pointer"
            >
              Scan & Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TwoFaModal;
