import React from 'react'
import { ThemeColor } from '../Theme/theme'

export const Creditcard = ({style}) => {
  return (
    <svg stroke={ThemeColor.bswhite} xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" version="1.1"
    viewBox="0 0 26.15 17.28"
    >
     <defs>
      <style type="text/css">
      </style>
     </defs>
     <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g fill={ThemeColor.bswhite} id="_1768541685984">
       <g data-name="Group 172">
        <path  id="Path_264" className="fil0" d="M2.17 0l21.81 0c1.2,0 2.17,0.98 2.17,2.18l0 12.92c0,0 0,0 0,0 0,1.2 -0.97,2.18 -2.17,2.18l-21.81 0c-1.2,-0 -2.17,-0.98 -2.17,-2.18l-0 -12.92c0,-1.2 0.97,-2.17 2.17,-2.18l-0 0zm21.81 1.21l-21.81 0c-0.53,0 -0.96,0.44 -0.96,0.97l0 12.92c0,0.53 0.43,0.96 0.96,0.97l21.81 0c0.53,-0 0.96,-0.44 0.97,-0.97l-0 -12.92c-0,-0.53 -0.43,-0.97 -0.96,-0.97l-0 0zm0 0l0 0 0 0z"/>
       </g>
       <g data-name="Path 266">
        <path id="Path_266" className="fil1" d="M25.95 3.79l0 3.19 -25.75 0 0 -3.59 25.75 0 0 0.4 0 0zm0 0l0 0 0 0z"/>
       </g>
       <g data-name="Path 267">
        <path id="Path_267" className="fil1" d="M4.51 9.45c0,0 0,0 0,0 0.28,0 0.57,0.05 0.83,0.15l0.07 0.03c-0.1,0.08 -0.19,0.16 -0.28,0.25 -0.53,0.53 -0.82,1.25 -0.82,2 0,0.88 0.41,1.71 1.11,2.25l-0.07 0.03c-0.27,0.1 -0.55,0.15 -0.83,0.15 -1.34,0 -2.42,-1.08 -2.42,-2.42 0,-1.34 1.08,-2.42 2.42,-2.42l-0 0zm0 0l0 0 0 0z"/>
       </g>
       <g data-name="Path 268">
        <path id="Path_268" className="fil1" d="M5.41 10.16c0.46,-0.46 1.08,-0.72 1.72,-0.72 1.34,0 2.43,1.09 2.43,2.43 0,1.34 -1.09,2.43 -2.43,2.43 -1.34,0 -2.43,-1.09 -2.43,-2.43 0,0 0,-0 0,-0 -0,-0 -0,-0.01 -0,-0.01 0,-0.64 0.25,-1.25 0.71,-1.7l-0 0zm0 0l0 0 0 0z"/>
       </g>
       <g data-name="Path 269">
        <path id="Path_269" className="fil0" d="M11.74 11.27l2.58 0c0.31,0.03 0.56,0.29 0.56,0.61 0,0.32 -0.24,0.58 -0.56,0.61l-2.58 0c-0.31,-0.03 -0.56,-0.29 -0.56,-0.61 0,-0.32 0.24,-0.58 0.56,-0.61l-0 0zm4.49 0l2.58 0c0.31,0.03 0.56,0.29 0.56,0.61 0,0.32 -0.24,0.58 -0.56,0.61l-2.58 0c-0.31,-0.03 -0.56,-0.29 -0.56,-0.61 0,-0.32 0.24,-0.58 0.56,-0.61l-0 0zm4.49 0l2.58 0c0.31,0.03 0.56,0.29 0.56,0.61 0,0.32 -0.24,0.58 -0.56,0.61l-2.58 0c-0.31,-0.03 -0.56,-0.29 -0.56,-0.61 0,-0.32 0.24,-0.58 0.56,-0.61l-0 0zm0 0l0 0 0 0z"/>
       </g>
      </g>
     </g>
    </svg>
    
  )
}

