import React from "react";
import { ThemeColor } from "../Theme/theme";
export const DTH = ({style}) => {
  return (
    <div >
<svg strokeWidth={"4px"} stroke={ThemeColor.bswhite} xmlns="http://www.w3.org/2000/svg"  width="20px" height="20px" version="1.1" 
viewBox="0 0 41.04 43.21"
 >
 <defs>
  <style type="text/css">
  </style>
 </defs>
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_1768538985296">
   <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
     <path fill={ThemeColor.primary} className="fil0 str0" d="M37.78 30.91c0.33,0.33 0.51,0.77 0.51,1.24 0,0.52 -0.23,1.01 -0.63,1.34 -3.85,3.24 -8.73,5.02 -13.77,5.02 -11.81,0 -21.38,-9.58 -21.38,-21.38 0,-5.03 1.78,-9.91 5.02,-13.76 0.33,-0.41 0.83,-0.65 1.36,-0.65 0.47,0 0.93,0.19 1.26,0.53l27.63 27.65z"/>
     <line className="fil0 str0" x1="26.06" y1="14.98" x2="33.09" y2= "7.94" />
     <circle fill={ThemeColor.primary} className="fil0 str0" cx="36.06" cy="4.98" r="4.26"/>
     <polyline fill={ThemeColor.primary} className="fil0 str1" points="7.6,35.61 0.72,42.49 8.65,42.49 "/>
    </g>
   </g>
  </g>
 </g>
</svg>

    </div>
  );
};
