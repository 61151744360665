import React, { useEffect, useState } from "react";
import { Dropdown, Tooltip } from "antd";

import { useMediaQuery } from "react-responsive";
import { DownCircleOutlined } from "@ant-design/icons";
import { Images } from "../../../Controller";
import Prepaid from "./Prepaid";
import { useDispatch, useSelector } from "react-redux";
import {
  setHeadings,
  setSubHeadings,
  setselectedTab,
} from "../../../Store/B2bslice";
import CommonBBPS from "./BBPS";
import { Education } from "../../../Assets/education";
import { ThemeColor } from "../../../Theme/theme";
import { Fasttag } from "../../../Assets/fastag";
import { Mobile_Prepaid } from "../../../Assets/mobile prepaid";
import { Mobile_PostPaid } from "../../../Assets/mobile postpaid";
import { DataCard } from "../../../Assets/datacard_icon";
import { Creditcard } from "../../../Assets/Creditcard";
import { DTH } from "../../../Assets/dth";
import { Electricity } from "../../../Assets/Electricity";
import { Gas_Icon } from "../../../Assets/gas_icon";
import { Water } from "../../../Assets/water_icon";
import { Cable } from "../../../Assets/cable";
import { Club } from "../../../Assets/club";
import { Hospital } from "../../../Assets/hospital";
import { HousingSociety } from "../../../Assets/housing society";
import { Insurance } from "../../../Assets/insurance";
import { Landline } from "../../../Assets/landline";
import { Loan } from "../../../Assets/loan";
import { Municiple_service } from "../../../Assets/municipal service";
import { Subscription } from "../../../Assets/subscription";
import { Municipal_Tax } from "../../../Assets/municipal tax";
import { Lpg_Gas } from "../../../Assets/LPG Gas";
import NotAvailable from "../../../Routes/NotAvailable";
import Entertainment from "../../../Assets/Entertainment";
import Broadband from "../../../Assets/Broadband";
const RechargeAndBill = () => {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState("Postpaid");
  const isExtraLargeScreen = useMediaQuery({ minWidth: 1501, maxWidth: 4000 });
  const isLargeScreen = useMediaQuery({ minWidth: 992, maxWidth: 1500 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isSmallScreen = useMediaQuery({ minWidth: 400, maxWidth: 767 });
  const isExtraSmallScreen = useMediaQuery({ minWidth: 250, maxWidth: 500 });
  const memberServices = useSelector((state) => state.B2B.memberServices);

  const tabNames = [
    // {
    //   name: "Recharge",
    //   component: <Prepaid />,
    //   image: <Mobile_Prepaid style={ThemeColor.bswhite} />,
    //   key: 1,
    // },
    {
      name: "Postpaid",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="mobilepostpaid" />
      ),
      image: <Mobile_PostPaid style={ThemeColor.bswhite} />,
      key: 23,
    },
    {
      name: "DTH",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="dth" />
      ),
      image: <DTH style={ThemeColor.bswhite} />,
      key: 4,
    },
    {
      name: "Fastag",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="fasttag" />
      ),
      image: <Fasttag style={ThemeColor.bswhite} />,
      key: 9,
    },
    {
      name: "Electricity",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="electricity" />
      ),
      image: <Electricity style={ThemeColor.bswhite} />,
      key: 5,
    },
    {
      name: "Gas",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="gas" />
      ),
      image: <Gas_Icon style={ThemeColor.bswhite} />,
      key: 6,
    },
    {
      name: "Water",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="water" />
      ),
      image: <Water style={ThemeColor.bswhite} />,
      key: 7,
    },
    {
      name: "Credit Card",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="creditcard" />
      ),
      image: <Creditcard style={ThemeColor.bswhite} />,
      key: 22,
    },
    {
      name: "Broadband",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="broadband" />
      ),
      image: <Broadband style={ThemeColor.bswhite} />,
      key: 2,
    },
    {
      name: "Entertainment",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="entertainment" />
      ),
      image: <Entertainment style={ThemeColor.bswhite} />,
      key: 3,
    },
    {
      name: "Education",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="education" />
      ),
      image: <Education style={ThemeColor.bswhite} />,
      key: 8,
    },
    {
      name: "Cable",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="cable" />
      ),
      image: <Cable style={ThemeColor.bswhite} />,
      key: 10,
    },
    {
      name: "Club",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="club" />
      ),
      image: <Club style={ThemeColor.bswhite} />,
      key: 11,
    },
    {
      name: "Hospital",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="hospital" />
      ),
      image: <Hospital style={ThemeColor.bswhite} />,
      key: 12,
    },
    {
      name: "housing Society",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="housingsociety" />
      ),
      image: <HousingSociety style={ThemeColor.bswhite} />,
      key: 13,
    },
    {
      name: "Insurance",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="insurance" />
      ),
      image: <Insurance style={ThemeColor.bswhite} />,
      key: 14,
    },
    {
      name: "Landline",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="landline" />
      ),
      image: <Landline style={ThemeColor.bswhite} />,
      key: 15,
    },
    {
      name: "Loan",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="loan" />
      ),
      image: <Loan style={ThemeColor.bswhite} />,
      key: 16,
    },
    {
      name: "Lpg gas",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="lpggas" />
      ),
      image: <Lpg_Gas style={ThemeColor.bswhite} />,
      key: 17,
    },
    {
      name: "Municiple Service",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="municipalservice" />
      ),
      image: <Municiple_service style={ThemeColor.bswhite} />,
      key: 18,
    },
    {
      name: "Municiple Tax",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="municipaltax" />
      ),
      image: <Municipal_Tax style={ThemeColor.bswhite} />,
      key: 19,
    },
    {
      name: "Subscription",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="subscription" />
      ),
      image: <Subscription style={ThemeColor.bswhite} />,
      key: 20,
    },
    {
      name: "Trans Id Card",
      component: !memberServices.includes("2") ? (
        <NotAvailable />
      ) : (
        <CommonBBPS selection={selection} type="transidcard" />
      ),
      image: <Subscription style={ThemeColor.bswhite} />,
      key: 21,
    },
  ];
  const itemsToShow = isExtraLargeScreen
    ? 9
    : isLargeScreen
    ? 9
    : isMediumScreen
    ? 3
    : isSmallScreen
    ? 1
    : isExtraSmallScreen
    ? 1
    : tabNames.length;

  // const items = tabNames.slice(itemsToShow).map((item, index) => {
  //   return (
  //     <div
  //       key={index}
  //       onClick={() => setSelection(item.name)}
  //       className="flex bg-primary hover:bg-primaryhover rounded-full  text-white p-2 justify-around w-14 h-14 items-center"
  //     >
  //       {/* <img src={item.image} className="" alt="service_image" width={"15px"} /> */}
  //       <div>{item.image}</div>
  //       <p className=" cursor-pointer flex justify-end items-start transition-all bg-transparent   font-bold rounded-sm text-[13px] px-5">
  //         {item.name}
  //       </p>
  //     </div>
  //   );
  // });
  const items = tabNames.slice(itemsToShow).map((item, index) => {
    return {
      key: index,
      label: (
        <div
          key={index}
          onClick={() => setSelection(item.name)}
          className="flex bg-primary hover:bg-primaryhover rounded-md text-white p-2 justify-start w-full items-center"
        >
          {/* <img src={item.image} className="" alt="service_image" width={"15px"} /> */}
          <div>{item.image}</div>
          <p className=" cursor-pointer capitalize flex justify-end items-start transition-all bg-transparent   font-bold rounded-sm text-[13px] px-5">
            {item.name}
          </p>
        </div>
      ),
    };
  });
  useEffect(() => {
    dispatch(setSubHeadings(""));
    dispatch(setselectedTab("201"));
    dispatch(setHeadings("Recharge And Bill Payment"));
  }, [selection]);
  return (
    <>
      <div className="All_services bg-white p-10 rounded-md mb-5 gap-4 flex justify-around flex-wrap items-center">
        {tabNames.slice(0, itemsToShow).map((item, index) => {
          return (
            <div key={index}>
              <div
                onClick={() => setSelection(item.name)}
                className=" p-3 w-14 h-14 hover:scale-110  cursor-pointer hover:opacity-100 bg-primary rounded-full hover:bg-primaryhover text-white transition-all flex flex-col justify-center items-center font-bold  "
              >
                {item.image}
              </div>
              <p className={`text-[13px]    font-semibold text-center`}>
                {item.name}
              </p>
              {selection === item.name ? (
                <div className="animate-slide-in mt-1 border border-primary "></div>
              ) : (
                ""
              )}
            </div>
          );
        })}
        {(isExtraLargeScreen ||
          isLargeScreen ||
          isMediumScreen ||
          isSmallScreen ||
          isExtraSmallScreen) && (
          <Dropdown
            className="dropdownMenu"
            menu={{ items }}
            trigger={["click"]}
          >
            <div className="p-3  cursor-pointer bg-primary mb-4 rounded-full hover:opacity-60 text-white transition-all  border border-primary font-bold flex flex-col gap-y-1 justify-center items-center text-xs ">
              <p>More</p>
              <DownCircleOutlined className="" />
            </div>
          </Dropdown>
        )}
      </div>

      {tabNames.filter((item, index) => item.name === selection)[0]?.component}
    </>
  );
};

export default RechargeAndBill;
