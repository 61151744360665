import React, { useEffect, useState } from "react";
import { getLocalStorageItem } from "../Utils";

const useDigiLoader = () => {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    let timer;
      timer = setInterval(() => {
        if(getLocalStorageItem("startLoader")==="2" ){
            clearInterval(timer)
        }
        setLoader(getLocalStorageItem("startLoader"));
      }, 1000);
    return ()=>clearInterval(timer)
  }, []);
  return loader;
};

export default useDigiLoader;
