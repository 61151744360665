import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  MantraPIDOPTS,
  MorphpoPIDOPTS,
  ToastContainerMsg,
  aadharPattern,
  captureUrl,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  handleSubmitForAeps,
  mantrairisPIDOPTS,
  mobilePattern,
  parseXml,
  startekPIDOPTS,
} from "../../../../../Utils";
import {
  AepsCashWithdrawl,
  AepsYesCashWithdrawl,
  getAllAepsYesMasters,
} from "../../../../../ApiServices/Apifun";
import { xml2json } from "../../../../../Utils/xml2json";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReportDrawer from "../../../../../Common/ReportDrawer";
import { setselectedTab } from "../../../../../Store/B2bslice";
import CommonInput from "../../../../../Common/CommonInput";
import { Images } from "../../../../../Controller";

const YesWithdrawalMoney = ({
  type,
  setType,
  response,withdrawalpayload,
  setwithdrawalPayload,
  setOpenModal,
  twofatxnId,
  openReceiptDrawer,
  setOpenReceiptDrawer,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const [openModal, setOpenModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [userBanks, setUserBanks] = useState([]);
  const [consent, setConsent] = useState(false);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  useEffect(() => {
    const handleAepsMasters = () => {
      getAllAepsYesMasters()
        .then((res) => {
          setUserBanks(
            res.data.aeps_banks.map((i) => ({
              label: i.bank_name,
              value: i.id,
              key: i.id,
            }))
          );
        })
        .catch((err) => console.log(err));
    };
    handleAepsMasters();
    // return()=>localStorage.removeItem("twofaId")
  }, []);
  const Content = () => {
    return (
      <>
        <div className="border-2 rounded-md w-9/12 border-dashed border-gray-500  ml-4 mr-4 text-center mt-5">
          <p className="-mt-2 bg-white w-[100px] flex justify-center items-center font-semibold m-auto text-[11px]">
            Transaction I'd
          </p>
          <p className="text-[11px] mt-2 mb-2">
            {response?.data?.userTransactionId}
          </p>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Merchant Name</p>
            <p className="text-black">{response?.data?.merchant_name}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Bank Name</p>
            <p className="text-black">{response?.data?.bank_name}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Aadhar Number</p>
            <p className="text-black">{response?.data?.masked_aadhaar}</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Withdrawl Amount</p>
            <p className="text-black">{withdrawalpayload?.val?.amount?withdrawalpayload?.val.amount:"-"} Rs.</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Remaining Balance</p>
            <p className="text-black">
              {response?.data?.balanceAmount ?? "0"} Rs.
            </p>
          </div>
        </div>

        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Bank RRN</p>
            <p className="text-black">{response.data?.bankRrn} Rs.</p>
          </div>
        </div>
        <div className="text-[10px] border-b-[1px] py-3 border-dashed border-gray-500 w-9/12 text-gray-400 mt-4 ">
          <div className="flex justify-between items-center">
            <p>Status</p>
            <p className="text-black">{response.data?.status}</p>
          </div>
        </div>
      </>
    );
  };
  // var PIDOPTS =
  //   '<PidOptions ver="1.0">' +
  //   '<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" env="P" otp="" wadh="" posh=""/>' +
  //   "</PidOptions>";

  // function getPosition(string, subString, index) {
  //   return string.split(subString, index).join(subString).length;
  // }
  // const capture = (val, port = 11100) => {
  //   setAmount(val.amount);
  //   setBtnLoading(true);
  //   axios({
  //     method: "CAPTURE", // Set the custom request method here
  //     url:
  //       val.device === "morpho"
  //         ? captureUrl
  //         : val.device === "startek"
  //         ? "https://localhost:11200/rd/capture"
  //         : `https://127.0.0.1:${port}/rd/capture`,
  //     data:
  //       val.device === "morpho"
  //         ? MorphpoPIDOPTS
  //         : val.device === "mantra"
  //         ? MantraPIDOPTS
  //         : val.device === "startek"
  //         ? startekPIDOPTS
  //         : mantrairisPIDOPTS,
  //     headers: {
  //       "Content-Type": "text/xml",
  //       Accept: "text/xml",
  //     },
  //   })
  //     .then((response) => {
  //       const parsed = parseXml(response.data);
  //       const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
  //       // const test1 = response.data;
  //       // const test2 = test1.search("errCode");
  //       // const test6 = getPosition(test1, '"', 2);
  //       // const test4 = test2 + 9;
  //       // const test5 = test1.slice(test4, test6);
  //       if (xmltojson.PidData.Resp.errCode !== "0") {
  //         dispatchtoast(
  //           `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
  //           true
  //         );
  //         setBtnLoading(false);
  //       } else {
  //         dispatchtoast("Fingerprint Captured SuccessFully");
  //         const payload = {
  //           consent: consent,
  //           mac_address: "",
  //           device_id: navigator.userAgent ?? "",
  //           twofa_txn_id: twofatxnId,
  //           customer_mobile: val.customer_mobile,
  //           serial_number: Array.isArray(
  //             xmltojson.PidData.DeviceInfo.additional_info.Param
  //           )
  //             ? xmltojson.PidData.DeviceInfo.additional_info.Param[0].value
  //             : xmltojson.PidData.DeviceInfo.additional_info.Param.value,
  //           customer_aadhaar_number: val.customer_aadhaar_number,
  //           amount: val.amount,
  //           bio_metricdata: response.data.replace(/"/g, "'"),
  //           user_bank: val.user_bank.value,
  //           user_bank_name: val.user_bank.label,
  //           latitude: getLocalStorageItem("latitude"),
  //           longitude: getLocalStorageItem("longitude"),
  //         };
  //         const formdata = new FormData();
  //         Object.entries(payload).forEach(([key, val]) =>
  //           formdata.append(key, val)
  //         );
  //         AepsYesCashWithdrawl(formdata)
  //           .then((res) => {
  //             if (res.status_1 === false) {
  //               setOpenModal(true);
  //               setType("status1");
  //             }
  //             if (res.status) {
  //               setResponse(res);
  //               dispatchtoast(res.data.message);
  //               setOpenReceiptDrawer(true);
  //             } else {
  //               setOpenModal(true);
  //               setType("status");
  //             }
  //           })
  //           .catch((err) => {
  //             if (err.response.data.status === false) {
  //               setOpenModal(true);
  //               setType("status");
  //             } else if (err.response.data.status_1 === false) {
  //               setType("status1");
  //             }
  //           })
  //           .finally(() => setBtnLoading(false));
  //         // .finally(() => navigate("/dashboard"));
  //       }
  //     })
  //     .catch((error) => {
  //       setBtnLoading(false);
  //       console.log(error.response ? error.response.data : error.message);
  //     });
  // };
  // function DeviceInfo(val) {
  //   var url = "https://localhost:11100/getDeviceInfo";
  //   axios({
  //     method: "DEVICEINFO", // Set the custom request method here
  //     url: url,
  //     headers: {
  //       "Content-Type": "text/xml",
  //       Accept: "text/xml",
  //     },
  //   })
  //     .then((res) => {
  //       const xmltojson = convertXmlToJson(res.data);
  //       const checkDeviceConnection = Object.values(xmltojson.attributes).every(
  //         (val) => val !== ""
  //       );
  //       if (checkDeviceConnection) {
  //         capture(val);
  //       } else {
  //         dispatchtoast("Please connect device to scan fingerprint", true);
  //       }
  //     })
  //     .catch((err) => console.log(err));

  //   // var xhr;
  //   // var ua = window.navigator.userAgent;
  //   // var msie = ua.indexOf("MSIE ");

  //   // if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
  //   //   // If Internet Explorer, return version number
  //   //   //IE browser
  //   //   xhr = new ActiveXObject("Microsoft.XMLHTTP");
  //   // } else {
  //   //   //other browser
  //   //   xhr = new XMLHttpRequest();
  //   // }

  //   // //
  //   // xhr.open("DEVICEINFO", url, true);

  //   // xhr.onreadystatechange = function () {
  //   //   // if(xhr.readyState == 1 && count == 0){
  //   //   //	fakeCall();
  //   //   //}
  //   //   if (xhr.readyState == 4) {
  //   //     var status = xhr.status;

  //   //     if (status == 200) {
  //   //       alert(xhr.responseText);

  //   //       console.log(xhr.response);
  //   //     } else {
  //   //       console.log(xhr.response);
  //   //     }
  //   //   }
  //   // };

  //   // xhr.send();
  // }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <div className="flex justify-between items-center">
            <p className="font-bold text-[15px] ">Cash Withdrawl</p>
            <img
              src={Images.yesBank}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
          </div>

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) =>
              // !twofatxnId
              //   ? setOpenModal(true)
              //   :
              {
                consent
                  ? (setwithdrawalPayload({ val, ...{consent:consent} }),
                    setOpenModal(true),
                    setType(""))
                  : //  handleSubmitForAeps(val, capture)
                    dispatchtoast(
                      "Please select term and conditions 😊.",
                      true
                    );
              }
            }
          >
            <Form.Item
              name={`customer_mobile`}
              label={"Mobile Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Mobile Number!",
                },
                {
                  pattern: mobilePattern,
                  message: "Invalid mobile number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Mobile Number"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                name="customer_mobile"
              />
            </Form.Item>
            <Form.Item
              name={`customer_aadhaar_number`}
              label={"Aadhar Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter aadhar Number!",
                },
                {
                  pattern: aadharPattern,
                  message: "Invalid aadhar number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                placeholder="Enter Aadhar Number"
                name="customer_aadhaar_number"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`amount`}
              label={"Amount"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Amount!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Amount"
                name="amount"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name={`user_bank`}
              label={"Select Bank"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one bank!",
                },
              ]}
            >
              <Select
                showSearch
                labelInValue={true}
                placeholder="Select a bank"
                filterOption={filterOption}
                className="w-full mt-2"
                options={userBanks}
              />
            </Form.Item>
            {/* <Form.Item
              name={`device`}
              label={"Select Device"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one device!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Device"
                filterOption={filterOption}
                className="w-full mt-2"
                options={deviceOptions}
              />
            </Form.Item> */}
            <Form.Item
              // rules={[
              //   {
              //     required: true,
              //     message: "Select one device!",
              //   },
              // ]}
              name={`consent`}
              labelCol={{ span: 24 }}
            >
              <div className="mt-2 flex justify-start items-start gap-x-3  checkbox">
                <input
                  checked={consent}
                  onChange={(e) => {
                    e.target.checked ? setConsent(true) : setConsent(false);
                  }}
                  className="rounded-md"
                  type="checkbox"
                />
                <p
                  onClick={() => {
                    consent ? setConsent(false) : setConsent(true);
                  }}
                  className="text-textGray cursor-pointer select-none text-xs italic required"
                >
                  I hereby agree and permit the Bank to use my personal
                  information (including sensitive personal data) and to share
                  the same with statutory/regulatory/law enforcement authorities
                  and payment networks, for monitoring and/or reporting
                  purposes.”
                </p>
              </div>
            </Form.Item>{" "}
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Cash Withdrawl Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content />}
        />
      )}
    </>
  );
};

export default YesWithdrawalMoney;
