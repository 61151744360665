import { DatePicker, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { BsBusFrontFill } from "react-icons/bs";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import Tabbar from "../Tabbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Busticket = () => {
  const [bookingType, setBookingType] = useState(1);
  const [chooseReturn, setChooseReturn] = useState(false);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const navigate = useNavigate();
  useEffect(() => {
    if (!memberServices.includes("13")) {
      navigate("/not-Available");
    }
  }, []);
  return (
    <>
      <Tabbar from={"bus"} />
      <div className=" p-4 bg-white rounded-xl">
        <div className="flex justify-start items-center gap-3">
          <BsBusFrontFill className="text-primary" size={20} />
          <p className="font-semibold text-xl">Bus Booking</p>
        </div>
        <div className="grid grid-cols-2 place-items-start mt-3">
          <div className="radio-group ">
            <Radio.Group
              value={bookingType}
              onChange={(e) => setBookingType(e.target.value)}
              name="radiogroup"
              defaultValue={1}
            >
              <Radio value={1}>One Way</Radio>
              <Radio value={2}>Round Trip</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-6 mt-4">
          <div className="flex justify-around items-center font-semibold w-full gap-4">
            <div className="w-full">
              <Select
                placeholder="From ✈"
                className="w-full mt-2"
                options={[]}
              />
            </div>
            <HiOutlineSwitchHorizontal
              className="cursor-pointer text-primary font-extralight mt-4"
              size={40}
            />
            <div className="w-full">
              <Select placeholder="To ✈" className="w-full mt-2" options={[]} />
            </div>
          </div>
          <div className="flex justify-around items-center font-semibold w-full gap-4">
            <div className="w-full">
              <DatePicker
                popupClassName="bottom-calendar"
                className="w-full datepicker mt-2"
                onChange={() => {}}
                placeholder="Depart"
              />
            </div>
            <div className="w-full cursor-pointer">
              {chooseReturn ? (
                <DatePicker
                  popupClassName="bottom-calendar"
                  className="w-full datepicker mt-2"
                  onChange={() => {}}
                  placeholder="Return"
                />
              ) : (
                <div className="border border-bsgray400 h-8 mt-2  text-bsgray flex justify-around items-center">
                  <p>Return</p>
                  <p
                    onClick={() => {
                      setChooseReturn(true);
                      setBookingType(2);
                    }}
                  >
                    Choose Date
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Busticket;
