import { AutoComplete, Button, Form, Input, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import { dispatchtoast, filterOption } from "../../../Utils";
import CommonInput from "../../../Common/CommonInput";
import { BalanceTransfer, MemberList } from "../../../ApiServices/Apifun";

const SendMoneyModal = ({ open, setopen,apiCall }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const[spin,setSpin]=useState(false)
  const[memberId,setMemberId]=useState("")
 const[selectedOne,setSelectedOne]=useState(null)
  let debounceTimer;


  const getMemberList = (
    e
  ) => {
    setSpin(true);
    const payload = {
        filter_keyword:e

    };
    const formdata = new FormData();
    Object.keys(payload).map((key) => formdata.append(key, payload[key]));
    clearTimeout(debounceTimer);
   debounceTimer=setTimeout(() => {
    MemberList(formdata)
    .then((res) => {
        setMemberId(
            res.data.map((i, index) => {
              return {
                label: (
                  <div
                  onClick={()=>setSelectedOne({member_id:i.id})}
                    className="grid text-black font-semibold grid-cols-1 md:grid-cols-3 place-items-center"
                  >
                    <p>{i.member_id}</p>
                    <p>{i.name}</p>
                    <p>{i.mobile}</p>

                  </div>
                ),
                value: i.member_id,
              };
            })
          );
    })
    .catch((err) => console.log(err))
    .finally(() => setSpin(false));
   }, 300);
  };

  const handleSend = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("member_id", selectedOne.member_id);
    formdata.append("amount", val.amount);
    formdata.append("remark", val.remark);
    formdata.append("pin", val.pin);
    BalanceTransfer(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setopen(false);
          apiCall()
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <Modal
      maskClosable={false}
        title="Balance Transfer"
        footer={null}
        open={open}
        onCancel={() => setopen(false)}
      >
        <Spin spinning={spin}>
        <Form
          onFinishFailed={(err) => console.log(err)}
          onFinish={(val) => handleSend(val)}
        >
          <div className="grid grid-cols-1 gap-x-3 place-items-center ">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Select a member",
                },
              ]}
              name={"member_id"}
              label="Member"
              labelCol={{ span: 24 }}
              className="w-full"
            >
                <AutoComplete
                className="w-full"
                value={""}
                onSearch={(e) => getMemberList(e)}
                placeholder="Member Id"
                options={memberId}
              />
              {/* <Select
                options={[]}
                placeholder="Member Id"
                filterOption={filterOption}
              /> */}
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Enter amount",
                },
              ]}
              label="Amount"
              name={"amount"}
              labelCol={{ span: 24 }}
              className="w-full"
            >
              <CommonInput
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                placeholder="Amount"
                className="outline-none text-xs border w-full  border-gray-200 focus:border-primary  h-8 px-2"
              />
            </Form.Item>
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Enter pin",
              },
            ]}
            label="Pin"
            name={"pin"}
            labelCol={{ span: 24 }}
            className="w-full mt-2"
          >
            <CommonInput
              onInput={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                e.target.value = value;
              }}
              placeholder="Pin"
              className="outline-none text-xs border w-full  border-gray-200 focus:border-primary  h-8 px-2"
            />
          </Form.Item>
          <Form.Item
            
            label="Remark"
            name={"remark"}
            labelCol={{ span: 24 }}
            className="w-full mt-2"
          >
            <CommonInput
              placeholder="Remark"
              className="outline-none text-xs border w-full  border-gray-200 focus:border-primary  h-8 px-2"
            />
          </Form.Item>
          <div className="flex justify-end mt-4 items-center gap-x-4">
            <Form.Item>
              <Button   
                className="bg-primary text-white hover:border-none border-none"
                loading={btnLoading}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default SendMoneyModal;
