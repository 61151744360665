import React from 'react'
import { Images } from '../Controller'
import { NavLink } from 'react-router-dom'

const Default = () => {
  return (
   <>
   <div className='grid grid-cols-1 md:grid-cols-2 h-full place-items-center'>
   <div className='w-full'>
   <img src={Images.manondesk} className=''/>
   </div>
   <div className=' w-full  text-center md:text-start'>
    <h1 className='font-bold text-9xl text-gray-600'>404</h1>
    <p className='text-md text-gray-600'>Aha! You see! You can be wrong!</p>
    <p className='text-xs text-gray-400'>(or it could be us)...</p>
    <p className='mt-4 text-md text-gray-600'>...either way, you should propbably</p>
    <NavLink to="/dashboard"><p className='text-blue-800 font-semibold'>go back to the homepage</p></NavLink>
   </div>
   </div>
   </>
  )
}

export default Default