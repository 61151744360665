import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { useNavigate } from "react-router-dom";
import { Button, DatePicker, Select, Spin, Table, Tag, Tooltip } from "antd";
import SendMoneyModal from "./SendMoneyModal";
import { AiOutlineSearch } from "react-icons/ai";
import CommonInput from "../../../Common/CommonInput";
import CommonPagination from "../../../Common/Pagination";
import { BalanceTransferReport } from "../../../ApiServices/Apifun";
import { formatDateAndTime, trimString } from "../../../Utils";
import dayjs from "dayjs";

const GiveTopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [current, setCurrent] = useState(1);
  const [searchKeyword, setSearchKeyWord] = useState("");
  const [searchcolumn, setSearchColumn] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [result, setResult] = useState({ data: null, transaction_id: null });
  const [searchOptions, setSearchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSendMoneyModal, setOpenSendMoneyModal] = useState(false);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
console.log(fromDate,toDate,"qefjlwehfwewelhfwkleh")
  useEffect(() => {
    dispatch(setselectedTab("44"));
    if (profiledetail.member_type === "Retailer") {
      navigate("/dashboard");
    } else {
      //   getMemberList();
    }
  }, []);
  const ReportColumn = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      render: (text) => (
        <p className="font-medium ">{formatDateAndTime(text)}</p>
      ),
    },
    {
      title: "Member Name",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return (
          <>
            <p className=" font-medium  overflow-y-scroll">{text ?? "-"}</p>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <p  className=" font-medium">
          {text ? `Rs. ${text} ` : "-"}
        </p>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
      render: (text) => (
        <Tooltip title={text}>
          <p className="font-medium w-[100px]  px-3 overflow-y-scroll">
            {text ? trimString(text) : "-"}
          </p>
        </Tooltip>
      ),
    },
  ];

  const getReport = (
    search_keyword = searchKeyword,
    search_column = searchcolumn,
    from_date = fromDate,
    to_date = toDate
  ) => {
    setLoading(true);
    const payload = {
      per_page: pageSize,
      page_no: current,
      search_keyword: search_keyword,
      search_column: search_column,
      from_date: from_date,
      to_date: to_date,
    };
    const formdata = new FormData();
    Object.keys(payload).map((key) => formdata.append(key, payload[key]));
    BalanceTransferReport(formdata)
      .then((res) => {
        setDataSource(res.data);
        setTotalPages(res.total);
        setSearchOptions(
          res?.filter_column_name?.map((i, index) => {
            return {
              label: i,
              value: res.filter_column_key[index],
            };
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getReport();
  }, [current, pageSize]);
  return (
    <>
      <div className="flex justify-end items-center">
        <Button
          onClick={() => setOpenSendMoneyModal(true)}
          style={{ color: "white" }}
          className="bg-primary text-white hover:border-none border-none"
        >
          Balance Transfer
        </Button>
      </div>
      <div className="mt-3">
        <div className="prepaid  animate-fade-in w-full  bg-white shadow-normal  rounded-xl p-4">
          <div className="flex justify-between items-center">
            <p className="font-bold text-[15px] mb-2">
              Balance Transfer Report
            </p>
            <p
              onClick={() => {
                getReport("", "", "", "");
                setSearchKeyWord("");
                setSearchColumn(null);
                setFromDate("");
                setToDate("");
              }}
              className="font-medium text-xs cursor-pointer bg-bsred hover:bg-red-600 text-white px-2 py-1 rounded-md text-[15px] mb-2"
            >
              Clear Filter
            </p>
          </div>
          <div className="selectionFields grid grid-cols-1 md:grid-cols-3 gap-4 place-items-center ">
            <div className="w-full">
              <p className={`required text-textGray mt-2`}>
                Select Search Type
              </p>
              <Select
                placeholder="Select search type"
                value={searchcolumn}
                onChange={(val) => setSearchColumn(val)}
                className="w-full mt-2"
                options={searchOptions}
              />
            </div>
            <div className="w-full">
              <p className={`required text-textGray `}>Keyword</p>
              {searchcolumn === "wallet_transfers.created_at" ? (
                <DatePicker
                  // value={dayjs("2023-09-07","YYYY-MM-DD")}
                  popupClassName="bottom-calendar"
                  allowClear
                  // value={searchKeyword}
                  className="w-full datepicker mt-4"
                  onChange={(val) =>
                    setSearchKeyWord(dayjs(val).format("YYYY-MM-DD"))
                  }
                  placement="bottomLeft"
                  placeholder="Search"
                />
              ) : (
                <CommonInput
                  placeholder="Seacrh"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyWord(e.target.value)}
                  className="outline-none text-xs border mt-4 w-full  border-gray-200 focus:border-primary   px-2 "
                />
              )}
            </div>
            <div className="w-full">
              <p className={`required text-textGray mt-2`}>
                From Date - To Date
              </p>
              <DatePicker.RangePicker
                allowClear
                onChange={(val) => {
                  setFromDate(val ? dayjs(val[0]).format("YYYY-MM-DD") : "");
                  setToDate(val ? dayjs(val[1]).format("YYYY-MM-DD") : "");
                }}
                className="w-full mt-2"
              />
            </div>{" "}
          </div>
          <div className="w-full flex justify-end items-center mt-3">
            <Button
              // disabled=
              onClick={() => getReport()}
              style={{ color: "white" }}
              className=" font-semibold flex justify-between gap-2 items-center hover:bg-primaryhover bg-primary border-none"
            >
              <AiOutlineSearch />
              <p>Search</p>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-4">
          <Spin spinning={loading}>
            <Table
              className="history-table animate-fade-in max-h-[500px]  overflow-x-auto text-white rounded-lg"
              columns={ReportColumn}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setResult({
                      data: {
                        Date: record.Date,
                        "Service Type": record.service_type,
                        "Biller Name": record.biller_name,
                        Amount: record.amount,
                        Remark: record.remark,
                      },
                      transaction_id: record.transaction_id,
                    });
                    setOpenReceiptDrawer(true);
                  },
                  className: "cursor-pointer text-[12px]",
                };
              }}
              dataSource={dataSource}
            />
            <div className="mt-2 mb-8 flex justify-end items-center">
              <CommonPagination
                totalPages={totalPages}
                current={current}
                pageSize={pageSize}
                setCurrent={setCurrent}
                setPageSize={setPageSize}
              />
            </div>
          </Spin>
        </div>
      </div>
      {openSendMoneyModal && (
        <SendMoneyModal
        apiCall={getReport}
          open={openSendMoneyModal}
          setopen={setOpenSendMoneyModal}
        />
      )}
    </>
  );
};

export default GiveTopup;
