import React from "react";
import { ThemeColor } from "../Theme/theme";

const Entertainment = () => {
  return (
    <svg
    //   strokeWidth={"3px"}
      stroke={ThemeColor.bswhite}
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      version="1.1"
      viewBox="0 0 28.45 30.03"
    >
      <defs>
        <style type="text/css"></style>
      </defs>
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_1768519506064">
          <g>
            <g>
              <path
              fill={ThemeColor.primary}
                className="fil0"
                d="M1.95 12.39l26.5 0 0 15.38c0,1.24 -1.02,2.27 -2.27,2.27l-22.15 0c-1.24,0 -2.27,-1.02 -2.27,-2.27l0 -15.32 -1.77 -5.4 3.85 -1.09 1.98 5.33 -3.88 1.11zm3.83 8.27c-0.29,0 -0.53,-0.24 -0.53,-0.53 0,-0.29 0.24,-0.53 0.53,-0.53l13.32 -0c0.29,0 0.53,0.24 0.53,0.53 0,0.29 -0.24,0.53 -0.53,0.53l-13.32 0zm0 -2.81c-0.29,0 -0.53,-0.24 -0.53,-0.53 0,-0.29 0.24,-0.53 0.53,-0.53l8.69 -0c0.29,0 0.53,0.24 0.53,0.53 0,0.29 -0.24,0.53 -0.53,0.53l-8.69 0zm20.19 -2.69l-21.72 0c-0.08,0 -0.14,0.03 -0.19,0.08 -0.05,0.05 -0.08,0.12 -0.08,0.19l0 6.6c0,0.08 0.03,0.14 0.08,0.19 0.05,0.05 0.12,0.08 0.19,0.08l21.72 0c0.08,0 0.14,-0.03 0.19,-0.08 0.05,-0.05 0.08,-0.12 0.08,-0.19l0 -6.6c0,-0.08 -0.03,-0.14 -0.08,-0.19 -0.05,-0.05 -0.12,-0.08 -0.19,-0.08zm-17.8 -4.54l4.63 -1.32 -1.98 -5.32 -4.45 1.26 1.8 5.38zm6.97 -1.99l5.23 -1.5 -1.97 -5.3 -5.05 1.43 1.79 5.36zm7.56 -2.16l3.88 -1.11 -1.77 -5.35 -3.9 1.11 1.79 5.35zm4.79 6.89l-24.77 0 0 14.42c0,0.36 0.15,0.69 0.39,0.92 0.24,0.24 0.57,0.39 0.92,0.39l22.15 0c0.36,0 0.69,-0.15 0.92,-0.39 0.24,-0.24 0.39,-0.57 0.39,-0.92l0 -14.42zm-26.28 -5.65l1.17 3.58 2.19 -0.63 -1.32 -3.54 -2.05 0.58zm3.04 6.51l21.72 0c0.16,0 0.32,0.03 0.47,0.1 0.1,0.04 0.2,0.1 0.28,0.17 0.04,0.03 0.08,0.06 0.12,0.1 0.11,0.11 0.2,0.24 0.26,0.4 0.06,0.15 0.1,0.3 0.1,0.47l0 6.6c0,0.16 -0.03,0.32 -0.1,0.47 -0.06,0.15 -0.15,0.29 -0.26,0.4l-0 -0 -0 0c-0.11,0.11 -0.25,0.2 -0.39,0.26 -0.15,0.06 -0.3,0.1 -0.47,0.1l-21.72 0c-0.16,0 -0.32,-0.03 -0.47,-0.1 -0.1,-0.04 -0.2,-0.1 -0.29,-0.17 -0.04,-0.03 -0.08,-0.06 -0.11,-0.1 -0.11,-0.11 -0.2,-0.25 -0.26,-0.4 -0.06,-0.15 -0.1,-0.31 -0.1,-0.47l0 -6.6c0,-0.16 0.03,-0.32 0.1,-0.47 0.06,-0.15 0.16,-0.29 0.26,-0.4l0 0 0 -0c0.11,-0.11 0.25,-0.2 0.39,-0.26 0.15,-0.06 0.3,-0.1 0.47,-0.1zm4.53 -4.75l2.77 -0.79 -1.31 -3.53 -2.65 0.75 1.19 3.57zm6.97 -1.99l3.36 -0.96 -1.3 -3.51 -3.25 0.92 1.19 3.55zm7.56 -2.16l2.05 -0.59 -1.17 -3.54 -2.06 0.58 1.18 3.54z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Entertainment;
