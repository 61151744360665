import React, { useEffect, useState } from "react";
import { Images } from "../Controller";
import { Button, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getLocalStorageItem,
  numberToWords,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../Utils";
import { DoRecharge, RechargeStatus } from "../ApiServices/Apifun";
import ReportDrawer from "./ReportDrawer";
import Content from "./ReceiptContent";

const ChekItOut = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [response, setResponse] = useState();
  const [result, setResult] = useState({ data: null, transaction_id: null });
  const [openRceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const [rechargeDone, setRechargeDone] = useState(false);
  const [iamgeState, setIamgeState] = useState("pending");
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(null);
  const { amount, apiEndpoint, icon, payload, service,selectedTab } = location.state || {};

  const handleDoRecharge = () => {
    setLoading(true);
    const formdata = new FormData();
    const result = Object.entries(payload).map(([key, val]) =>
      formdata.append(key, val)
    );
    DoRecharge(formdata, "", apiEndpoint)
      .then((res) => {
        if (res.status) {
          setResponse(res.data);
          setLocalStorageItem("rechargeDone", true);
          setShowSuccess(true);
          setPageLoader(true)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    }
    if (getLocalStorageItem("rechargeDone")) {
      setShowSuccess(true);
    }
    return () => {
      removeLocalStorageItem("rechargeDone");
    };
  }, []);

  useEffect(() => {
    let timer;
    let count = 0;
    if (showSuccess) {
      timer = setInterval(() => {
        count = count + 1;
        callApiwithRetry(timer);
        if (count === 6) {
          clearInterval(timer);
        }
      }, 10000);
    }
    return () => clearInterval(timer);
  }, [showSuccess]);

  const callApiwithRetry = (timer) => {
    setPageLoader(true);
    const formdata = new FormData();
    formdata.append("transaction_id", response?.transaction_id);
    formdata.append("api_transaction_id", response?.api_transaction_id);
    RechargeStatus(formdata)
      .then((res) => {
        if (res.message === "SUCCESS") {
          clearInterval(timer)
          setPageLoader(false);
          setIamgeState("success");
        } else if (res.message === "PENDING") {
          setIamgeState("pending");
        } else if (res.message === "FAILED") {
          setPageLoader(false);
          setIamgeState(" ");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 place-items-start">
      {!showSuccess ? (
        <div className="prepaid leading-7 font-semibold bg-white  w-full shadow-normal rounded-3xl   p-5">
          <p>Amount</p>
          <p className="font-bold">₹{amount}/-</p>
          <p>Rupees {numberToWords(Number(amount))}</p>
          <hr className="mt-3" />
          <div className="flex mt-2 flex-wrap justify-between items-center">
            <p>
              For {service} of {payload?.operator} {selectedTab===0 && "Mobile"}{" "}
            </p>
            {selectedTab===0 && <img src={icon} className="w-10 h-10" />}
          </div>
          <div className="mt-2">
            <div className="flex justify-between items-center capitalize">
              <p>Service Type</p>
              <p>{service}</p>
            </div>
            <div className="flex justify-between items-center">
              <p>Order Total</p>
              <p>₹{amount}/-</p>
            </div>
          </div>
          <div>
            <Button
              loading={loading}
              onClick={handleDoRecharge}
              className="bg-primary rounded-full p-1 text-white w-full  mt-2 border-none"
            >
              Pay ₹{amount}/-
            </Button>
          </div>
        </div>
      ) : (
        <Spin spinning={pageLoader}>
          <div className="prepaid leading-7 font-semibold bg-white  w-full shadow-normal rounded-3xl   p-5">
            <div className="flex justify-between items-center">
              <div>
                <p>Amount</p>
                <p className="font-bold">₹{amount}/-</p>
                <p>Rupees {numberToWords(Number(amount))}</p>
              </div>
              <div>
                <img
                  src={
                    iamgeState === "success"
                      ? Images.success
                      : iamgeState === "pending"
                      ? Images.pending
                      : Images.failed
                  }
                  className="w-10 h-10"
                />
                <p>
                  {iamgeState === "success"
                    ? "Success"
                    : iamgeState === "pending"
                    ? "Pending"
                    : "Failed"}
                </p>
              </div>
            </div>
            <hr className="mt-3" />
            <div className="flex mt-2 flex-wrap justify-between items-center">
              <div>
                <p>
                  For {service} of {payload?.operator ?? ""} {selectedTab===0 && "Mobile"}{" "}
                </p>
                {iamgeState === "success" ? (
                  <p
                    onClick={() => {
                      setResult({
                        data: {
                          Amount: amount,
                          Status: "success",
                        },
                        transaction_id: response.transaction_id,
                      });
                      setOpenReceiptDrawer(true);
                    }}
                    className="bg-primary mt-2 hover:bg-primaryhover cursor-pointer text-white text-center rounded-md"
                  >
                    Invoice
                  </p>
                ) : (
                  ""
                )}
              </div>
              {selectedTab===0 && <img src={icon} className="w-10 h-10" />}
            </div>
            <hr className="mt-3" />
            <div className="mt-2 text-bsgray500">
              <p className="capitalize">Service Type: {service}</p>
              <p>TXN: {response?.transaction_id ?? ""}</p>
            </div>
            <div>
              <p
                onClick={() => navigate("/recharge")}
                className="bg-primary hover:bg-primaryhover cursor-pointer text-white h-10 flex justify-center items-center rounded-md"
              >
                Go Back
              </p>
            </div>
          </div>
        </Spin>
      )}
      {openRceiptDrawer && (
        <ReportDrawer
          title={"Recharge Receipt"}
          open={openRceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content data={result} />}
        />
      )}
    </div>
  );
};

export default ChekItOut;
