import React, { useEffect, useRef, useState } from "react";
import { Form, Select, Button, DatePicker, Radio, Checkbox, Input } from "antd";
import {
  aadharPattern,
  panPattern,
  pincodePattern,
  mobilePattern,
  emailPattern,
  filterOption,
  dispatchtoast,
} from "../../../Utils";
import {
  addMember,
  getCity,
  getCountry,
  getState,
  getmemberPackages,
  getmemberType,
} from "../../../ApiServices/Apifun";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import CommonInput from "../../../Common/CommonInput";
const Addmembers = () => {
  const formRef = useRef();
  const [form] = Form.useForm();
  const [membercustomPayload, setCustomMemberPayload] = useState({});
  const [country, setCountry] = useState([]);
  const [check, setcheck] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [packages, setPackages] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberType, setMemberType] = useState([]);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setselectedTab("15"));
    if (profiledetail.member_type === "Retailer") {
      navigate("/dashboard");
    } else {
      getCountry()
        .then((res) => {
          setCountry(
            res.countries.map((i, index) => {
              return {
                label: i.name,
                value: i.id,
                key: i.id,
              };
            })
          );
        })
        .catch((err) => console.log(err));
      getmemberType()
        .then((res) => {
          setMemberType(
            res.data.map((i) => {
              return {
                label: i.type,
                value: i.id,
                key: i.id,
              };
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const handleGetPackage = (val) => {
    setSelectedMember(val);
    setCustomMemberPayload({});
    getmemberPackages({ member_type: val })
      .then((res) => setPackages(res.packages))
      .catch((err) => console.log(err));
  };
  const handleGetState = (val) => {
    const payload = {
      country_id: val,
    };
    getState(payload)
      .then((res) => {
        setState(
          res.states.map((i, index) => {
            return {
              label: i.name,
              value: i.id,
              key: i.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const handleGetCity = (val) => {
    const payload = {
      state_id: val,
    };
    getCity(payload)
      .then((res) => {
        setCity(
          res.cities.map((i, index) => {
            return {
              label: i.name,
              value: i.id,
              key: i.id,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const handleAutoFill = (e) => {
    form.getFieldValue(country);
    setcheck(e.target.checked);
    formRef.current.setFieldsValue({
      business_country: form.getFieldValue("country"),
      business_state: form.getFieldValue("state"),
      business_city: form.getFieldValue("city"),
      business_zip: form.getFieldValue("zip"),
    });
  };
  const handleFinish = (val) => {
    let data = { ...val };
    data.pan = data.pan.toUpperCase();
    data.dob = dayjs(data.dob).format("YYYY-MM-DD");
    const formdata = new FormData();
    formdata.append("is_same_address", check ? 1 : 0);
    formdata.append("member_type", selectedMember);
    Object.entries(membercustomPayload).forEach(([key, val]) =>
      formdata.append(key, val)
    );
    Object.entries(data).forEach(([key, val]) => formdata.append(key, val));
    addMember(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="bg-white rounded-md">
        <div className="p-4 ">
          <Form
            form={form}
            ref={formRef}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleFinish(val)}
          >
            <p className="text-md text-primary font-semibold">Add Member</p>
            <p className="mt-4 font-semibold">Member Details</p>
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-x-5">
              <Form.Item
                name={`first_name`}
                label={"First Name"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter First Name!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your First Name"
                  name="first_name"
                />
              </Form.Item>
              <Form.Item
                name={`last_name`}
                label={"Last Name"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Last Name!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Last Name"
                  name="last_name"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`dob`}
                label={"Date Of Birth"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Date Of Birth!",
                  },
                ]}
              >
                <DatePicker
                  popupClassName="bottom-calendar"
                  className="w-full datepicker h-9"
                  onChange={() => {}}
                  placement="bottomLeft"
                  placeholder="Enter DOB"
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Gender"
                name={"gender"}
                rules={[
                  {
                    required: true,
                    message: "Select Gender!",
                  },
                ]}
              >
                <div className="radio-group">
                  <Radio.Group name="gender">
                    <Radio value="0"> Male </Radio>
                    <Radio value="1"> Female </Radio>
                  </Radio.Group>
                </div>
              </Form.Item>
              <Form.Item
                name={`aadhar`}
                label={"Aadhar Number"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Aadhar Number!",
                  },
                  {
                    pattern: aadharPattern,
                    message: "Invalid Aadhaar card number format!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Aadhar Number"
                  name="aadhar"
                />
              </Form.Item>
              <Form.Item
                name={`pan`}
                label={"Pan Card"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Pan!",
                  },
                  {
                    pattern: panPattern,
                    message: "Invalid PAN format!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Pan"
                  name="pan"
                />
              </Form.Item>
              <Form.Item
                name={`mobile`}
                label={"Mobile"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter your Mobile Number!",
                  },
                  {
                    pattern: mobilePattern,
                    message: "Invalid mobile number!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Mobile"
                  name="mobile"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`email`}
                label={"Email"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter your Email!",
                  },
                  {
                    pattern: emailPattern,
                    message: "Enter Valid Email!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Email "
                  name="email"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`address`}
                label={"Address"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Address!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter Your Address"
                  name="address"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`country`}
                label={"Country"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select  country!",
                  },
                ]}
              >
                <Select
                  placeholder="--Select Country--"
                  showSearch
                  onChange={(val) => handleGetState(val)}
                  className="w-full mt-2"
                  options={country}
                />
              </Form.Item>{" "}
              <Form.Item
                name={`state`}
                label={"State"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select state!",
                  },
                ]}
              >
                <Select
                  placeholder="--Select State--"
                  showSearch
                  filterOption={filterOption}
                  onChange={(val) => handleGetCity(val)}
                  className="w-full mt-2"
                  options={state}
                />
              </Form.Item>
              <Form.Item
                name={`city`}
                label={"City"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select city!",
                  },
                ]}
              >
                <Select
                  placeholder="--Select City--"
                  showSearch
                  filterOption={filterOption}
                  className="w-full mt-2"
                  options={city}
                />
              </Form.Item>
              <Form.Item
                name={`zip`}
                label={"Zip"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter PinCode!",
                  },
                  {
                    pattern: pincodePattern,
                    message: "Invalid PIN code format!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Pin Code"
                  name="zip"
                />
              </Form.Item>
            </div>
            <p className="mt-4 font-semibold">Business Details</p>
            <div className="mt-2 flex justify-start items-center gap-x-3  checkbox">
              <Input
                checked={check}
                onChange={(e) => handleAutoFill(e)}
                className="rounded-md"
                type="checkbox"
              />
              <p className="text-textGray">
                Is business address is same as member address?
              </p>
            </div>
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-x-5">
              <Form.Item
                name={`business_name`}
                label={"Buisness Name"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Business Name!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Business Name"
                  name="business_name"
                />
              </Form.Item>
              <Form.Item
                name={`business_address`}
                label={"Business Address"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Business Address!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Enter Your Business Address"
                  name="business_address"
                />
              </Form.Item>{" "}
              <Form.Item
                name={`business_country`}
                label={"Business Country"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select country!",
                  },
                ]}
              >
                <Select
                  placeholder="--Select Country--"
                  showSearch
                  filterOption={filterOption}
                  allowClear
                  onChange={(val) => handleGetState(val)}
                  className="w-full h-9"
                  options={country}
                />
              </Form.Item>{" "}
              <Form.Item
                name={`business_state`}
                label={"Business State"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select  state!",
                  },
                ]}
              >
                <Select
                  placeholder="--Select State--"
                  showSearch
                  filterOption={filterOption}
                  onChange={(val) => handleGetCity(val)}
                  className="w-full h-9 "
                  options={state}
                />
              </Form.Item>{" "}
              <Form.Item
                name={`business_city`}
                label={"Business City"}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Select any one city!",
                  },
                ]}
              >
                <Select
                  placeholder="--Select City--"
                  showSearch
                  filterOption={filterOption}
                  className="w-full h-9"
                  options={city}
                />
              </Form.Item>{" "}
              <Form.Item
                name={`business_zip`}
                label={"Business Zip "}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Enter Zip!",
                  },
                  {
                    pattern: pincodePattern,
                    message: "Invalid PIN code format!",
                  },
                  {
                    whitespace: true,
                    message: "Field cannot contain only blank spaces",
                  },
                ]}
              >
                <CommonInput
                  placeholder="Business Zip Code"
                  name="business_zip"
                />
              </Form.Item>
            </div>
            <p className="mt-4 font-semibold">Package Details</p>
            <div className="w-full md:w-1/2 mt-4">
              <p>Member Detail</p>
              <Select
                placeholder="Select Member Type"
                showSearch
                filterOption={filterOption}
                onChange={(val) => handleGetPackage(val)}
                className="w-full mt-2"
                options={memberType}
              />
            </div>
            {packages.map((item, index) => {
              return (
                <div key={index} className="mt-4">
                  <p>{item.member_type}</p>
                  {index === 0 ? (
                    <div className="radio-group my-2">
                      <Radio.Group name={`type_${item.member_type_id}`}>
                        {item.packages.map((i, index) => (
                          <Radio
                            className="mt-2 md:mt-0"
                            onChange={(e) =>
                              setCustomMemberPayload({
                                ...membercustomPayload,
                                [e.target.name]: e.target.value,
                              })
                            }
                            name={`type_${item}`}
                            value={i.id}
                          >
                            {" "}
                            {i.package_name}{" "}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </div>
                  ) : (
                    <div className="flex justify-start items-center md:gap-4 flex-wrap">
                      {item.packages.map((i, index) => {
                        return (
                          <div className="my-2 flex justify-start items-center gap-x-3  checkbox">
                            <Input
                              value={i.id}
                              name={
                                `type_${item.member_type_id}` + `[${index}]`
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setCustomMemberPayload({
                                    ...membercustomPayload,
                                    [e.target.name]: e.target.value,
                                  });
                                } else {
                                  const check = Object.keys(
                                    membercustomPayload
                                  ).includes(e.target.name);
                                  let data = { ...membercustomPayload };
                                  check && delete data[e.target.name];
                                  setCustomMemberPayload(data);
                                }
                              }}
                              className="rounded-md"
                              type="checkbox"
                            />
                            <p className="text-textGray">{i.package_name}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
            <div className="flex justify-start items-center mt-2">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="bg-primary outline-none border-none text-white"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Addmembers;
