import React from "react";
import { ThemeColor } from "../Theme/theme";
export const Fasttag= ({style}) => {
    return(
        <div >
            <svg xmlns="http://www.w3.org/2000/svg"    height="20px"
        width="20px"  strokeWidth={"4px"} stroke={ThemeColor.bswhite} viewBox="0 0 102.77 95.88">
                <defs>
                    {/* <style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:4px}</style> */}
                    </defs>
                    <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                            <circle cx="12.81" cy="26.72" r="4.78"/>
                            <path fill={ThemeColor.primary} className="cls-1" d="M12.87,14.37h0A10.87,10.87,0,0,1,23.74,25.24V93.88a0,0,0,0,1,0,0H2a0,0,0,0,1,0,0V25.24A10.87,10.87,0,0,1,12.87,14.37Z"/>
                            <path fill={ThemeColor.primary} className="cls-1" d="M23.74,54.13,90.14,3.37a6.61,6.61,0,0,1,10.63,5.25h0a6.64,6.64,0,0,1-2.59,5.26L23.74,70.77"/><line className="cls-1" x1="37.96" y1="43.26" x2="37.96" y2="60.36"/><line className="cls-1" x1="48.96" y1="35" x2="48.96" y2="52.11"/><line className="cls-1" x1="60.79" y1="25.92" x2="60.79" y2="43.03"/><line className="cls-1" x1="72.62" y1="16.85" x2="72.62" y2="33.95"/> 
                            <line className="cls-1" x1="85.55" y1="6.94" x2="85.55" y2="24.04"/></g></g>
                            </svg>
        </div>
    )
};