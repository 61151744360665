import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getPayoutBanks } from "../../../ApiServices/Apifun";
import PayModal from "./PayModal";
import { useDispatch, useSelector } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { Images } from "../../../Controller";
import { useNavigate } from "react-router-dom";

const Verification = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [registeredBanks, setRegisteredBanks] = useState([]);
  const [response, setResponse] = useState({});
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const getAllRegistered = () => {
    setSpinner(true);
    getPayoutBanks()
      .then((res) => {
        setRegisteredBanks(res.data.banks);
      })
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    dispatch(setselectedTab("5"))
    if (!memberServices.includes("5")) {
      navigate("/not-Available");
    } else {
      getAllRegistered();
    }
  }, []);

  return (
    <>
      <p className="font-semibold text-xl">Registered Banks</p>
     <Spin spinning={spinner}>
     {registeredBanks && registeredBanks.length !== 0 ? (
        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mt-3 lg:grid-cols-3 gap-4 place-items-center">
          {registeredBanks?.map((i, index) => {
            return (
              <div
                key={index}
                className="bg-primary h-[190px] relative animate-fade-in  w-full py-3 rounded-2xl "
              >
                <div className="text-white ml-8 mb-4">
                  <img src={Images.circleShape} alt="circle" />
                  <p className="text-md md:text-xl mt-1 capitalize">{i.account_holder}</p>
                  <p className="text-md">Acc No. {i.account_no}</p>
                  <p className="text-md mt-3">IFSC: {i.ifsc_code}</p>
                </div>
                <div
                  onClick={() => {
                    setResponse(i);
                    setIsOpen(true);
                  }}
                  className=" hover:shadow-2xl w-1/4 hover:bg-green-600  hover:text-white md:w-1/4 absolute bottom-0 right-0 float-right transition-all cursor-pointer   flex justify-around i gap-x-4 p-2 bg-bswhite rounded-tl-3xl"
                >
                  <p className="font-semibold">Pay</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-full">
            <img src={Images.nodata} alt="noBank"/>
          <p className="font-semibold text-lg">No Bank Added</p>
          <p
            onClick={() => navigate("/personal-info")}
            className="bg-primary text-white p-3 rounded-md hover:bg-primaryhover cursor-pointer mt-2"
          >
            + Add Bank
          </p>
        </div>
      )}
     </Spin>
      {isOpen && (
        <PayModal
          details={{
            benificiary_name: response.account_holder,
            benificiary_account: response.account_no,
            benificiary_ifsc: response.ifsc_code,
          }}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default Verification;
