import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Layout, Menu, Dropdown, Badge, Tooltip } from "antd";
import { Images } from "../Controller";
import { Menuitems } from "./Menuitems";
import { persistor } from "../Store/Store";
import {
  GetDashboardData,
  GetWalletBalance,
  MemberProfile,
  UserLogout,
  getMemberServices,
} from "../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsArrowRight, BsBell } from "react-icons/bs";
import { AiOutlineWallet } from "react-icons/ai";
import NotificationDrawer from "./NotificationDrawer";
import { ThemeColor } from "../Theme/theme";
import {
  clearAllRedux,
  setMemberService,
  setProfile,
  setWalletBal,
  setselectedTab,
} from "../Store/B2bslice";
import { dispatchtoast, trimString } from "../Utils";
import Marquee from "react-fast-marquee";
const { Header, Sider, Content } = Layout;
const ProjectLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const [showDefault, setShowDefault] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [news, setNews] = useState([]);
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const walletBalance = useSelector((state) => state.B2B.walletBal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedTab = useSelector((state) => state.B2B.selectedTab);
  useEffect(() => {
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // setselectedTab(getLocalStorageItem("selectedTab"));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {}, [selectedTab]);
  useEffect(() => {
    if (windowwidth > 1000) {
      setCollapsed(false);
      setShowDefault(true);
    } else {
      setCollapsed(true);
      setShowDefault(false);
    }
  }, [windowwidth]);

  const handleLogout = () => {
    UserLogout()
      .then((res) => {
        persistor.purge(); // This clears the persisted state
        dispatch(clearAllRedux());
        localStorage.clear();
        navigate("/");
      })
      .catch((err) => console.log(err));
  };
  const items = [
    {
      key: "1",
      label: (
        <div className="w-48">
          <div className="text-sm flex justify-start items-center">
            <AiOutlineWallet size={20} className="mr-3 text-sm text-primary" />
            <p> Wallet Balance</p>
          </div>
          <p className="font-bold text-[16px] mb-4">Rs. {walletBalance}</p>
          <hr />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <p
            onClick={() => navigate("/personal-info")}
            className="font-medium mb-4"
          >
            View Profile
          </p>
          <hr />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <p onClick={handleLogout} className="font-medium">
          Sign Out
        </p>
        //        <Popconfirm
        //    title="Logout"
        //    icon={<QuestionCircleOutlined />}
        //    description="Are you sure to logout?"
        //    onConfirm={()=>{}}
        //    onCancel={()=>{}}
        //    cancelButtonProps={{style:{color:"black"}}}
        //    okButtonProps={{style:{color:"white",background:"red"}}}
        //    okText="Yes"
        //    cancelText="No"
        //  >

        //       </Popconfirm>
      ),
    },
  ];
  const getNews = () => {
    GetDashboardData()
      .then((res) => {
        setNews(res.news);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getNews();
    MemberProfile()
      .then((res) => {
        if (res.status) {
          dispatch(setProfile(res.data.profile));
        } else {
          dispatchtoast("Unable to fetch profile detail", true);
        }
      })
      .catch((err) => console.log(err, "errr"));
    GetWalletBalance()
      .then((res) => {
        if (res.status) {
          dispatch(setWalletBal(res.balance));
        }
      })
      .catch((err) => console.log(err));
    getMemberServices()
      .then((res) => {
        if (res.status) {
          dispatch(setMemberService(res.services));
        }
      })
      .catch((err) => console.log(err));
  }, [selectedTab]);
  return (
    <>
      <Layout hasSider className="min-h-screen sidebar">
        <Sider
          style={{
            position: "fixed",
            marginBottom: "20px",
            overflowY: "scroll",
            height: "100vh",
          }}
          trigger={null}
          collapsible
          className="my-10"
          collapsed={collapsed}
        >
          <div className="demo-logo-vertical flex justify-around items-center my-5">
            {!collapsed ? (
              <>
                <div className="flex gap-4 justify-start items-center">
                  {" "}
                  <img
                    src={Images.WebsiteLogo}
                    className="h-10 w-[85%] ml-5"
                    alt="Weblogo"
                  />
                </div>
                <img
                  onClick={() => setCollapsed(!collapsed)}
                  className="cursor-pointer"
                  src={Images.hamburger}
                  alt="logo"
                  width={30}
                  height={30}
                />
              </>
            ) : (
              <img
                src={Images.WebsiteSmallLogo}
                alt="WeblogoSmall"
                className="h-10 w-10"
              />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[selectedTab]}
            items={Menuitems()}
          />
        </Sider>
        <Layout className="w-full">
          <Header
            style={{
              marginLeft: collapsed ? 75 : 230,
              // background:"linear-gradient(169deg, rgb(245, 14, 14) 0%, rgb(255 255 255) 0%, rgb(33, 111, 242) 100%, rgb(227, 9, 9) 100%)"
            }}
            className=" bg-white mt-4 flex justify-between items-center h-20"
          >
            <div className=" flex flex-wrap justify-start gap-x-3 mx-4  items-center">
              {collapsed ? (
                <p>
                  {" "}
                  <BsArrowRight
                    size={30}
                    onClick={() => setCollapsed(!collapsed)}
                    className="text-primary cursor-pointer"
                  />
                </p>
              ) : (
                ""
              )}
            </div>
            <div className=" flex flex-wrap justify-start gap-x-4 mx-4  items-center">
              <div className="rounded-md px-4 hidden lg:block  bg-inputbg ">
                <input
                  placeholder="Search here..."
                  className="outline-none border-none px-4 text-xs bg-gray-100 "
                />
                <SearchOutlined className="text-xl" />
              </div>
              <Tooltip title="Notification" placement="top">
                <div
                  className="mt-4 cursor-pointer"
                  onClick={() => setOpenDrawer(true)}
                >
                  <Badge
                    color={ThemeColor.primary}
                    className="text-black"
                    count={5}
                  >
                    <BsBell className=" text-primary" size={20} />
                  </Badge>
                </div>
              </Tooltip>

              <Dropdown
                className="dropdownMenu cursor-pointer sm:hidden md:hidden"
                menu={{ items }}
                trigger={["click"]}
              >
                <img
                  src={profiledetail.avatar}
                  className="bg-bswhite rounded-full  w-8 h-8"
                  width={"30px"}
                  alt="userImg"
                  height={"30px"}
                />
              </Dropdown>
              <Dropdown
                className="dropdownMenu "
                menu={{ items }}
                trigger={["click"]}
              >
                <div className="md:flex sm:flex justify-around hidden  items-center w-36 cursor-pointer hover:bg-primaryhover h-11 rounded-full bg-primary">
                  <div className="leading-5 ml-2">
                    <p className="text-white font-bold">
                      Hi,{" "}
                      <Tooltip title={profiledetail.first_name}>
                        <span className="capitalize">
                          {trimString(profiledetail.first_name, 6)}
                        </span>
                      </Tooltip>
                    </p>
                    <p className="text-white font-bold">Rs. {walletBalance}</p>
                  </div>
                  <img
                    src={profiledetail.avatar}
                    className="bg-bswhite rounded-full  w-8 h-8"
                    width={"30px"}
                    alt="userImg"
                    height={"30px"}
                  />
                </div>
              </Dropdown>
            </div>
          </Header>
          <Content
            className={`bg-loginbg h-full p-3 md:p-5 ${
              collapsed ? "ml-[75px]" : "ml-[230px]"
            }`}
          >
            <div className="mb-5 w-full flex justify-between items-center gap-4">
              <BsArrowLeft
                size={20}
                color={ThemeColor.primary}
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
              {/* {news?.length !== 0 && (
                <div className="bg-primary rounded-sm w-full ">
                  <Marquee pauseOnHover={true} speed={50}>
                    <p>&nbsp; &nbsp;</p>
                    {news?.map((i, index) => {
                      return (
                        <p
                          key={0}
                          className="py-1  rounded-md text-white  text-[17px]"
                        >
                          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{index + 1}
                          . <span className="capitalize font-bold">{i.title}</span>: <span className="">{i.description}</span>
                        </p>
                      );
                    })}
                  </Marquee>
                </div>
              )} */}
              {/* {location.pathname?.includes("yes") &&
              !location.pathname?.includes("Report") ? (
                <div className="">
                  <img
                    src={Images.yesBank}
                    className="flex justify-center m-auto items-center w-56"
                    alt="yes bank"
                  />
                </div>
              ) : (
                ""
              )} */}
            </div>

            {children}
          </Content>
        </Layout>
      </Layout>
      {openDrawer && (
        <NotificationDrawer setopen={setOpenDrawer} open={openDrawer} />
      )}
    </>
  );
};

export default ProjectLayout;
