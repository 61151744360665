import React from "react";
export const HousingSociety = ({ style }) => {
  return (
    <div >
      <svg
        id="Capa_1"
        enable-background="new 0 0 512 512"
        fill={style}
        
        viewBox="0 0 512 512"
        height="20px"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="m376 451h30v61h-30z" />
          <path d="m271 60v452h75v-91h90v91h76v-452l-121-60zm165 61h30v30h-30zm0 60h30v30h-30zm0 60h30v30h-30zm0 60h30v30h-30zm0 60h30v30h-30zm-90 30h-30v-30h30zm0-60h-30v-30h30zm0-60h-30v-30h30zm0-60h-30v-30h30zm0-60h-30v-30h30zm60 240h-30v-30h30zm0-60h-30v-30h30zm0-60h-30v-30h30zm0-60h-30v-30h30zm0-60h-30v-30h30z" />
          <path d="m106 451h30v61h-30z" />
          <path d="m76 421h90v91h75v-271l-120-60-121 60v271h76zm90-120h30v30h-30zm0 60h30v30h-30zm-60-60h30v30h-30zm0 60h30v30h-30zm-60-60h30v30h-30zm0 60h30v30h-30z" />
        </g>
      </svg>
    </div>
  );
};
