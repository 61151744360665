import React, { Suspense } from "react";
import { Images } from "../Controller";
import { Skeleton } from "antd";

const ComingSoon = () => {
  return (
    <>
      <Suspense fallback={ <Skeleton.Image active={true} />}>
        <div className="flex justify-center items-center h-full">
          <img src={Images.comingSoon} alt="comingsoon" />
        </div>
      </Suspense>
    </>
  );
};

export default ComingSoon;
