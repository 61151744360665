import React from "react";

const Content = ({ data }) => {
  return (
    <>
      <div className="border-2 rounded-md w-9/12 border-dashed border-gray-500  ml-4 mr-4 text-center mt-5">
        <p className="-mt-2 bg-white w-[100px] flex justify-center items-center font-semibold m-auto text-[11px]">
          Transaction I'd
        </p>
        <p className="text-[11px] mt-2 mb-2">{data.transaction_id??"-"}</p>
      </div>
      {Object.keys(data.data).map((i, index) => {
        return (
          <>
            <div className="text-[11px] border-b-[1px] py-[6px] border-dashed border-gray-500 w-9/12 text-gray-600 mt-2 ">
              <div className="flex justify-between gap-14 items-center">
                <p>{i}</p>
                <p className="text-black text-end" style={{wordBreak:"break-word"}}>{data.data[i]?data.data[i]:"-"}</p>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default Content;
