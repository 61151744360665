import React, { useEffect, useRef, useState } from "react";
import { Form, Radio, Upload, Spin, Button } from "antd";
import CommonInput from "../../../../../Common/CommonInput";
import { useDispatch, useSelector } from "react-redux";
import { IoCloudUploadOutline } from "react-icons/io5";
import Compressor from "compressorjs";
import { useNavigate } from "react-router-dom";
import {
  AepsYesMemberKyc,
  AepsYesMemberKycData,
  AepsYesMemberUploadDoc,
} from "../../../../../ApiServices/Apifun";
import {
  dispatchtoast,
  emailPattern,
  getLocalStorageItem,
} from "../../../../../Utils";
import { Images } from "../../../../../Controller";
import { setselectedTab } from "../../../../../Store/B2bslice";

const Memberkyc = () => {
  const dispatch=useDispatch()
  const formref=useRef()
  const [form]=Form.useForm()
  const [inComingPath, setIncomingPath] = useState({});
  const navigate = useNavigate();
  const [isRent, setIsRent] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPendingScreen, setShowPendingScreen] = useState("3");
  const profiledetail = useSelector((state) => state.B2B.profileDetail);

  useEffect(() => {
    if (profiledetail.bank2_aeps_memberkyc == "1") {
      navigate(-1)
    } 
    if (profiledetail.bank2_aeps_memberkyc == "0") {
      setShowPendingScreen("0");
    } else if (profiledetail.bank2_aeps_memberkyc == "2") {
      setShowPendingScreen("2");
    }
  }, []);
  const [imageDetail, setImageDetail] = useState({
    pan_image: { pan: [] },
    aadhar_image: { aadhar: [] },
    shop_image: { shop: [] },
    electricity_bill_image: { electricity: [] },
    rent_agreement_image: { rented: [] },
    selfie_with_agent: { selfie: [] },
    shop_stablish_proof: { shopstablish: [] },
  });
  const docUplpoad = (data, type, key) => {
    setPageLoader(true);
    const formdata = new FormData();
    formdata.append("image_base64", data);
    formdata.append("type", type);
    AepsYesMemberUploadDoc(formdata)
      .then((res) => {
        if (res.status) {
          setIncomingPath({ ...inComingPath, [key]: res.data.image_path });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };

  const handleImageChange = async (file, from, key) => {
    try {
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        mimeType: "image/jpeg",
        success: (compressedResult) => {
          const reader = new FileReader();
          reader.onload = async () => {
            let data = { ...compressedResult };
            data.name = compressedResult.name;
            data.thumbUrl = URL.createObjectURL(compressedResult);
            await docUplpoad(reader.result, from, key);
            if (from === "pan") {
              setImageDetail({
                ...imageDetail,
                pan_image: { pan: [data] },
              });
            } else if (from === "aadhar") {
              setImageDetail({
                ...imageDetail,
                aadhar_image: { aadhar: [data] },
              });
            } else if (from === "shop") {
              setImageDetail({
                ...imageDetail,
                shop_image: { shop: [data] },
              });
            } else if (from === "electricity") {
              setImageDetail({
                ...imageDetail,
                electricity_bill_image: {
                  electricity: [data],
                },
              });
            } else if (from === "rent") {
              setImageDetail({
                ...imageDetail,
                rent_agreement_image: {
                  rented: [data],
                },
              });
            } else if (from === "selfie") {
              setImageDetail({
                ...imageDetail,
                selfie_with_agent: {
                  selfie: [data],
                },
              });
            } else if (from === "shopProof") {
              setImageDetail({
                ...imageDetail,
                shop_stablish_proof: {
                  shopstablish: [data],
                },
              });
            }
          };
          reader.readAsDataURL(compressedResult);
        },
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const props = {
    showUploadList: {
      showRemoveIcon: false,
    },
  };
  const handleFinish = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("email", val.email);
    formdata.append("shop_latitude", getLocalStorageItem("latitude"));
    formdata.append("shop_longitude", getLocalStorageItem("longitude"));
    formdata.append("shop_address", val.shop_address);
    isRent === "1"
      ? formdata.append("electricity_bill_image", "")
      : formdata.append("rent_agreement_image", "");
    formdata.append("is_shop_rented", val.is_shop_rented);
    formdata.append("shop_name", val.shop_name);

    Object.keys(inComingPath).map((key) =>
      formdata.append(key, inComingPath[key])
    );
    AepsYesMemberKyc(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const getMeberData = () => {
    AepsYesMemberKycData()
      .then((res) => {
        if (res.status) {
          const { aadhar_dob,
            aadhar_gender,
            aadhar_id_number,
            aadhar_name,
            member_id,
            mobile,
            pan_id_number}=res.data
          formref.current.setFieldsValue({
            aadhar_dob,
            aadhar_gender,
            aadhar_id_number,
            aadhar_name,
            member_id,
            mobile,
            pan_id_number
          })
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    dispatch(setselectedTab("17"))
    getMeberData();
  }, [showPendingScreen]);
  return (
    <>
      <Spin spinning={pageLoader}>
        {showPendingScreen == "0" ? (
          <div className="p-4 flex flex-col justify-center h-full items-center text-center bg-white rounded-md">
            <img src={Images.pending} alt="pending" className="w-28" />
            <p className="mt-20 font-bold text-xl text-center">
              Your Kyc is in pending state.
            </p>
            <p onClick={()=>window.location.reload()} className="mt-10 font-semibold text-lg text-blue-400 text-center">
             Reload the page.
            </p>
          </div>
        ) : showPendingScreen == "2" ? (
          <div className="p-4 flex flex-col justify-center h-full items-center text-center bg-white rounded-md">
            <img src={Images.failed} alt="pending" className="w-28" />
            <p className="mt-10 font-bold text-xl text-center">
              Your Kyc is rejected.
            </p>
            <p className="mt-10 font-bold text-sm text-center">
              Reason: {profiledetail.bank2_aeps_memberkyc_remark}
            </p>
            <button
              onClick={() => setShowPendingScreen("3")}
              className="mt-5 font-bold text-sm bg-primary text-white h-10 px-4 rounded-md text-center"
            >
              Do Again
            </button>
          </div>
        ) : (
          showPendingScreen == "3" && (
            <div className="p-4 bg-white rounded-md">
              <p className="font-bold">Member KYC</p>
              <div>
                <Form ref={formref} form={form}
                >
                  <div className="grid grid-cols-3 gap-3 mt-4 place-items-center">
                    <Form.Item
                      className="w-full"
                      name={`aadhar_name`}
                      label={"Member Name"}
                      labelCol={{ span: 24 }}
                    >
                      <CommonInput disabled={true} name="email" />
                    </Form.Item>
                    <Form.Item
                      className="w-full"
                      name={`member_id`}
                      label={"Member Id"}
                      labelCol={{ span: 24 }}
                    >
                      <CommonInput disabled={true} name="email" />
                    </Form.Item>{" "}
                    <Form.Item
                      className="w-full"
                      name={`mobile`}
                      label={"Mobile"}
                      labelCol={{ span: 24 }}
                    >
                      <CommonInput disabled={true} />
                    </Form.Item>{" "}
                    <Form.Item
                      className="w-full"
                      name={`pan_id_number`}
                      label={"Pan Number"}
                      labelCol={{ span: 24 }}
                    >
                      <CommonInput disabled={true} />
                    </Form.Item>{" "}
                    <Form.Item
                      className="w-full"
                      name={`aadhar_id_number`}
                      label={"Aadhar Number"}
                      labelCol={{ span: 24 }}
                    >
                      <CommonInput disabled={true} />
                    </Form.Item>{" "}
                    <Form.Item
                      className="w-full"
                      name={`aadhar_gender`}
                      label={"Gender"}
                      labelCol={{ span: 24 }}
                    >
                      <CommonInput disabled={true} />
                    </Form.Item>{" "}
                    <Form.Item
                      className="w-full"
                      name={`aadhar_dob`}
                      label={"DOB"}
                      labelCol={{ span: 24 }}
                    >
                      <CommonInput disabled={true} />
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div>
                <Form
                  onFinish={(val) => handleFinish(val)}
                  onFinishFailed={(err) => console.log(err)}
                >
                  <div className="grid grid-cols-3 gap-3 mt-4 place-items-center">
                    <Form.Item
                      className="w-full"
                      name={`email`}
                      label={"Email"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Enter Email!",
                        },
                        { pattern: emailPattern, message: "Enter Valid Email" },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <CommonInput placeholder="Enter Email" name="email" />
                    </Form.Item>
                    <Form.Item
                      className="w-full"
                      name={`shop_address`}
                      label={"Shop Address"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Enter Shop Address!",
                        },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <CommonInput
                        placeholder="Enter Shop Address"
                        name="shop_address"
                      />
                    </Form.Item>{" "}
                    <Form.Item
                      className="w-full"
                      name={`shop_name`}
                      label={"Shop Name"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Enter Shop Name!",
                        },
                        {
                          whitespace: true,
                          message: "Field cannot contain only blank spaces",
                        },
                      ]}
                    >
                      <CommonInput
                        placeholder="Enter Shop Name"
                        name="shop_name"
                      />
                    </Form.Item>
                    <Form.Item
                      className="w-full"
                      labelCol={{ span: 24 }}
                      label="Is Shop Rented?"
                      name={"is_shop_rented"}
                      rules={[
                        {
                          required: true,
                          message: "Select Rent Type!",
                        },
                      ]}
                    >
                      <div className="radio-group">
                        <Radio.Group
                          onChange={(e) => setIsRent(e.target.value)}
                          name="gender"
                        >
                          <Radio value="0"> No </Radio>
                          <Radio value="1"> Yes </Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-x-5 mt-4">
                    <Form.Item
                      name={`pan_image`}
                      className="font-semibold"
                      label={"Pan Image"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Pan Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) =>
                          handleImageChange(file, "pan", "pan_image")
                        }
                        fileList={imageDetail.pan_image.pan}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <IoCloudUploadOutline />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`aadhar_image`}
                      className="font-semibold"
                      label={"Aadhar Image"}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Aadhar Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) =>
                          handleImageChange(file, "aadhar", "aadhar_image")
                        }
                        fileList={imageDetail.aadhar_image.aadhar}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <IoCloudUploadOutline />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>{" "}
                    <Form.Item
                      name={`shop_image`}
                      label={"Background Shop Image"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Shop Image!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) =>
                          handleImageChange(file, "shop", "shop_image")
                        }
                        fileList={imageDetail.shop_image.shop}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <IoCloudUploadOutline />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    {isRent === "0" ? (
                      <Form.Item
                        name={`electricity_bill_image`}
                        className="font-semibold"
                        label={"Electricity Bill Image"}
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Upload Electricity Bill Image!",
                          },
                        ]}
                      >
                        <Upload
                          maxCount={1}
                          listType="picture"
                          customRequest={({ file }) =>
                            handleImageChange(
                              file,
                              "electricity",
                              "electricity_bill_image"
                            )
                          }
                          fileList={
                            imageDetail.electricity_bill_image.electricity
                          }
                          accept=".jpg,.jpeg,.png,.webp"
                          {...props}
                          defaultFileList={[]}
                        >
                          <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                            <IoCloudUploadOutline />
                            <p>Upload</p>
                          </div>
                        </Upload>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name={`rent_agreement_image`}
                        className="font-semibold"
                        label={"Rent Aggreement"}
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Upload Rent Agreement!",
                          },
                        ]}
                      >
                        <Upload
                          maxCount={1}
                          listType="picture"
                          customRequest={({ file }) =>
                            handleImageChange(
                              file,
                              "rent",
                              "rent_agreement_image"
                            )
                          }
                          fileList={imageDetail.rent_agreement_image.rented}
                          accept=".jpg,.jpeg,.png,.webp"
                          {...props}
                          defaultFileList={[]}
                        >
                          <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                            <IoCloudUploadOutline />
                            <p>Upload</p>
                          </div>
                        </Upload>
                      </Form.Item>
                    )}
                    <Form.Item
                      name={`selfie_with_agent`}
                      label={"Selfie With Agent"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Selfie!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) =>
                          handleImageChange(file, "selfie", "selfie_with_agent")
                        }
                        fileList={imageDetail.selfie_with_agent.selfie}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <IoCloudUploadOutline />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      name={`shop_stablish_proof`}
                      label={"Shop stablish Proof"}
                      className="font-semibold"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Upload Shop Stablish Proof!",
                        },
                      ]}
                    >
                      <Upload
                        maxCount={1}
                        listType="picture"
                        customRequest={({ file }) =>
                          handleImageChange(
                            file,
                            "shopProof",
                            "shop_stablish_proof"
                          )
                        }
                        fileList={imageDetail.shop_stablish_proof.shopstablish}
                        accept=".jpg,.jpeg,.png,.webp"
                        {...props}
                        defaultFileList={[]}
                      >
                        <div className="flex justify-center  items-center gap-4 bg-green-600 text-white  px-4 py-2 shadow shadow-gray-400 rounded-3xl ">
                          <IoCloudUploadOutline />
                          <p>Upload</p>
                        </div>
                      </Upload>
                    </Form.Item>
                  </div>
                  <div className="flex mt-4 justify-end items-center">
                    <Form.Item>
                      <Button
                        loading={btnLoading}
                        htmlType="submit"
                        className="bg-primary outline-none border-none text-white"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          )
        )}
      </Spin>
    </>
  );
};

export default Memberkyc;
